import React, { useContext, useEffect, useState } from 'react';
import styles from './YourTopNutrients.module.scss';
import { SwiperComponent, Icon } from 'vitl-component-library';
import GoalItem from '@features/account/pages/result/components/GoalNutrients/GoalSlideItem';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import { IGoalNutrientInPack } from '@features/account/pages/result/components/GoalNutrients/GoalNutrients';
import { IProductPill } from '@src/types/product';
import ModalAllNutrients from '@features/account/pages/result/components/modals/modalAllNutrients';

const YourTopNutrients = () => {
  const { personalisedProductPills, dataHealthResults, nutrientTotal } = useContext(
    ResultPageContext
  );

  const [healthGoalNutrients, setHealthGoalNutrients] = useState<IGoalNutrientInPack[] | []>([]);
  const [modalNutrients, setModalNutrients] = useState<IProductPill[]>();
  const [showModalNutrients, setShowModalNutrients] = useState(false);

  useEffect(() => {
    if (dataHealthResults?.goalNutrientsInPack) {
      setHealthGoalNutrients(dataHealthResults.goalNutrientsInPack);
    }
  }, [dataHealthResults]);

  useEffect(() => {
    if (personalisedProductPills) {
      setModalNutrients([...personalisedProductPills]);
    }
  }, [personalisedProductPills]);

  return (
    <section className={styles.yourTopNutrients}>
      <h1>Your top nutrients</h1>
      <h4>
        Based on your consultation, our nutritionists have selected <b>{nutrientTotal} nutrients</b>{' '}
        for you.
      </h4>
      <div className={styles.nutrientsSlider}>
        <SwiperComponent
          className={styles.slider}
          paginationType="bullets"
          showArrows={false}
          pagination={false}
          breakpoints={{
            320: {
              slidesPerView: 1.15,
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15,
            },
            375: {
              slidesPerView: 1.3,
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15,
            },
            414: {
              slidesPerView: 1.4,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            767: {
              slidesPerView: 2.55,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 40,
            },
            992: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {healthGoalNutrients.map((goalItem: any) => (
            <GoalItem key={goalItem.goalId} goalItem={goalItem} />
          ))}
        </SwiperComponent>
      </div>
      <p className={styles.seeFullListLink} onClick={() => setShowModalNutrients(true)}>
        See full ingredients list
        <sup>
          <Icon icon="external-link" size={12} />
        </sup>
      </p>

      {modalNutrients && personalisedProductPills && (
        <ModalAllNutrients
          pills={personalisedProductPills}
          showModalNutrients={showModalNutrients}
          setShowModalNutrients={setShowModalNutrients}
        />
      )}
    </section>
  );
};

export default YourTopNutrients;
