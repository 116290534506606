import React, { useContext, useEffect } from 'react';

import Hero from './components/Hero/hero';
import ModalWarningScreen from '@src/components/modal-warning-screen';
import { ResultPageContext } from './store/store';
import { PPRecomndation } from './components/PPRecommendation/PPRecomndation';
import { Review } from './components/Reviews/Reviews';
import PartnersSlider from '@src/components/partners-slider/partners-slider';
import StillHaveQuestion from '@src/components/still-have-question/still-have-question';
import useIs4ppOrEo from './hooks/useIs4ppOrEo';
import { EoNutrients } from './components/EoNutrients/EoNutrients';
import ModalNutrients from '@src/components/modal-nutrients';
import { EffectsTimeline } from './components/EffectsTimeline/EffectsTimeline';
import { BackedByScience } from './components/BackedByScience/BackedByScience';
import YourTopNutrients from '@features/account/pages/result/components/YourTopNutrients/YourTopNutrients';
import SwitchSubscriptionModal from './components/SwitchSubscriptionModal/SwitchSubscriptionModal';
import { FAQs, faqsDescitption } from './utils/mockData';
import ResultPageErrorModal from './components/ResultPageErrorModal/ResultPageErrorModal';
import { useTracking } from './hooks/useTracking';
import { ACCOUNT_RESULT_OFFER_CODES } from './constants/constants';
import NoConsultation from './components/NoConsultation/NoConsultation';
import PillLoader from './components/PillLoading/PillLoader';
import useAccountResult from './hooks/useAccountResult';
import NoPersonalizedProduct from './components/NoPersonalizedProduct/no-personalized-product';
import { trackEvent } from '@src/services/tracking/tracking';

const ResultPage: React.FC = () => {
  const {
    modalNutrients,
    setModalNutrients,
    goalIsUpdated,
    consultationStatusCompleted,
    dataRecommendedProducts,
  } = useContext(ResultPageContext);

  const { loading, currentRecommendedProduct } = useAccountResult();
  const { isE0, is4pp } = useIs4ppOrEo();
  const track = useTracking();

  useEffect(() => {
    if (goalIsUpdated) {
      window.location.reload(); //! Try to fix it Later with refactor and ussage of Redux
    }
  }, [goalIsUpdated]);

  useEffect(() => {
    if (isE0) {
      track(ACCOUNT_RESULT_OFFER_CODES.EssentialOne);
    }
    if (is4pp) {
      track(ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus);
    }
  }, []);

  useEffect(() => {
    if (!currentRecommendedProduct) {
      return;
    }
    trackEvent('Shown Results Page', {
      product: currentRecommendedProduct.sku.includes('sn') ? '4PP' : 'EO',
      isSubscribed: currentRecommendedProduct.isSubscribed || false,
    });
  }, [currentRecommendedProduct]);

  if (
    !consultationStatusCompleted &&
    dataRecommendedProducts &&
    dataRecommendedProducts?.health_recommendedProducts?.length < 1
  ) {
    return <NoConsultation />;
  }

  if (
    consultationStatusCompleted &&
    dataRecommendedProducts &&
    dataRecommendedProducts?.health_recommendedProducts?.length < 1
  )
    return <NoPersonalizedProduct />;

  if (loading || goalIsUpdated) return <PillLoader />;

  return (
    <>
      <Hero />
      {is4pp ? <PPRecomndation /> : null}
      {isE0 ? <EoNutrients /> : null}
      <YourTopNutrients />
      <EffectsTimeline />
      <Review />
      <BackedByScience />
      <PartnersSlider backgroundColor="#FEF6DC" />
      <StillHaveQuestion accordion={FAQs} description={faqsDescitption} />
      {modalNutrients && (
        <ModalNutrients
          pillInfo={modalNutrients}
          showModalNutrients={!!modalNutrients}
          setShowModalNutrients={() => setModalNutrients(null)}
        />
      )}
      <ModalWarningScreen />
      <SwitchSubscriptionModal />
      <ResultPageErrorModal />
    </>
  );
};

export default ResultPage;
