import React from 'react';
import Image from '@components/image/image';
// utilities
import { getContentStyles } from '../utilities';
// styles
import styles from './content-icon-grid.module.scss';

interface IProps {
  content: any;
}

const ContentIconGrid: React.FC<IProps> = ({ content }) => {
  const { list } = content;
  const listLenght = {
    isFour: list?.length === 4,
  };

  return (
    <ul className={`${styles.list} ${listLenght.isFour ? styles.list2items : styles.list3items}`}>
      {list?.map((item: any, index: number) => (
        <li className={styles.item} key={index}>
          <div className={styles.circle} style={getContentStyles(item.style)}>
            {item.icon_text}
            {item.image && <Image src={item.image.url} width={200} alt={item.image.alt} />}
          </div>
          <p>
            <strong>{item.title}</strong>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default ContentIconGrid;
