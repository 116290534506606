import React from 'react';
import { SwiperComponent } from 'vitl-component-library';
// utilities
import { getContentStyles } from '../utilities';
import { renderBlock } from '../../account-content-screen';
// styles
import styles from './content-carousel.module.scss';

interface IProps {
  content: any;
}

const ContentCarousel: React.FC<IProps> = ({ content }) => (
  <SwiperComponent paginationType="bullets" spaceBetween={20} slidesPerView={1}>
    {content?.slides?.map((slide: any, slideIndex: number) => {
      return (
        <div key={slideIndex} className={styles.item}>
          {slide.sections?.map((section: any, sectionIndex: number) => (
            <section key={sectionIndex} style={getContentStyles(section.style)}>
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    {section.blocks.map((block: any, blockIndex: number) => (
                      <div key={blockIndex} className={styles.block}>
                        {renderBlock(block)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          ))}
        </div>
      );
    })}
  </SwiperComponent>
);

export default ContentCarousel;
