import React from 'react';
import ModalBody from '@components/modal/modal-body';
import styles from '@features/account/pages/result/components/GoalSelection/GoalSelection.module.scss';
import { Icon, Image } from 'vitl-component-library';
import Modal from '@components/modal';
import { IHealthProfileGoal } from '@src/types/goal';

interface IProps {
  goalInfo: IHealthProfileGoal | null;
  setShowInfoModal: (showInfoModal: boolean) => void;
}

const ModalInfoHealthGoal: React.FC<IProps> = ({ goalInfo, setShowInfoModal }) => {
  const hasGoalIssues = goalInfo?.issues && goalInfo.issues.length > 0;
  return (
    <Modal type="Results" handleClose={() => setShowInfoModal(false)}>
      <ModalBody type="ResultsHealthGoal">
        <div className={styles.modalContentHeader}>
          <Image
            url={`${goalInfo?.imageUrl}?&colorize=%23${
              goalInfo?.color.split('#')[1]
            }&truecolor=true`}
            width={24}
          />
          <h3>{goalInfo?.healthPillar}</h3>
        </div>
        {hasGoalIssues && (
          <>
            <p className={styles.youToldUsText}>You told us...</p>
            <ul className={styles.goalInfoList}>
              {goalInfo?.issues &&
                goalInfo.issues.map(issue => (
                  <li key={issue.name}>
                    <Icon icon="check" size="11" className={styles.goalIcon} />
                    {issue.name}
                  </li>
                ))}
            </ul>
          </>
        )}
        {!hasGoalIssues && goalInfo?.genericDesc && (
          <p
            className={styles.genericDesc}
            dangerouslySetInnerHTML={{
              __html: goalInfo?.genericDesc,
            }}
          />
        )}
        {goalInfo?.userPopularityLabel && (
          <div className={styles.vitlFact}>
            <b>{goalInfo.userPopularityLabel}</b> of Vitl users have this goal
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalInfoHealthGoal;
