import React from 'react';
// styles
import styles from './content-dna-trait-marker.module.scss';

interface IProps {
  content: any;
}

const ContentDnaTraitMarker: React.FC<IProps> = ({ content }) => {
  const gradientBreakpoints = content.results.bar_colors.map((bar: string, index: number) => {
    return bar + ' ' + (index / (content.results.bar_colors.length - 1)) * 100 + '%';
  });

  return (
    <>
      <div
        className={styles.bar}
        style={{
          background: `linear-gradient(to right, ${gradientBreakpoints.join(',')})`,
        }}
      >
        <div className={styles.marker} style={{ left: content.results.value * 100 + '%' }}></div>
      </div>
      <p className={styles.labels}>
        <span>{content.results.start_label}</span>
        <span>{content.results.end_label}</span>
      </p>
    </>
  );
};

export default ContentDnaTraitMarker;
