import React, { useContext, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { SwiperComponent } from 'vitl-component-library';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
// context

import { GlobalContext } from '@store/global-state';
// queries
import { GOALS, USER_WARNING_SCREEN } from '@queries/user';
import { IHealthGoal } from '@interfaces/goal';
// components
import Loader from '../loader';
import Image from '@components/image/image';
import GoalPanel from '../goal-panel';

import styles from './account-consultation-result.module.scss';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

const AccountConsultationResult: React.FC = () => {
  const { directusAccountConsultationResult } = useAccountText();
  const { setShowWarningScreen, setWarningScreenContent } = useContext(GlobalContext);

  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const {
    data: goalsData,
    loading: goalsLoading,
    error: goalsError,
    startPolling,
    stopPolling,
  } = useQuery(GOALS, {
    fetchPolicy: 'network-only',
  });

  const [getWarningScreen] = useLazyQuery(USER_WARNING_SCREEN, {
    onCompleted: data => {
      const jsonSections = data.user_warningScreen?.jsonSections;
      if (jsonSections) {
        setShowWarningScreen(true);
        setWarningScreenContent(JSON.parse(jsonSections));
      }
    },
  });

  useEffect(() => {
    getWarningScreen();
  }, []);

  const userFirstName = goalsData?.user_details?.firstName;
  const selectedHealthGoals = goalsData?.health_goals.filter((goal: IHealthGoal) => goal.selected);

  // add polling for goals
  useEffect(() => {
    if (goalsData?.health_goals.length > 0 && selectedHealthGoals.length === 0) {
      setShouldPoll(true);
      startPolling(1000);

      const timer = setTimeout(() => {
        setShouldPoll(false);
        stopPolling();
      }, 20000);
      return () => clearTimeout(timer);
    }

    if (selectedHealthGoals?.length > 0) {
      setShouldPoll(false);
      stopPolling();
    }
  }, [goalsData]);

  if (goalsLoading || (shouldPoll && animationCompleted) || !directusAccountConsultationResult)
    return <Loader />;

  if (goalsError || (animationCompleted && selectedHealthGoals.length === 0 && !shouldPoll)) {
    navigate('/account/result');
  }

  return (
    <>
      {!showWalkthrough && (
        <section className={styles.hero}>
          <div className={styles.container}>
            <div className={styles.pillLoader}>
              <div className={styles.progress} />
              <div
                className={styles.pill}
                onAnimationEnd={() => {
                  setShowWalkthrough(true);
                  setAnimationCompleted(true);
                }}
              >
                <Image
                  filename={directusAccountConsultationResult.product_image.filename_disk}
                  width={33}
                />
              </div>
            </div>
            <div className={styles.textLoader}>
              <span className={styles.message}>
                {directusAccountConsultationResult.supplement_list_text_1}
              </span>
              <span className={styles.message}>
                {directusAccountConsultationResult.supplement_list_text_2}
              </span>
              <span className={styles.message}>
                {directusAccountConsultationResult.supplement_list_text_3}
              </span>
              <span className={styles.message}>
                {directusAccountConsultationResult.supplement_list_text_4}
              </span>
              <span className={styles.message}>
                {directusAccountConsultationResult.supplement_list_text_5}
              </span>
            </div>
          </div>
        </section>
      )}

      {showWalkthrough && (
        <section className={styles.sectionPrimaryLighter}>
          <div className={styles.container}>
            <p className={styles.subheading}>Results</p>
            <h2>{userFirstName ? `${userFirstName}'s` : 'Your'} Health Goals</h2>
            <p>Carefully selected for you by our experts based on your answers.</p>
            <div className={styles.sliderWrapper}>
              <SwiperComponent
                paginationType="bullets"
                spaceBetween={8}
                centerInsufficientSlides={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                  1280: {
                    slidesPerView: 3,
                  },
                }}
              >
                {selectedHealthGoals.map((goal: IHealthGoal, index: number) => (
                  <div key={index} className={styles.goal}>
                    <GoalPanel goal={goal} />
                  </div>
                ))}
              </SwiperComponent>
            </div>
            <Link to={'/account/result'} className={styles.buttonPrimary}>
              Next
            </Link>
          </div>
        </section>
      )}
    </>
  );
};

export default AccountConsultationResult;
