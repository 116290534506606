import * as Sentry from '@sentry/gatsby';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/react-hooks';

import { CANCEL_SUBSCRIPTION, PAUSE_SUBSCRIPTION, UNPAUSE_SUBSCRIPTION } from '@queries/orders';

const useCancelSubscription = (onCompletedCallback: () => void, onErrorCallback: () => void) => {
  const [cancelSubscription, { loading }] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted() {
      onCompletedCallback();
    },
    onError: error => {
      const traceToken = uuidv4();
      Sentry.withScope(scope => {
        scope.setTag('traceToken', traceToken);
        Sentry.captureException(new Error(`subscription_cancel: ${error.message}}`));
      });

      onErrorCallback();
    },
  });
  return {
    cancelSubscription,
    loading,
  };
};

const usePauseSubscription = (onCompletedCallback: () => void, onErrorCallback: () => void) => {
  const [pauseSubscription, { loading }] = useMutation(PAUSE_SUBSCRIPTION, {
    onCompleted: () => {
      onCompletedCallback();
    },
    onError: () => {
      onErrorCallback();
    },
  });
  return {
    pauseSubscription,
    loading,
  };
};

const useUnpauseSubscription = (onCompletedCallback: () => void, onErrorCallback: () => void) => {
  const [unpauseSubscription, { loading }] = useMutation(UNPAUSE_SUBSCRIPTION, {
    onCompleted: () => {
      onCompletedCallback();
    },
    onError: () => {
      onErrorCallback();
    },
  });
  return {
    unpauseSubscription,
    loading,
  };
};

export { useCancelSubscription, usePauseSubscription, useUnpauseSubscription };
