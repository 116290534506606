import React, { useCallback } from 'react';

import useAccountResult from '../../hooks/useAccountResult';

import styles from './EffectsTimeline.module.scss';

// Currently only supported goals | Add more later if needed
const supportedGoals = ['brain', 'energy', 'stress', 'sleep', 'skin', 'hair', 'fitness'];

export const EffectsTimeline = () => {
  const { selectedGoals } = useAccountResult();

  const checkIfGOalsAreSupported = useCallback(() => {
    const goalList: string[] = [];
    if (selectedGoals.length !== 0) {
      selectedGoals.forEach((el: any) => {
        goalList.push(el.id);
      });
    }
    const results = supportedGoals.filter(row => goalList.some(value => row.includes(value)));

    return {
      isSupported: results.length > 1,
      goals: results,
    };
  }, [selectedGoals]);

  if (!checkIfGOalsAreSupported().isSupported) return null;

  const getCurrentImage = (currentGoal: any) => {
    return <img src={`/images/result-page/chart/${currentGoal}.png`} alt="" />;
  };

  return (
    <div className={styles.recommentation}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.chart}>
            {checkIfGOalsAreSupported().goals.map((goal: any) => getCurrentImage(goal))}
            <span className={styles.arrow}></span>
          </div>
          <div className={styles.textWrap}>
            <p>Average time taken to notice difference</p>
            <span>According to Vitl’s customer surveys</span>
          </div>
        </div>
        <div className={styles.right}>
          <h2>When will I feel extraordinary?</h2>
          <p>
            Top tip: it’s all about <strong>consistency!</strong> Make your vitamins a healthy daily
            habit & give your body time to adjust.
          </p>
        </div>
      </div>
    </div>
  );
};
