import { graphql, useStaticQuery } from 'gatsby';

export const useAccountText = () => {
  const content = useStaticQuery(graphql`
    query Account {
      directusConsultationResult {
        section_1_title
        section_1_subtitle
        section_3_title
        section_3_description
        section_3_footnote
        section_3_image {
          title
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        faqs_title
        faq_categories {
          name
          id
          questions {
            id
            question
            answer
          }
        }
        pp_main_image {
          filename_disk
        }
        pp_alt_image {
          filename_disk
        }
        pp_alt_image_2 {
          filename_disk
        }
        eo_main_image {
          filename_disk
        }
        eo_alt_image {
          filename_disk
        }
        eo_bottle_image {
          filename_disk
        }
        eo_pill_cover {
          filename_disk
        }
        eo_pill_cover_full {
          filename_disk
        }
      }
      directusAccount {
        page_title
        page_description
        signin_title
        signin_email_label
        signin_signin_button_label
        signin_guest_button_label
        signin_register_button_label
        signin_no_account
        details_title
        details_first_name_label
        details_last_name_label
        details_phone_label
        details_email_label
        details_submit_button_label
        pause_title
        pause_subtitle
        pause_restart_label
        pause_submit_button_label
        pause_cancel_button_label
        update_title
        update_submit_button_label
        update_cancel_button_label
        payment_title
        payment_no_methods_subtitle
        payment_update_button_label
        payment_remove_button_label
        payment_update_title
        payment_update_postcode_label
        payment_update_default_label
        payment_update_submit_button_label
        payment_update_cancel_button_label
        goals_manage_label
        goals_manage_button_label
        goal_report_title
        goal_diet_title
        goal_advice_title
        goal_food_title
        goal_lifestyle_title
        goal_start_title
        goal_start_description
        goal_start_footnote
        goal_start_image {
          filename_disk
        }
        goal_start_submit_button_label
        feedback_title
        feedback_description
        feedback_placeholder
        feedback_button_label
        feedback_sent_title
        feedback_sent_description
        feedback_sent_button_label
        email_preferences_title
        email_preferences_label
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        recommendation_unavailable_title
        recommendation_unavailable_text
        recommendation_unavailable_close_button_label
        communication_preferences_text
        communication_email_label
        communication_sms_label
      }
      directusAccountGoal {
        manage_title
        manage_no_goals_label
        manage_save_button_label
        manage_cancel_button_label
        read_label_text
      }
      directusAccountOrder {
        title
        text
        no_orders_subtitle
        number_label
        date_label
        total_label
        discount_label
        shipping_label
      }
      directusAccountSubscription {
        title
        has_subscriptions_subtitle
        no_subscriptions_subtitle
        apply_the_coupon_text
        latest_pack_label
        delivered_by_label
        invoice_label
        manage_button_label
        update_button_label
        pause_button_label
        unpause_button_label
        cancel_button_label
        restart_now_button_label
        save_unpause_button_label
        cancel_success_label
        pause_title
        unpause_title
        pause_description
        unpause_description
        pause_restart_label
        pause_cancel_button_label
        pause_confirm_button_label
        pause_success_label
        modify_pause_success_label
        update_delivery_title
        update_delivery_button_label
        change_payment_method_label
        update_success_label
        cancelation_reason_title
        cancelation_reasons {
          reason
        }
        restart_success_label
      }
      directusAccountReferral {
        title
        subtitle
        description
        refer_friend_title
        no_referral_text
      }
      directusCheckout {
        delivery_title
        delivery_first_name_label
        delivery_last_name_label
        delivery_address_label
        delivery_city_label
        delivery_postcode_label
        delivery_manual_button_label
      }
      directusPillComponent {
        title
        description
        pill_image {
          filename_disk
        }
        formulation_title
        formulation_description
        directions_description
      }
      directusBenefitsComponent {
        title
        subtitle
        items {
          name
          image_hash
        }
      }
      directusAccountConsultationResult {
        supplement_title
        supplement_list_text_1
        supplement_list_text_2
        supplement_list_text_3
        supplement_list_text_4
        supplement_list_text_5
        product_description_list_1
        product_description_list_2
        product_description_list_3
        product_description_list_4
        button_text
        product_image {
          filename_disk
        }
        no_consultation_title
        no_consultation_description
        no_consultation_button_label
        teo_banner {
          filename_disk
        }
      }
      directusSetting {
        read_label_text
      }
    }
  `);
  return content;
};
