import React from 'react';
// utilities
import { getContentStyles } from '../utilities';
// styles
import styles from './content-title.module.scss';

interface IProps {
  content: any;
}

const ContentTitle: React.FC<IProps> = ({ content }) => {
  return (
    <h1 className={styles.h1} style={getContentStyles(content.style)}>
      {content.text}
    </h1>
  );
};

export default ContentTitle;
