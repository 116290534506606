import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { GlobalContext } from '@src/store/global-state';
import Loader from '../loader/loader';

const PrivateRoute: React.FC<any> = ({ component: Component, path, ...rest }) => {
  const { isCheckLoginTried, isLoggedIn } = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    if (isCheckLoginTried && !isLoggedIn) navigate('/account/signin', { state: { path: location.pathname } });
  }, [isLoggedIn, isCheckLoginTried]);

  if (!isCheckLoginTried) return <Loader />;

  return <Component {...rest} />;
};

export default PrivateRoute;
