import React from 'react';
import ModalBody from '@components/modal/modal-body';
import styles from '@features/account/pages/result/components/GoalNutrients/GoalSlideItem.module.scss';
import Modal from '@components/modal';
import { Image } from 'vitl-component-library';
import { IGoalNutrientClaim } from '@features/account/pages/result/components/GoalNutrients/GoalNutrients';
import { IHealthGoal } from '@interfaces/goal';

interface IProps {
  goal: IHealthGoal | null;
  goalNutrients: IGoalNutrientClaim[];
  handleClose: () => void;
}

const ModalHealthGoalNutrients: React.FC<IProps> = ({ goal, goalNutrients, handleClose }) => {
  return (
    <Modal type="ResultsHealthGoalNutrients" handleClose={() => handleClose()}>
      <ModalBody type="ResultsHealthGoalNutrients">
        <div className={styles.modalTitle}>
          <Image
            url={`${goal?.imageUrl}?&colorize=%23${goal?.color.split('#')[1]}&truecolor=true`}
            width={32}
          />
          <div className={styles.modalTitleText}>
            <h3>For your</h3>
            <h4>{goal?.healthPillar}</h4>
          </div>
        </div>
        <p className={styles.modalTextNrv}>
          NRV (Nutrient Reference Value) is the daily amount recommended to{' '}
          <b>prevent deficiency</b>. NRV doesn’t refer to maximum safe intakes. All Vitl’s vitamins
          have safe levels of nutrients in.
        </p>
        <div className={styles.nutrientListWrapper}>
          <ul className={styles.nutrientsList}>
            {goalNutrients?.length > 0 &&
              goalNutrients.map(nutrientClaim => (
                <li key={nutrientClaim.nutrient.name}>
                  <div className={styles.nutrientListHeader}>
                    <h4>{nutrientClaim.nutrient.name}</h4>
                    <span>
                      {nutrientClaim.nutrient.amount}
                      {nutrientClaim.nutrient.unit} | {nutrientClaim.nutrient.nrv}% NRV
                    </span>
                  </div>
                  <p>{nutrientClaim.claim}</p>
                </li>
              ))}
          </ul>
        </div>
        {goalNutrients?.length > 5 && (
          <div className={styles.scrollToSeeMore}>Scroll down to see all nutrients</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalHealthGoalNutrients;
