import React, { useContext, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import ModalHeader from '@components/modal/modal-header';
import ModalFooter from '@components/modal/modal-footer';
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { formatDate } from '@services/format';
import { areDatesEqual } from '@services/global';
import { ISubscription } from '@interfaces/subscriptions';
import { usePauseSubscription } from '@hooks/useSubscription';
import { addDaysToTimestamp, daysFromNow } from '../../../utils/date';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

import styles from '../account-subscription/account-subscription.module.scss';

interface IProps {
  restartDate: string;
  setRestartDate: (restartDate: string) => void;
  setModal: (showCancelReasonModal: boolean) => void;
  subscription: ISubscription;
  unpauseSubscription: (formData: any) => void;
  setDropdownIsOpen: (dropdownOpen: boolean) => void;
  setSuccessMessage: (message: string) => void;
  refetchSubscriptions: () => void;
}

const CalendarModal = ({
  setModal,
  subscription,
  restartDate,
  setRestartDate,
  unpauseSubscription,
  setDropdownIsOpen,
  setSuccessMessage,
  refetchSubscriptions,
}: IProps) => {
  const { directusAccountSubscription } = useAccountText();
  const { setMessageModal, setErrorModalMessage } = useContext(GlobalContext);

  const date14DaysFromToday = daysFromNow(14);

  const [selectedRestartDate, setSelectedRestartDate] = useState(
    subscription.restartsAt
      ? addDaysToTimestamp(new Date(+subscription.restartsAt * 1000), 1)
      : date14DaysFromToday
  );

  const isSubscriptionPaused = subscription.status === 'paused';

  const pauseSubscriptionSucess = () => {
    setModal(false);
    setDropdownIsOpen(false);
    setMessageModal({
      visible: true,
      title: 'Success',
      message: isSubscriptionPaused
        ? directusAccountSubscription.modify_pause_success_label
        : directusAccountSubscription.pause_success_label,
      buttonLabel: 'OK',
    });
    setSuccessMessage(
      isSubscriptionPaused
        ? directusAccountSubscription.modify_pause_success_label
        : directusAccountSubscription.pause_success_label
    );
    setRestartDate(`${selectedRestartDate.getTime() / 1000}`);
    refetchSubscriptions();
  };
  const pauseSubscriptionError = () => {
    setErrorModalMessage(ERROR_MESSAGES.pauseSubscription);
  };

  const { pauseSubscription, loading } = usePauseSubscription(
    pauseSubscriptionSucess,
    pauseSubscriptionError
  );

  const handlePauseSubscription = () => {
    pauseSubscription({
      variables: {
        id: subscription.id,
        restartAt: new Date(selectedRestartDate),
      },
    });
  };

  return (
    <Modal handleClose={() => setModal(false)}>
      <ModalHeader>
        <h3 data-testid="subscription-pause-header">
          {isSubscriptionPaused
            ? directusAccountSubscription.unpause_title
            : directusAccountSubscription.pause_title}
        </h3>
        <hr />
        <p>
          {isSubscriptionPaused
            ? directusAccountSubscription.unpause_description
            : directusAccountSubscription.pause_description}
        </p>
      </ModalHeader>
      <ModalBody>
        <ReactDatePicker
          selected={selectedRestartDate}
          dateFormat="yyyy-MM-dd"
          inline
          disabledKeyboardNavigation
          minDate={new Date()}
          onChange={(date: any) => {
            setSelectedRestartDate(date);
          }}
        />
        {selectedRestartDate && (
          <>
            <p className={styles.restartDate}>
              {directusAccountSubscription.pause_restart_label} {formatDate(selectedRestartDate)}
            </p>
            <p className={styles.restartDateText}>
              If you’re currently participating in a limited time offer, this does not pause when
              you delay your subscription, which means your discount could expire.
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter
        closeLabel={directusAccountSubscription.pause_cancel_button_label}
        saveLabel={
          isSubscriptionPaused
            ? areDatesEqual(restartDate, new Date())
              ? directusAccountSubscription.restart_now_button_label
              : directusAccountSubscription.save_unpause_button_label
            : directusAccountSubscription.pause_confirm_button_label
        }
        isSaveDisabled={
          !selectedRestartDate || selectedRestartDate.getTime() <= new Date().getTime()
        }
        loading={loading}
        handleClose={() => setModal(false)}
        handleSave={() =>
          areDatesEqual(restartDate, new Date())
            ? unpauseSubscription({
                variables: {
                  id: subscription.id,
                },
              })
            : handlePauseSubscription()
        }
      ></ModalFooter>
    </Modal>
  );
};

export default CalendarModal;
