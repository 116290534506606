import React, { useContext, useEffect, useState } from 'react';
import styles from '@components/account-consultation-result/account-consultation-result.module.scss';
import { useAccountText } from '@hooks/directusHooks/useAccountText';
import { useGetAccountData } from '@features/account/hooks/useGetAccountData';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import PillLoader from './PillLoader';
import { navigate } from 'gatsby';
import { RESULT_STEP_URLS } from '../../constants/constants';

const PillLoading = (textLoading: boolean = false) => {
  useGetAccountData();

  const {
    dataGoals,
    dataHealthResults,
    loadingGoals,
    loadingRecommended,
    dataRecommendedProducts,
  } = useContext(ResultPageContext);

  const { directusAccountConsultationResult } = useAccountText();

  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    if (
      animationCompleted &&
      !loadingGoals &&
      !loadingRecommended &&
      dataHealthResults &&
      dataGoals
    ) {
      if (
        dataRecommendedProducts &&
        dataRecommendedProducts?.health_recommendedProducts?.length < 1
      ) {
        navigate('/account/recommendation');
        return;
      }
      navigate(RESULT_STEP_URLS.step_1);
    }
  }, [animationCompleted]);

  return (
    <PillLoader setAnimationCompleted={setAnimationCompleted}>
      <div className={styles.textLoader}>
        <span className={styles.message}>
          {directusAccountConsultationResult.supplement_list_text_1}
        </span>
        <span className={styles.message}>
          {directusAccountConsultationResult.supplement_list_text_2}
        </span>
        <span className={styles.message}>
          {directusAccountConsultationResult.supplement_list_text_3}
        </span>
        <span className={styles.message}>
          {directusAccountConsultationResult.supplement_list_text_4}
        </span>
        <span className={styles.message}>
          {directusAccountConsultationResult.supplement_list_text_5}
        </span>
      </div>
    </PillLoader>
  );
};

export default PillLoading;
