import React, { useContext, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { SwiperComponent } from 'vitl-component-library';
//types
import { ICrossSellMyVitlProduct, IProductPlan, IRecommendedProduct } from '@src/types/product';
//context
import { GlobalContext } from '@src/store/global-state';
//hooks
import useCrossSellMyVitlProducts from '@hooks/useCrossSellMyVitlProducts';
//components
import { RecommendedProduct } from './RecommendedProduct';
import Loader from '../../loader/loader';
//config
import { mapCrossSellMyVitlProductToShopProduct } from './mapper';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
// styles
import styles from './recommended-for-you.module.scss';

interface IProps {
  title?: string;
  healthRecommendedProducts: IRecommendedProduct[];
}
const RecommendedForYou = ({
  title = 'Recommended for you',
  healthRecommendedProducts,
}: IProps) => {
  const hasUserHealthRecommendedProducts = healthRecommendedProducts.length > 0;
  const { setShowShopModal, setCurrentProductAndPlanData } = useContext(GlobalContext);
  const { crossSellMyVitlProducts, loadingCrossSellMyVitlProducts } = useCrossSellMyVitlProducts();

  const { allPlans } = useGetProductPlansData();

  const getCurrentPlan = (sku: string) => {
    const currentPlan = allPlans?.product_plans.filter(plan => plan.parentSku === sku);
    const currentPlanFormatted = { product_plans: currentPlan };
    return currentPlanFormatted;
  };

  const onClickProduct = (
    product: ICrossSellMyVitlProduct,
    healthRecommendedProducts: IRecommendedProduct[],
    isProductPurchaseAvailable: boolean
  ) => {
    if (!isProductPurchaseAvailable) {
      navigate('/consultation');
      return;
    }

    const productPlan = getCurrentPlan(product.configurableSku);
    const plan =
      product.configurableSku === 'personalised'
        ? {
            product_plans: productPlan?.product_plans?.filter((item: IProductPlan) =>
              item?.sku?.includes(
                `${healthRecommendedProducts.find(product => product?.id.startsWith('sn'))?.id}-`
              )
            ),
          }
        : product.configurableSku === 'essential-one'
        ? {
            product_plans: productPlan?.product_plans?.filter((item: IProductPlan) =>
              item?.sku
                ?.replace('-monthly-30', '')
                ?.includes(
                  healthRecommendedProducts?.find(product =>
                    product.id.startsWith('essential-one')
                  )!?.id
                )
            ),
          }
        : productPlan;

    setCurrentProductAndPlanData({
      product: mapCrossSellMyVitlProductToShopProduct(product),
      plan,
    });

    setShowShopModal(true);
  };

  const recommendedProducts = crossSellMyVitlProducts?.map(product => {
    return (
      <RecommendedProduct
        key={product.configurableSku}
        loading={false}
        product={product}
        healthRecommendedProducts={healthRecommendedProducts}
        onClick={onClickProduct}
      />
    );
  });

  if (!loadingCrossSellMyVitlProducts && !crossSellMyVitlProducts) return null;

  return (
    <>
      <section className={styles.section}>
        <div className={styles.container}>
          <h2 className={styles.h2}>{title}</h2>
          <p className={styles.subtitle}>
            {hasUserHealthRecommendedProducts ? (
              `The best products for you, based on your body, your lifestyle & your health goals.`
            ) : (
              <span>
                Complete your <Link to={'/consultation'}>free health quiz</Link> to get personalised
                recommendations
              </span>
            )}
          </p>
          {loadingCrossSellMyVitlProducts ? (
            <Loader hasContainer={true} isDark={false} />
          ) : (
            <div className={styles.swiperComponentWrapper}>
              <div className={styles.swiperComponent}>
                <SwiperComponent paginationType="bullets" showArrows overlaps={true}>
                  {recommendedProducts}
                </SwiperComponent>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default RecommendedForYou;
