import React from 'react';
import { Badge } from 'vitl-component-library';

import { useDiscount } from '../../hooks/useDiscount';

import styles from './hero.module.scss';

export const DiscountBadge = () => {
  const badgeText = useDiscount();
  if (!badgeText) return null;

  return (
    <div className={styles.discount}>
      <Badge
        isSmall={true}
        label={badgeText.text}
        className={styles.offerBadgeTreeTrial}
        bgColor={badgeText.color}
      />
    </div>
  );
};
