import { trackEvent } from '@src/services/tracking/tracking';
import { ACCOUNT_RESULT_OFFER_CODES } from '../constants/constants';
import useIsProductUpToDate from './useIsProductUpToDate';
import useAccountResult from './useAccountResult';
import { useDiscount } from './useDiscount';

type tracking =
  | ACCOUNT_RESULT_OFFER_CODES.EssentialOne
  | ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus;

export const useTracking = () => {
  const isProductUpToDate = useIsProductUpToDate();
  const { shouldUpdatePack } = useAccountResult();
  const discount = useDiscount();

  const track = (product: tracking) => {
    if (product === ACCOUNT_RESULT_OFFER_CODES.EssentialOne) {
      if (isProductUpToDate) {
        trackEvent('view_recommendation_EO_no_update_available');
      }
      if (shouldUpdatePack) {
        trackEvent('view_recommendation_EO_update_available');
      }
      if (discount?.text === 'free trial') {
        trackEvent('view_recommendation_EO_FT');
      }
      if (discount?.text === '30% off the first 3 orders') {
        trackEvent('view_recommendation_EO_30off3');
      }
      if (discount?.text === '50% off the first month') {
        trackEvent('view_recommendation_EO_50off');
      }
      if (discount?.text === '55% off the first month') {
        trackEvent('view_recommendation_EO_55off');
      }
      if (discount?.text === '70% off the first month') {
        trackEvent('view_recommendation_EO_70off');
      }
      if (!discount?.text) {
        trackEvent('view_recommendation_EO_FullPrice');
      }
    }
    if (product === ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus) {
      if (isProductUpToDate) {
        trackEvent('view_recommendation_4PP_no_update_available');
      }
      if (shouldUpdatePack) {
        trackEvent('view_recommendation_4PP_update_available');
      }
      if (discount?.text === 'free trial') {
        trackEvent('view_recommendation_4PP_FT');
      }
      if (discount?.text === '30% off the first 3 orders') {
        trackEvent('view_recommendation_4PP_30off3');
      }
      if (discount?.text === '50% off the first month') {
        trackEvent('view_recommendation_4PP_50off');
      }
      if (discount?.text === '55% off the first month') {
        trackEvent('view_recommendation_4PP_55off');
      }
      if (discount?.text === '70% off the first month') {
        trackEvent('view_recommendation_4PP_70off');
      }
      if (!discount?.text) {
        trackEvent('view_recommendation_4PP_FullPrice');
      }
    }
  };

  return track;
};
