import React, { useState, useEffect, createContext } from 'react';

import { isSSR } from '@src/services/global';
import Routes from './routes';
//STORE
import { ResultStoreProvider } from './pages/result/store/store';

interface IContentScreen {
  content_submitForm: {
    canDismiss: boolean;
    canNavigateBack: boolean;
    jsonNavigateBack: boolean;
    jsonSection: any;
    jsonStyle: string | null;
    title: string;
  };
}

interface IAccount {
  recommendedProducts: any;
  setRecommendedProducts: any;
  contentScreen?: IContentScreen;
  setContentScreen: any;
}

// content Provider
export const ContentAccountContext = createContext<Partial<IAccount>>({});

const NewAccount: React.FC = () => {
  const [recommendedProducts, setRecommendedProducts] = useState<any>(null);
  const [contentScreen, setContentScreen] = useState<IContentScreen | undefined>(undefined);

  const accountContext = {
    contentScreen,
    setContentScreen,
    recommendedProducts,
    setRecommendedProducts,
  };

  useEffect(() => {
    return () => {
      setContentScreen(undefined);
    };
  }, []);

  return (
    <ContentAccountContext.Provider value={accountContext}>
      <ResultStoreProvider>{!isSSR ? <Routes /> : <p></p>}</ResultStoreProvider>
    </ContentAccountContext.Provider>
  );
};

export default NewAccount;
