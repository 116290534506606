import React, { useMemo } from 'react';
//types
import { ICrossSellMyVitlProduct } from '@src/types/product';
import { IRecommendedProduct as IHealthRecommendedProduct } from '@src/types/product';
//components
import { Button } from 'vitl-component-library';
import ProductBadge from '@src/features/shop/components/product/product-badge';
//services
import { formatPrice } from '@src/services/format';
import { mapCrossSellMyVitlProductToShopProduct } from './mapper';
//style
import styles from './recommended-product.module.scss';

interface IRecommendedProduct extends ICrossSellMyVitlProduct {
  imageSrc?: string;
}

interface IProps {
  product: IRecommendedProduct;
  healthRecommendedProducts: IHealthRecommendedProduct[];
  onClick: (
    product: ICrossSellMyVitlProduct,
    healthRecommendedProducts: IHealthRecommendedProduct[],
    isProductPurchaseAvailable: boolean
  ) => void;
  loading: boolean;
}
export const RecommendedProduct = ({
  product,
  onClick,
  healthRecommendedProducts,
  loading,
}: IProps) => {
  const {
    name,
    imageSrc,
    imageUrl,
    backgroundColor,
    caption,
    callouts,
    cta,
    minPrice,
    configurableSku,
    route,
  } = product;

  const isProductPurchaseAvailable = useMemo(() => {
    const isProductPersonalisedOrEO =
      configurableSku === 'personalised' || configurableSku == 'essential-one';

    if (!isProductPersonalisedOrEO) return true;

    const isPersonalisedAlreadyRecommended =
      healthRecommendedProducts.some(product => product.subtype === 'personalised') &&
      configurableSku === 'personalised';

    const isEoAlreadyRecommended =
      healthRecommendedProducts.some(product => product.subtype === 'theone') &&
      configurableSku === 'essential-one';

    if (isPersonalisedAlreadyRecommended || isEoAlreadyRecommended) {
      return true;
    }
    return false;
  }, [configurableSku, healthRecommendedProducts]);

  const image = imageSrc || `${process.env.GATSBY_DIRECTUS_MARKETING_MEDIA_URL}/${imageUrl}`;

  return (
    <article className={styles.recommendedProduct}>
      <ProductBadge
        product={mapCrossSellMyVitlProductToShopProduct(product)}
        className={styles.badgeWrap}
      />
      <div className={styles.recommendedProductHeading}>
        <a className={styles.recommendedProductTitle} href={route}>
          {name}
        </a>
        <p className={styles.recommendedProductPriceLabel}>from {formatPrice(minPrice)}</p>
      </div>
      <div className={styles.recommendedProductContent}>
        <a
          className={styles.recommendedProductImage}
          style={{
            backgroundImage: `url(${image})`,
            backgroundColor: backgroundColor,
          }}
          href={route}
        ></a>
        <div className={styles.recommendedFooter}>
          <div>
            <p className={styles.recommendedProductCaption}>{caption}</p>
            <div className={styles.recommendedProductCallouts}>
              {callouts?.map((callout, index) => {
                return (
                  <p key={index}>
                    <img
                      className={styles.grayTick}
                      src="/images/icon-compare-check.svg"
                      alt="check"
                      width={10}
                    />
                    <span>{callout}</span>
                  </p>
                );
              })}
            </div>
          </div>
          <div data-testid={`recommended-product-button-${name}`}>
            <Button
              title={isProductPurchaseAvailable ? cta : 'Take health quiz'}
              primary={!isProductPurchaseAvailable}
              disabled={false}
              loading={loading}
              onClick={() =>
                onClick(product, healthRecommendedProducts, isProductPurchaseAvailable)
              }
              className={`${styles.addToBasketButton} ${
                isProductPurchaseAvailable ? styles.whiteButton : ''
              }`}
            />
          </div>
        </div>
      </div>
    </article>
  );
};
