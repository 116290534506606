import React, { createContext, useState } from 'react';
import { IResult } from '../interfaces/global';
import { IProduct, IProductPill } from '@src/types/product';
import { ACCOUNT_RESULT_OFFER_CODES } from '../constants/constants';

// content Provider
export const ResultPageContext = createContext<IResult>({} as IResult);

interface IProps {
  children: JSX.Element;
}

export const ResultStoreProvider = ({ children }: IProps) => {
  // _____ Cached Backend data _____
  const [dataGoals, setDataGoals] = useState<any>(null);
  const [dataRecommendedProducts, setDataRecommendedProducts] = useState<any>(null);
  const [loadingGoals, setLoadingGoals] = useState<boolean>(true);
  const [loadingRecommended, setLoadingRecommended] = useState<boolean>(true);
  const [error, setError] = useState<any>(false);
  const [dataHealthResults, setDataHealthResults] = useState<any>(null);
  const [loadingHealthResults, setLoadingHealthResults] = useState<any>(null);
  const [dataHealthAttributes, setDataHealthAttributes] = useState<any>(null);
  const [loadingHealthAttributes, setLoadingHealthAttributes] = useState<any>(null);
  const [userName, setUsername] = useState<string>('');
  // _____ STATES _____
  const [showQuizResults, setShowQuizResults] = useState<boolean>(true); //! REMOVE THIS WHEN WE MOVE showQuizResults to router
  const [personalisedProduct, setPersonalisedProduct] = useState<IProduct | null>(null);
  const [personalisedProductPills, setPersonalisedProductPills] = useState<IProductPill[] | null>(
    null
  );
  const [nutrientTotal, setNutrientCountTotal] = useState(0);
  const [displayProductCode, setDisplayProductCode] = useState<
    ACCOUNT_RESULT_OFFER_CODES | undefined
  >(undefined);
  const [isUpgradeSectionVisible, toggleUpgradeSection] = useState(true);
  const [partnership, setPartnership] = useState<string | undefined | null>(null);
  const [freeTrial, setFreeTrial] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [goalIsUpdated, setGoalIsUpdated] = useState<boolean>(false);
  // Modals
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [showModalSwitchSubscription, setShowModalSwitchSubscription] = useState(false);
  const [modalNutrients, setModalNutrients] = useState<IProductPill | null>(null);
  const [consultationStatusCompleted, setConsultationStatusComplete] = useState<boolean>(false);

  const resultContext = {
    dataHealthResults,
    setDataHealthResults,
    loadingHealthResults,
    setLoadingHealthResults,
    dataHealthAttributes,
    setDataHealthAttributes,
    loadingHealthAttributes,
    setLoadingHealthAttributes,
    setShowQuizResults,
    showQuizResults,
    personalisedProduct,
    setPersonalisedProduct,
    nutrientTotal,
    setNutrientCountTotal,
    displayProductCode,
    setDisplayProductCode,
    isUpgradeSectionVisible,
    toggleUpgradeSection,
    isErrorModalVisible,
    setIsErrorModalVisible,
    partnership,
    setPartnership,
    freeTrial,
    setFreeTrial,
    showModalSwitchSubscription,
    setShowModalSwitchSubscription,
    subscription,
    setSubscription,
    personalisedProductPills,
    setPersonalisedProductPills,
    dataGoals,
    setDataGoals,
    dataRecommendedProducts,
    setDataRecommendedProducts,
    loadingRecommended,
    setLoadingRecommended,
    setLoadingGoals,
    loadingGoals,
    error,
    setError,
    modalNutrients,
    setModalNutrients,
    goalIsUpdated,
    setGoalIsUpdated,
    userName,
    setUsername,
    consultationStatusCompleted,
    setConsultationStatusComplete,
  };

  return <ResultPageContext.Provider value={resultContext}>{children}</ResultPageContext.Provider>;
};
