import React, { FC, useEffect } from 'react';
import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '../../../../styles/fade.module.scss';
import styles from './cancel-discount-modal.module.scss';
import { trackGAEvent } from '@services/tracking/ga';

interface ICancelDiscountModalProps {
  showModal: boolean;
  closeModal: () => void;
  onDiscountClick: () => void;
  onCancelClick: () => void;
  loading: boolean;
}

const CancelDiscountModal: FC<ICancelDiscountModalProps> = ({
  showModal,
  closeModal,
  onDiscountClick,
  onCancelClick,
  loading,
}) => {
  useEffect(() => {
    trackGAEvent('cancellation_discount', {});
  }, []);

  return (
    <CSSTransition in={showModal} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal handleClose={closeModal}>
        <ModalBody>
          <div className={styles.modalBody}>
            <h5>Have 30% off 3 months on us</h5>
            <p>
              Remember, to feel the full benefits of your vitamins it's important to take them every
              day. If you're struggling to make it a habit, we're here to help!{' '}
              <b>Take 30% off your next 3 months and see what a difference it makes.</b>
            </p>
            <div className={styles.actionButtons}>
              <button className={styles.buttonPrimary} onClick={onDiscountClick} disabled={loading}>
                I want a 30% discount
              </button>
              <button className={styles.buttonInverted} onClick={onCancelClick} disabled={loading}>
                I want to cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default CancelDiscountModal;
