import React, { useContext, useEffect, useState } from 'react';

import AdditionalFeedbackModal from './additional-feedback-modal/additional-feedback-modal';
import ShowCancelReasonModal from './show-cancel-reason-modal/show-cancel-reason-modal';
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { useCancelSubscription } from '@hooks/useSubscription';
import { MODAL_TYPES } from '../constants';
import AreYouSureModal from './are-you-sure-modal/AreYouSureModal';
import { ISubscription } from '../../../types/subscription';
import CancelDiscountModal from './cancel-discount-modal/CancelDiscountModal';
import { SUBSCRIPTION_CLAIM_CANCELLATION_OFFER } from '@queries/orders';
import { useMutation } from 'react-apollo';
import { trackGAEvent } from '@services/tracking/ga';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  refetchSubscriptions: () => void;
  subscription: ISubscription;
  setSuccessMessage: (message: string) => void;
  showCancelModal: boolean;
  setShowCancelModal: (showCancelModal: boolean) => void;
  setShowCalendarModal: (showCalendarModal: boolean) => void;
}

const CancelModal = ({
  subscription,
  refetchSubscriptions,
  setSuccessMessage,
  showCancelModal,
  setShowCancelModal,
  setShowCalendarModal,
}: IProps) => {
  const { setMessageModal, setErrorModalMessage } = useContext(GlobalContext);
  const { directusAccountSubscription } = useAccountText();
  const [chosenCancellationReasons, setChosenCancellationReasons] = useState('');
  const [showModal, setShowModal] = useState('');

  const [claimCancellationOffer, { loading: claimCancellationOfferLoading }] = useMutation(
    SUBSCRIPTION_CLAIM_CANCELLATION_OFFER,
    {
      variables: {
        id: subscription?.id,
      },
      onCompleted: () => {
        refetchSubscriptions();
        closeModal();
        setMessageModal({
          visible: true,
          title: 'Success',
          message:
            'Your 30% discount has now been applied to your subscription and is valid for the next 3 months.',
          buttonLabel: 'OK',
        });
      },
      onError: () => {
        closeModal();
        setMessageModal({
          visible: true,
          title: 'Error',
          message:
            "Oops, looks like we're having some trouble applying a coupon code to your subscription. Please contact our team via live chat on https://vitl.com/contact or email hello@vitl.com.",
          buttonLabel: 'OK',
        });
      },
    }
  );

  useEffect(() => {
    if (showCancelModal) {
      initializeSubscriptionCancelModal();
    }
  }, [showCancelModal]);

  const initializeSubscriptionCancelModal = () => {
    if (
      subscription.canOfferCancellationDiscount &&
      subscription.product.sku.includes('personalised-monthly-') &&
      !subscription.product.sku.includes('free-trial')
    ) {
      setShowModal(MODAL_TYPES.areYouSureModal);
      return;
    }
    setShowModal(MODAL_TYPES.cancelReasonModal);
  };

  const closeModal = () => {
    setShowModal('');
    setShowCancelModal(false);
  };

  const handleAreYouSureKeepButtonClick = () => {
    closeModal();
    trackGAEvent('cancellation_usp_notcancel', {});
  };

  const handleAreYouSurePauseButtonClick = () => {
    closeModal();
    trackGAEvent('cancellation_usp_pause', {});
    setShowCalendarModal(true);
  };

  const handleAreYouSureCancelButtonClick = () => {
    if (subscription.canOfferCancellationDiscount) {
      setShowModal(MODAL_TYPES.offerDiscountModal);
      return;
    }
    setShowModal(MODAL_TYPES.cancelReasonModal);
  };

  const handleDiscountClaimButtonClick = () => {
    trackGAEvent('cancellation_discount_getdiscount', {});
    claimCancellationOffer();
  };

  const handleDiscountCancelButtonClick = () => {
    setShowModal(MODAL_TYPES.cancelReasonModal);
  };

  const onCancelSubscriptionSuccess = () => {
    trackGAEvent('cancellation_complete', {});
    refetchSubscriptions();
    closeModal();
    setSuccessMessage(directusAccountSubscription.cancel_success_label);
    setMessageModal({
      visible: true,
      title: 'Success',
      message: directusAccountSubscription.cancel_success_label,
      buttonLabel: 'OK',
    });
  };
  const onCancelSubscriptionError = () => {
    closeModal();
    setErrorModalMessage(ERROR_MESSAGES.cancelSubscription);
  };

  const { cancelSubscription, loading } = useCancelSubscription(
    onCancelSubscriptionSuccess,
    onCancelSubscriptionError
  );

  const handleAdditionalFeedbackSubmit = (formData: any) => {
    cancelSubscription({
      variables: {
        id: subscription.id,
        feedback: chosenCancellationReasons,
        additionalFeedback: formData.additionalFeedback,
      },
    });
  };

  const handleCancellationReasonSubmit = (formData: any) => {
    setChosenCancellationReasons(formData.cancellationReasons);
    setShowModal(MODAL_TYPES.additionalFeedBackModal);
  };

  return (
    <>
      {showModal === MODAL_TYPES.areYouSureModal && (
        <AreYouSureModal
          showModal={showModal === MODAL_TYPES.areYouSureModal}
          subscription={subscription}
          closeModal={closeModal}
          onPauseClicked={handleAreYouSurePauseButtonClick}
          onCancelClicked={handleAreYouSureCancelButtonClick}
          onKeepClicked={handleAreYouSureKeepButtonClick}
        />
      )}
      {showModal === MODAL_TYPES.offerDiscountModal && (
        <CancelDiscountModal
          showModal={showModal === MODAL_TYPES.offerDiscountModal}
          closeModal={closeModal}
          onDiscountClick={handleDiscountClaimButtonClick}
          onCancelClick={handleDiscountCancelButtonClick}
          loading={claimCancellationOfferLoading}
        />
      )}
      {showModal === MODAL_TYPES.cancelReasonModal && (
        <ShowCancelReasonModal
          showModal={showModal === MODAL_TYPES.cancelReasonModal}
          closeModal={closeModal}
          subscription={subscription}
          onSubmit={handleCancellationReasonSubmit}
        />
      )}
      {showModal === MODAL_TYPES.additionalFeedBackModal && (
        <AdditionalFeedbackModal
          showModal={showModal === MODAL_TYPES.additionalFeedBackModal}
          closeModal={closeModal}
          subscription={subscription}
          loading={loading}
          onSubmit={handleAdditionalFeedbackSubmit}
        />
      )}
    </>
  );
};

export default CancelModal;
