import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// components
import SubmitButton from '../submit-button';
// styles
import styles from './account-payment-update.module.scss';
import CustomInput from '../form/custom-input';
import CustomCheckbox from '../form/custom-checkbox';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  paymentData: any;
  submit: (formData: any) => void;
  loading: boolean;
}

const AccountPaymentUpdate: React.FC<IProps> = ({ paymentData, submit, loading }) => {
  const { directusAccount } = useAccountText();

  // state
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
    setValue,
  } = useForm();

  const onSubmit = (formData: any) => {
    submit(formData);
  };

  useEffect(() => {
    setValue('postcode', paymentData?.postcode);
    setValue('setAsDefault', paymentData?.default);
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <fieldset>
        <CustomInput
          id="postcode"
          label={directusAccount?.payment_update_postcode_label}
          register={register({ minLength: 4 })}
          isDirty={dirtyFields.postcode || paymentData?.postcode}
          error={errors.postcode}
          errorMessage="Please enter a valid postcode"
        />
        <div className={styles.checkboxWrapper}>
          <CustomCheckbox
            label="Set as default payment"
            id="setAsDefault"
            name="setAsDefault"
            register={register()}
          />
        </div>
      </fieldset>

      <SubmitButton
        loading={loading}
        buttonLabel={directusAccount.payment_update_submit_button_label}
        fullWidth
      />
    </form>
  );
};

export default AccountPaymentUpdate;
