import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonResultsFlowStep = () => {
  return (
    <Skeleton
      width={`100%`}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      baseColor={'#ffd32605'}
      highlightColor={'#f8b91a15'}
    />
  );
};
export default SkeletonResultsFlowStep;
