import React from 'react';
// utilities
import { getContentStyles, handleContentAction } from '../utilities';
// styles
import styles from './content-text.module.scss';

interface IProps {
  content: any;
}

const ContentText: React.FC<IProps> = ({ content }) => {
  if (content.text !== 'Read more' && content.text !== 'View contents') {
    return (
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: content.text }}
        style={getContentStyles(content.style)}
      />
    );
  }
  if (content.action) {
    return (
      <button
        type="button"
        className={styles.link}
        onClick={() => handleContentAction(content.action)}
        style={getContentStyles(content.style)}
      >
        {content.text}
      </button>
    );
  }
  return null;
};

export default ContentText;
