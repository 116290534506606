import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from '@reach/router';
// context
import { GlobalContext } from '@store/global-state';
// components
import { TrustPilot, AddToBasket30Percent3MonthsOffer } from 'vitl-component-library';
import Modal from '../modal';
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import PartnersSlider from '@components/partners-slider/partners-slider';
import ModalFooter from '../modal/modal-footer';
import Goals from '../goals';
import SectionBenefits from '../section-benefits';
import Accordion from '../accordion';
import Reviews from '@components/reviews/reviews';
import Loader from '../loader';
import AddToBasket from '../add-to-basket';
import ModalNutrients from '../modal-nutrients';
import ModalWarningScreen from '../modal-warning-screen';
import Image from '@components/image/image';
import SubmitButton from '@components/submit-button';
// queries
import { GOALS, RECOMMENDED_PRODUCTS } from '@queries/user';
import { GET_PRODUCT } from '@queries/product';
import { SUBSCRIPTIONS } from '@queries/orders';
import { SUBSCRIPTION_SWITCH_LINE } from '@queries/subscription';
// services
import { checkFreeTrial } from '@services/global';
import { getCookie } from '@services/cookies';
import { formatGraphQLError } from '@services/format';
import { trackEvent, trackHeapEvent } from '@src/services/tracking/tracking';
// styles
import styles from './account-result.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
// interfaces
import {
  IGetProductQueryResponse,
  IHealthGoalsQueryResponse,
  IHealthRecommendedProductsQueryResponse,
  IUserSubscriptionsQueryResponse,
} from '../../types/query-response';
import { ISubscription } from '../../types/subscription';
import { IProduct, IProductPill, IRecommendedProduct } from '../../types/product';
// hooks
import useGlobalError from '@hooks/useGlobalError';
import { useGetUserDetails } from '@hooks/useGetUserDetails';
import useAddToBasketDNAUpsell from '@components/account-result/hooks/useAddToBasketDNAUpsell';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
// constants
import { PILL_COLORS } from './pill-colors';
import { COOKIES } from '@constants/cookies';
import { PACK_NAMES } from '@constants/enums';
import {
  ADD_TO_BASKET_OFFER_LABEL,
  DISCOUNT_30_3_MONTHS_INFO,
  DISCOUNT_50_INFO,
  DISCOUNT_55_INFO,
  DISCOUNT_70_INFO,
  productAlternative,
} from '@components/account-result/constants';
import {
  ERROR_MESSAGES,
  OFFER_CODES,
  PAGE_URL_TO_PRODUCT_CODE,
  PRODUCT_COOKIES,
} from '@src/constants';
import { get30PercentOf3MonthsPrices } from '@components/account-result/utils';
import couponCodeService from '@src/utils/couponCodeService';
import { useBasket } from '@src/hooks/useBasket';
import { essentialPageVisitSetCookie, fourPPPageVisitSetCookie } from '@src/utils/coupons';

const AccountResult: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { getOffer } = couponCodeService();

  const {
    directusConsultationResult,
    directusAccountConsultationResult,
    directusAccount,
    directusBenefitsComponent,
  } = useAccountText();

  const { setErrorModalMessage, setShowBasket } = useContext(GlobalContext);

  const { data: dataGoals, loading: loadingGoals, error: errorGoals } = useQuery<
    IHealthGoalsQueryResponse
  >(GOALS);

  const { data: dataRecommendedProducts, loading: loadingResult, error: errorResult } = useQuery<
    IHealthRecommendedProductsQueryResponse
  >(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const { data: basketData } = useBasket('network-only');

  const { user, loading: loadingUserDetails } = useGetUserDetails();
  const [
    getProduct,
    { data: dataProduct, loading: loadingProduct, error: errorProduct },
  ] = useLazyQuery<IGetProductQueryResponse>(GET_PRODUCT);

  const [
    getSubscriptions,
    { data: dataSubscriptions, loading: loadingSubscriptions, error: errorSubscriptions },
  ] = useLazyQuery<IUserSubscriptionsQueryResponse>(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setShowModalSwitchSubscription(true);
    },
    onError: error => {
      error.message && setErrorModalMessage(error.message && formatGraphQLError(error.message));
    },
  });

  const [subscriptionSwitchLine, { loading: loadingSubscriptionSwitchLine }] = useMutation(
    SUBSCRIPTION_SWITCH_LINE,
    {
      onError: error => {
        setShowModalSwitchSubscription(false);
        error.message && setErrorModalMessage(error.message && formatGraphQLError(error.message));
      },
    }
  );

  const [personalisedProduct, setPersonalisedProduct] = useState<IProduct | null>(null);
  const [displayProductCode, setDisplayProductCode] = useState<OFFER_CODES | ''>('');
  const [partnership, setPartnership] = useState<string | undefined | null>(null);
  const [freeTrial, setFreeTrial] = useState(false);
  const [modalNutrients, setModalNutrients] = useState<IProductPill>();
  const [showModalNutrients, setShowModalNutrients] = useState(false);
  const [isUpgradeSectionVisible, toggleUpgradeSection] = useState(true);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [loadingProductSwitch, setLoadingProductSwitch] = useState(false);
  const [showModalSwitchSubscription, setShowModalSwitchSubscription] = useState(false);
  const [ingredientsTotal, setIngredientsTotal] = useState(0);

  const currentRecommendedProduct = useMemo(() => {
    if (!personalisedProduct) {
      return null;
    }
    return dataRecommendedProducts?.health_recommendedProducts?.find(
      p => p.id === personalisedProduct?.id
    );
  }, [personalisedProduct, dataRecommendedProducts]);

  const isSubscribedToRecommendedProduct = currentRecommendedProduct?.isSubscribed || false; //! isSubscribedToCurrentRecommendedProduct

  const shouldUpdatePack = currentRecommendedProduct?.updateAvailable || false;

  const getPackName = displayProductCode && PACK_NAMES[displayProductCode];

  const getAllSwitchLinePromises = (
    filteredSubscriptions: ISubscription[],
    currentRecommendedProductVariant: string
  ) =>
    filteredSubscriptions.map(s => {
      return new Promise(async resolve => {
        await subscriptionSwitchLine({
          variables: {
            subscriptionId: s.id,
            variant: currentRecommendedProductVariant,
          },
        });
        resolve(true);
      });
    });

  const confirmUpdateSubscription = async () => {
    const currentRecommendedProductSubtype =
      currentRecommendedProduct?.subtype === 'theone'
        ? 'essential-one'
        : currentRecommendedProduct?.subtype;

    const currentRecommendedProductVariant = currentRecommendedProduct?.variant?.replace(
      'essential-one-',
      ''
    );

    const filteredSubscriptions =
      currentRecommendedProductSubtype && dataSubscriptions
        ? dataSubscriptions?.user_subscriptions?.filter(s =>
            s.product?.sku?.includes(currentRecommendedProductSubtype)
          )
        : [];

    if (filteredSubscriptions.length > 0 && currentRecommendedProductVariant) {
      await Promise.all(
        getAllSwitchLinePromises(filteredSubscriptions, currentRecommendedProductVariant)
      );
      setShowModalSwitchSubscription(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (dataGoals && dataRecommendedProducts) {
      const productCode = chooseProductToDisplay() as OFFER_CODES;
      setDisplayProductCode(productCode);
      loadProduct(productCode);
    }
  }, [dataGoals, dataRecommendedProducts]);

  useEffect(() => {
    if (!personalisedProduct || !currentRecommendedProduct) {
      return;
    }

    trackEvent('Shown Results Page', {
      product: currentRecommendedProduct.sku.includes('sn') ? '4PP' : 'EO',
      isSubscribed: currentRecommendedProduct.isSubscribed || false,
    });
  }, [personalisedProduct, currentRecommendedProduct]);

  const selectedGoals = useMemo(() => {
    const healthGoals = dataGoals?.health_goals;
    if (!healthGoals) {
      return [];
    }
    return healthGoals.filter(goal => goal.selected);
  }, [dataGoals]);

  useEffect(() => {
    if (dataProduct?.product_getProduct) {
      const product = {
        ...dataProduct.product_getProduct,
        pills: dataProduct.product_getProduct.pills?.length
          ? [...dataProduct.product_getProduct.pills]
          : [],
      };

      const ingredientsCount = product.pills.map(pill => pill.ingredients).flat().length;

      setIngredientsTotal(ingredientsCount);
      setPersonalisedProduct(product);
      window.scrollTo(0, 0);
    }
  }, [displayProductCode, dataProduct]);

  const error = errorProduct || errorGoals || errorResult;

  useGlobalError(!!error, ERROR_MESSAGES.generic);

  const has4PPDiscount = () => {
    if (isSubscribedToRecommendedProduct) return false;

    const discountCookie = getOffer('4pp') || '';
    return [
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH,
      PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF,
    ].includes(discountCookie);
  };

  const getDiscountInfo = () => {
    const discountCookie = getOffer('4pp') || '';
    if (PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF === discountCookie) {
      return DISCOUNT_50_INFO;
    }
    if (PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF === discountCookie) {
      return DISCOUNT_55_INFO;
    }
    if (PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH === discountCookie) {
      return DISCOUNT_30_3_MONTHS_INFO;
    }
    if (PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF === discountCookie) {
      return DISCOUNT_70_INFO;
    }
    return '';
  };

  const shouldShowFreeTrialLabel = () => {
    if (has4PPDiscount()) {
      return false;
    }
    const hasEOOffer = displayProductCode === OFFER_CODES.EssentialOne && getOffer('essential') !== null
    const hasPPOffer = displayProductCode === OFFER_CODES.PersonalisedPlus && getOffer('4pp') !== null
    return hasEOOffer || hasPPOffer;
  };

  const chooseProductToDisplay = () => {
    // 2 - user started consultation from product page
    const pageName = sessionStorage.getItem('consultationProduct');
    if (pageName) {
      return PAGE_URL_TO_PRODUCT_CODE[pageName].productCode;
    }

    if (dataRecommendedProducts) {
      const isPpPresent = dataRecommendedProducts.health_recommendedProducts?.find(
        recommendation => recommendation.subtype === 'personalised'
      );
      const isEoPresent = dataRecommendedProducts.health_recommendedProducts?.find(
        recommendation => recommendation.subtype === 'theone'
      );

      if (!isPpPresent && !isEoPresent) {
        setIsErrorModalVisible(true);
        return '';
      }

      if (params.get('showProduct')?.toLowerCase() === 'eo' || !isPpPresent) {
        toggleUpgradeSection(false);
        essentialPageVisitSetCookie();
        return OFFER_CODES.EssentialOne;
      }

      if (isPpPresent && !isEoPresent) {
        toggleUpgradeSection(false);
      }

      if (params.get('showProduct')?.toLowerCase() === '4pp' || !isEoPresent) {
        toggleUpgradeSection(false);
        fourPPPageVisitSetCookie();
        return OFFER_CODES.PersonalisedPlus;
      }
    }

    const trialData = checkFreeTrial('essentialOne', getCookie(COOKIES.essentialOnePartnership));
    // 1 - user has free trial
    if (trialData.isActive) {
      return trialData.productCode;
    }
    // 3 - user chose a specific issue in the consultation
    // TODO: Update when LANA is fixed to support correct supplement issues (@Makan for any questions)
    // if (checkHasSupplementIssue()) {
    //   return OFFER_CODES.EssentialOne
    // }
    // 4 - 4PP otherwise
    return OFFER_CODES.PersonalisedPlus;
  };

  const switchProduct = () => {
    const newDisplayProductCode =
      displayProductCode === OFFER_CODES.PersonalisedPlus
        ? OFFER_CODES.EssentialOne
        : OFFER_CODES.PersonalisedPlus;
    setDisplayProductCode(newDisplayProductCode);
    setLoadingProductSwitch(true);
    setTimeout(() => {
      setLoadingProductSwitch(false);
    }, 700);
    loadProduct(newDisplayProductCode);
  };

  const loadProduct = (displayProductCode: OFFER_CODES | '') => {
    if (!dataRecommendedProducts?.health_recommendedProducts) {
      return;
    }

    const productId = getProductId(
      dataRecommendedProducts?.health_recommendedProducts,
      displayProductCode
    );

    productId &&
      getProduct({
        variables: {
          slug: productId,
          id: '',
          freeTrial: freeTrial,
          partnership: partnership,
        },
      });
  };

  const getProductId = (
    recommendations: IRecommendedProduct[],
    displayProductCode: OFFER_CODES | ''
  ) => {
    let product: IRecommendedProduct | undefined;

    if (displayProductCode === OFFER_CODES.PersonalisedPlus) {
      product = recommendations.find(recommendation => recommendation.id.startsWith('sn'));
      setPartnership(getOffer('4pp'));

      if (partnership) {
        setFreeTrial(checkFreeTrial('personalisedPlus', partnership).isActive);
      }
    }

    // sometimes user can't get an actual PP product even when that's what they opted for
    if (displayProductCode === OFFER_CODES.EssentialOne || !product) {
      if (!product) setDisplayProductCode(OFFER_CODES.EssentialOne);
      product = recommendations.find(recommendation =>
        recommendation.id.startsWith('essential-one')
      );
      setPartnership(getOffer('essential'));

      if (partnership) {
        setFreeTrial(checkFreeTrial('essentialOne', partnership).isActive);
      }
    }

    return product?.id;
  };

  const handleNutrientsPopup = (pill: IProductPill | null) => {
    if (!pill) {
      return;
    }
    setModalNutrients(pill);
    setShowModalNutrients(true);
  };

  const firstName = user ? user?.firstName : 'You';

  useEffect(() => {
    trackEvent('result_4ppfullprice_view', {
      currency: getCookie(COOKIES.currency),
    });
    trackHeapEvent('result_4ppfullprice_view');
  }, []);

  if (dataRecommendedProducts && dataRecommendedProducts?.health_recommendedProducts?.length < 1) {
    return (
      <section className={styles.sectionNoResults}>
        <div className={styles.container}>
          <h2>{directusAccountConsultationResult.no_consultation_title}</h2>
          <p>{directusAccountConsultationResult.no_consultation_description}</p>
          <br />
          <Link to="/consultation" className={styles.button}>
            {directusAccountConsultationResult.no_consultation_button_label}
          </Link>
        </div>
      </section>
    );
  }

  if (
    loadingResult ||
    loadingProduct ||
    loadingProductSwitch ||
    loadingUserDetails ||
    loadingGoals ||
    !directusConsultationResult ||
    !directusAccount
  ) {
    return <Loader />;
  }

  if (!personalisedProduct) {
    return (
      <section className={styles.sectionHero}>
        <div className={styles.container}>
          <div className={styles.sectionHeader}>Recommendation</div>
          <div className={styles.row}>
            <p>
              Oops, we are sorry but we are unable to recommend a suitable personalised product for
              you based on some of your answers to the consultation.
              <br />
              Please contact <a href="mailto:hello@vitl.com">hello@vitl.com</a> and our in-house
              nutritionists can help you find the perfect product for you.
            </p>
          </div>
          <div className={styles.rowHero}>
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${
                displayProductCode === OFFER_CODES.PersonalisedPlus
                  ? directusConsultationResult.pp_main_image.filename_disk
                  : directusConsultationResult.eo_main_image.filename_disk
              }?w=790`}
              alt="Personalised product"
            />
          </div>
        </div>
      </section>
    );
  }

  const heroContent = (
    <div className={styles.contentCentered}>
      <ul className={styles.resultNew}>
        {selectedGoals.map((goal, index: number) => (
          <li key={index}>
            <Image
              src={`${goal.imageUrl}?&colorize=%23${goal.color.split('#')[1]}&truecolor=true`}
              width={40}
            />
            <p className={styles.textXsmall}>{goal.id}</p>
          </li>
        ))}
      </ul>
      <div className={styles.divider} />

      {shouldUpdatePack ? (
        <p className={styles.heroTextUpdate}>Make sure your pack is fully up to date</p>
      ) : (
        !isSubscribedToRecommendedProduct && (
          <p className={styles.heroText}>
            Get your{' '}
            {displayProductCode === OFFER_CODES.PersonalisedPlus ? '28-day pack' : '30-day bottle'}{' '}
            of vitamins delivered through your door every month.
          </p>
        )
      )}

      {!shouldUpdatePack && isSubscribedToRecommendedProduct && (
        <p>
          <b>Your pack is fully up to date!</b>
          <br />
          You're getting the best vitamin recommendations for you.
        </p>
      )}
      {shouldUpdatePack ? (
        <SubmitButton
          className={styles.buttonUpdatePack}
          buttonLabel={'Update pack'}
          loading={loadingSubscriptions}
          onClick={() => getSubscriptions()}
          error={errorSubscriptions}
        />
      ) : (
        personalisedProduct.plans &&
        personalisedProduct.plans.length > 0 &&
        !isSubscribedToRecommendedProduct && (
          <div className={styles.addToCartWrapper}>
            {has4PPDiscount() ? (
              <div
                className={styles.discountInfo}
                dangerouslySetInnerHTML={{ __html: getDiscountInfo() }}
              />
            ) : null}

            <AddToBasket
              isFreeTrial={shouldShowFreeTrialLabel()}
              dontShowDeliveryCost
              plans={personalisedProduct.plans}
              showPlanLabel={false}
              productType={displayProductCode}
              ctaText={has4PPDiscount() ? ADD_TO_BASKET_OFFER_LABEL : ''}
              basketCouponCode={basketData?.discountCode}
            />
          </div>
        )
      )}
      <div className={styles.trustPilotHeroWrapper}>
        <TrustPilot type={'MicroStar'} />
      </div>
    </div>
  );

  return (
    <>
      <section
        className={styles.sectionHero}
        id="resultHeroSectionA"
        data-vwo-test={partnership ? partnership : ''}
      >
        <div className={styles.container}>
          <div className={styles.sectionHeader}>Recommendation</div>
          <h2 className={styles.h2}>{personalisedProduct.label}</h2>
          <div className={styles.rowHero}>
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${
                displayProductCode === OFFER_CODES.PersonalisedPlus
                  ? directusConsultationResult.pp_main_image.filename_disk
                  : directusConsultationResult.eo_main_image.filename_disk
              }?w=790`}
              alt={personalisedProduct.label}
            />
          </div>
          {heroContent}
          {shouldUpdatePack && (
            <p className={styles.heroUpdateInfo}>
              Your personalised vitamin formulas are ever-evolving. Whether it’s a reformulation
              we’ve made following the latest scientific research, a change you made in your
              consultation, or an update in your Vitl DNA or Blood test results, update your pack to
              make sure that you’re getting the best vitamins and nutrients for you.
            </p>
          )}
        </div>
      </section>

      <section className={styles.sectionHeroABTest} id="resultHeroSectionB">
        <div className={styles.container}>
          <div className={styles.sectionHeader}>Recommendation</div>
          <h2 className={styles.h2}>{personalisedProduct.label}</h2>
          <div className={styles.rowHero}>
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${
                displayProductCode === OFFER_CODES.PersonalisedPlus
                  ? directusConsultationResult.pp_alt_image.filename_disk
                  : directusConsultationResult.eo_alt_image.filename_disk
              }?h=400`}
              alt={personalisedProduct.label}
            />
          </div>
          {heroContent}
        </div>
      </section>

      <section className={styles.sectionHeroABTest} id="resultHeroSectionC">
        <div className={styles.container}>
          <div className={styles.sectionHeader}>Recommendation</div>
          <h2 className={styles.h2}>{personalisedProduct.label}</h2>
          <div className={styles.rowHero}>
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${
                displayProductCode === OFFER_CODES.PersonalisedPlus
                  ? directusConsultationResult.pp_alt_image_2.filename_disk
                  : directusConsultationResult.eo_alt_image.filename_disk
              }?h=400`}
              alt={personalisedProduct.label}
            />
          </div>
          {heroContent}
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.sectionHeader}>Nutrients</div>

          {displayProductCode === OFFER_CODES.PersonalisedPlus && (
            <div className={styles.row}>
              <div className={styles.contentCentered}>
                <h2 className={styles.h2}>Selected for {firstName}</h2>
                <p>
                  Four unique pills containing <strong>{ingredientsTotal} nutrients</strong>,
                  sourced from the highest quality ingredients. Click on a pill to find out more.
                </p>

                <ul className={styles.pillsNew}>
                  {personalisedProduct.pills &&
                    personalisedProduct.pills.map(
                      (pill, index: number) =>
                        pill.ingredients &&
                        pill.ingredients.length > 0 && (
                          <li
                            key={index}
                            style={{
                              backgroundColor:
                                PILL_COLORS[pill.id]?.color || PILL_COLORS['vegan-omega-3']?.color,
                            }}
                          >
                            <img
                              width="90"
                              height="90"
                              src={`${pill.iconUrl}?compress=1&height=200`}
                              srcSet={`${pill.iconUrl}?compress=1&height=200 2x`}
                              alt={pill.label}
                            />
                            <p
                              className={styles.nutrientsLink}
                              onClick={() => handleNutrientsPopup(pill)}
                            >
                              {pill.ingredients.length}{' '}
                              {pill.ingredients.length === 1 ? 'nutrient' : 'nutrients'}
                              <span className={styles.arrow}> &gt;</span>
                            </p>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
          )}

          {displayProductCode === OFFER_CODES.EssentialOne && (
            <div className={styles.row}>
              <div className={styles.contentLeft}>
                <div className={styles.imageIngredients}>
                  <img
                    className="lazyload"
                    data-srcset={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_bottle_image.filename_disk}?width=590 2x`}
                    data-src={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_bottle_image.filename_disk}?width=590`}
                    alt="Essential One"
                  />
                </div>
              </div>
              <div className={styles.contentRightCentered}>
                <h2 className={styles.h2}>Selected for {firstName}</h2>
                <p className={styles.textSmall}>
                  One unique pill containing{' '}
                  <strong>
                    {ingredientsTotal}
                    &nbsp;nutrients
                  </strong>
                  , sourced from the highest quality ingredients.
                </p>

                <div className={styles.eoPillWrapper}>
                  <div className={styles.eoPill}>
                    {selectedGoals.map(goal => (
                      <div
                        className={styles.eoPillFragment}
                        key={goal.id}
                        style={{ backgroundColor: goal.color }}
                      ></div>
                    ))}
                    {selectedGoals.length > 0 ? (
                      <img
                        className={styles.eoPillImage}
                        width="78"
                        src={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_pill_cover.filename_disk}?w156`}
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.eoPillImage}
                        width="70"
                        src={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_pill_cover_full.filename_disk}?w140`}
                        alt=""
                      />
                    )}
                  </div>
                  <ul className={styles.eoPillGoals}>
                    {selectedGoals.map(goal => (
                      <li key={goal.id}>
                        <img
                          src={`${goal.imageUrl}?&colorize=%23${
                            goal.color.split('#')[1]
                          }&truecolor=true`}
                          alt={goal.name}
                        />
                        <p className={styles.textXsmall} style={{ color: goal.color }}>
                          <strong>{goal.id}</strong>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>

                <p
                  className={styles.nutrientsLink}
                  onClick={() =>
                    handleNutrientsPopup(
                      personalisedProduct.pills ? personalisedProduct.pills[0] : null
                    )
                  }
                >
                  {personalisedProduct.pills &&
                    personalisedProduct.pills[0] &&
                    personalisedProduct.pills[0].ingredients?.length}{' '}
                  nutrients
                  <span className={styles.arrow}> &gt;</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </section>

      {/*{showDnaUpsell && (*/}
      {/*  <DnaNutritionTestSection*/}
      {/*    loading={addItemToBasketLoading || loadingCoupon}*/}
      {/*    handleAddToBasket={addDNAPlus4PPToCart}*/}
      {/*  />*/}
      {/*)}*/}

      <section className={styles.goalsWrapper}>
        <div className={styles.goalsContainer}>
          <div className={styles.contentCentered}>
            <div className={styles.sectionHeader}>Health Goals</div>
            <h2 className={styles.h2}>{directusConsultationResult.section_1_title}</h2>
            <p className={styles.goalsText}>{directusConsultationResult.section_1_subtitle}</p>
          </div>
          <Goals type="result"></Goals>
        </div>
      </section>

      <PartnersSlider isWhite />

      <Reviews
        title={directusConsultationResult.reviews_title}
        items={directusConsultationResult.reviews_items}
        showReview
        hideTrustPilot={false}
        trustpilotIframe={<TrustPilot type={'MicroCombo'} />}
        hideImage={false}
      />

      <SectionBenefits content={directusBenefitsComponent} />

      {/* Alternative product */}
      {isUpgradeSectionVisible && (
        <section className={styles.section}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.contentLeft}>
                <img
                  className={styles.image}
                  src={`${process.env.GATSBY_MEDIA_URL}${productAlternative[displayProductCode].image}?w=590`}
                  alt=""
                />
              </div>
              <div className={styles.contentRight}>
                <h2 className={styles.h2}>{productAlternative[displayProductCode].title}</h2>
                <p>{productAlternative[displayProductCode].description}</p>
                <a className={styles.moreButton} onClick={() => switchProduct()}>
                  Learn more &gt;
                </a>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* FAQs */}
      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <h2 className={styles.h2}>{directusConsultationResult.faqs_title}</h2>
          <div className={styles.row}>
            <div className={styles.colFaqsSubtitle}>
              <div
                className={styles.dynamicContent}
                dangerouslySetInnerHTML={{
                  __html: directusConsultationResult.faqs_subtitle,
                }}
              ></div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.colFaqs}>
              <Accordion
                isHtmlContent={true}
                items={directusConsultationResult.faq_categories[2].questions}
              ></Accordion>
            </div>
          </div>
        </div>
      </section>

      {modalNutrients && (
        <ModalNutrients
          pillInfo={modalNutrients}
          showModalNutrients={showModalNutrients}
          setShowModalNutrients={setShowModalNutrients}
        />
      )}

      <CSSTransition
        in={isErrorModalVisible}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <Modal handleClose={() => setIsErrorModalVisible(false)}>
          <ModalHeader>
            <h4>{directusAccount.recommendation_unavailable_title}</h4>
          </ModalHeader>
          <ModalBody>
            <p>{directusAccount.recommendation_unavailable_text}</p>
          </ModalBody>
          <ModalFooter
            type=""
            closeLabel={directusAccount.recommendation_unavailable_close_button_label}
            saveLabel={''}
            handleClose={() => setIsErrorModalVisible(false)}
            handleSave={() => {}}
          />
        </Modal>
      </CSSTransition>

      <CSSTransition
        in={showModalSwitchSubscription}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <Modal handleClose={() => setShowModalSwitchSubscription(false)}>
          <ModalHeader>
            <h3>Update your vitamin pack</h3>
            <hr />
          </ModalHeader>
          <ModalBody>
            <p className={styles.updateModalText}>
              It’s important to update your pack every now and again to make sure your personalised
              vitamin formulas reflect any recent changes.
            </p>
            <p className={styles.updateModalTextNote}>
              {<>Please note: This update will apply to all of your {getPackName} subscriptions.</>}
            </p>
          </ModalBody>
          <ModalFooter
            handleClose={() => setShowModalSwitchSubscription(false)}
            handleSave={() => confirmUpdateSubscription()}
            saveLabel="Update"
            closeLabel="Cancel"
            loading={loadingSubscriptions || loadingSubscriptionSwitchLine}
          />
        </Modal>
      </CSSTransition>

      <ModalWarningScreen />
    </>
  );
};

export default AccountResult;
