import React from 'react';
import { Link } from 'gatsby';

import styles from './no-personalized-product.module.scss';

const NoPersonalizedProduct = () => {
  return (
    <section className={styles.sectionHero}>
      <div className={styles.container}>
        <div className={styles.sectionHeader}>No personalised product available right now</div>
        <div className={styles.row}>
          <p>
            Unfortunately, we are unable to recommend to you any personalised products right now.
            This may be due to your consultation, blood and/or DNA results, which prevents us from
            being able to give you a recommendation right now.
            <br />
            If you'd like to understand more, please contact{' '}
            <a href="mailto:hello@vitl.com">hello@vitl.com</a>
            <br />
            Or feel free to browse our <Link to="/shop">shop</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default NoPersonalizedProduct;
