export const healthHighlights = [
  {
    id: '1',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: null,
  },
  {
    id: '2',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        'In 2010 a study showed that eating 5+ daily contributed to a an increase in white blood cell count, suggesting a stronger immune system, in those participants',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
  {
    id: '3',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        'In 2010 a study showed that eating 5+ daily contributed to a an increase in white blood cell count, suggesting a stronger immune system, in those participants',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
  {
    id: '4',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        '4 In 2010 a study showed that eating 5+ vegetables a day has been shown to improve immunity',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
  {
    id: '5',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        'In 2010 a study showed that eating 5+ daily contributed to a an increase in white blood cell count, suggesting a stronger immune system, in those participants',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
  {
    id: '6',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        'In 2010 a study showed that eating 5+ daily contributed to a an increase in white blood cell count, suggesting a stronger immune system, in those participants',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
  {
    id: '7',
    label: 'Eating 5+ vegetables a day has been shown to improve immunity',
    study: {
      summary:
        'In 2010 a study showed that eating 5+ daily contributed to a an increase in white blood cell count, suggesting a stronger immune system, in those participants',
      title: 'Example scientific study title: styled nicely with serif font',
      studyCount: 1,
      participantCount: 269,
      reference:
        'Cirillo M, Fucci R, Rubini S, Coccia ME, Fatini C. 5-Methyltetrahydrofolate and Vitamin B12 Supplementation Is Associated with Clinical Pregnancy and Live Birth in Women Undergoing Assisted Reproductive Technology. International Journal of Environmental Research and Public Health. 2021; 18(23):12280.',
      url: '',
    },
  },
];
export const healthGoalsMock = [
  {
    id: 'joints',
    name: 'Healthy joints',
    color: '#535151',
    healthPillar: 'Joints',
    score: null,
    selected: true,
    description:
      'It seems that your joints could do with some extra care. Take a look below to see where to get started!',
    imageUrl: 'https://p-media-content.vitl.com/1670234733-joint.png',
    tagline: 'Maintain your flexibility',
    nutrientClaims: [],
    issues: [
      {
        id: 'frequent-joint-discomfort',
        name: 'you experience joint discomfort or stiffness',
        imageUrl: 'https://p-media-content.vitl.com/1670508591-icon-triangle.png',
      },
      {
        id: 'top-priority-joints',
        name: 'your top priority is joint health',
        imageUrl: 'https://p-media-content.vitl.com/1670508591-icon-triangle.png',
      },
    ],
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
  {
    id: 'detox',
    name: 'Detox',
    color: '#50AFD4',
    healthPillar: 'Detoxification',
    score: null,
    selected: true,
    description:
      "Luckily there's a lot we can do to help your detoxification pathways. Take a look below to see where to get started.",
    imageUrl: 'https://p-media-content.vitl.com/1531240649-detox.png',
    tagline: 'Support toxin elimination',
    nutrientClaims: [],
    issues: [
      {
        id: 'interest-in-detox',
        name: 'you are interested in detox',
        imageUrl: 'https://p-media-content.vitl.com/1670508591-icon-triangle.png',
      },
      {
        id: 'drink-insufficient-water',
        name: "you don't drink much water",
        imageUrl: 'https://p-media-content.vitl.com/1543512319-issues-drink-insufficient-water.png',
      },
      {
        id: 'frequent-fried-food-intake',
        name: 'you often eat fried foods',
        imageUrl: 'https://p-media-content.vitl.com/1670508591-icon-triangle.png',
      },
    ],
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'digestion',
    name: 'Better digestion',
    color: '#47BB8F',
    healthPillar: 'Digestion',
    score: null,
    selected: true,
    description:
      "Luckily there's a lot we can do to improve your digestion. Take a look below to see where to get started.",
    imageUrl: 'https://p-media-content.vitl.com/1531240731-digestion.png',
    tagline: 'Solve those tummy troubles',
    nutrientClaims: [],

    issues: [
      {
        id: 'bloating',
        name: 'you frequently feel bloated',
        imageUrl: 'https://p-media-content.vitl.com/1543512121-issues-bloating.png',
      },
      {
        id: 'diarrhoea',
        name: 'you suffer from diarrhoea',
        imageUrl: 'https://p-media-content.vitl.com/1543512289-issues-diarrhoea.png',
      },
      {
        id: 'heartburn-acid-reflux',
        name: 'you suffer from acid reflux',
        imageUrl: 'https://p-media-content.vitl.com/1543513061-issues-heartburn-acid-reflux.png',
      },
    ],
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
  {
    id: 'fertility',
    name: 'Enhance fertility',
    color: '#FF9FB5',
    healthPillar: 'Fertility',
    score: null,
    selected: false,
    description:
      'Here we’ll take a look at the best dietary and lifestyle choices to support your fertility in order to give you the greatest chance of conceiving.',
    imageUrl: 'https://p-media-content.vitl.com/1531240682-fetility.png',
    tagline: 'Support a healthy conception',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'sleep',
    name: 'Restful sleep',
    color: '#8F54A1',
    healthPillar: 'Sleep',
    score: null,
    selected: false,
    description:
      "Luckily there's a lot we can do to improve your sleep. Take a look below to see where to get started.",
    imageUrl: 'https://p-media-content.vitl.com/1531240702-sleep.png',
    tagline: 'Wake up feeling refreshed',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
  {
    id: 'fitness',
    name: 'Improve fitness',
    color: '#F87B64',
    healthPillar: 'Fitness',
    score: null,
    selected: false,
    description:
      'Getting the balance right with exercise is crucial for maintaining a consistent routine. Here we’ll take a look at some ideas for you to try.',
    imageUrl: 'https://p-media-content.vitl.com/1531240591-athlete-performance.png',
    tagline: 'Get into shape',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'stress',
    name: 'Reduce stress / anxiety',
    color: '#CC4963',
    healthPillar: 'Stress / Anxiety',
    score: null,
    selected: false,
    description:
      'Your stress & anxiety could do with a little attention, let’s take a look at what we can do to improve it.',
    imageUrl: 'https://p-media-content.vitl.com/1531240739-reduce-stress.png',
    tagline: 'Release the tension',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
  {
    id: 'bones',
    name: 'Strong bones',
    color: '#033D60',
    healthPillar: 'Bones',
    score: null,
    selected: false,
    description:
      'Maintaining healthy bones is one of the most important things you can do for long-term health. Let’s see how we can help with some expert tips!',
    imageUrl: 'https://p-media-content.vitl.com/1670234271-bone.png',
    tagline: 'Protect, repair and strengthen',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
  {
    id: 'immunity',
    name: 'Stronger immunity',
    color: '#6681B7',
    healthPillar: 'Immunity',
    score: null,
    selected: false,
    description:
      'Diet and lifestyle plays a role in this area. Check out the expert tips below to maintain a healthy immune system.',
    imageUrl: 'https://p-media-content.vitl.com/1531240711-immunity.png',
    tagline: "Boost your body's defences",
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'energy',
    name: 'Feel energised',
    color: '#FFD326',
    healthPillar: 'Energy',
    score: null,
    selected: false,
    description:
      'Energy levels can be affected by many different factors. Keeping it at an optimal level is important so check out the tips below to keep it that way.',
    imageUrl: 'https://p-media-content.vitl.com/1531240762-energy.png',
    tagline: 'Ignite your spark',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'skin',
    name: 'Better skin',
    color: '#D56D85',
    healthPillar: 'Skin',
    score: null,
    selected: false,
    description:
      'Diet and lifestyle can play a role in this area. Here’s some advice on what you can do to maintain healthy skin.',
    imageUrl: 'https://p-media-content.vitl.com/1531240722-skin.png',
    tagline: 'Managing an even complexion',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Roxane Bakker',
      imageUrl:
        'https://p-media-content.vitl.com/1653995491-1653987787-1607600599-dsc-3124-roxanne-square-white-1x.jpeg',
      title: 'Registered Dietitian',
    },
  },
  {
    id: 'hair',
    name: 'Healthy hair',
    color: '#654762',
    healthPillar: 'Hair',
    score: null,
    selected: false,
    description:
      'Diet and lifestyle can play a role in this area. Here’s some advice on what you can do to maintain a healthy head of hair.',
    imageUrl: 'https://p-media-content.vitl.com/1531240660-hair-loss.png',
    tagline: 'Revive, repair and protect',
    nutrientClaims: [],

    issues: null,
    expert: {
      name: 'Christina Mamada',
      imageUrl: 'https://p-media-content.vitl.com/1653995662-christina-square-9646.png',
      title: 'Nutrition Associate',
    },
  },
];
export const healthGoalNutrients = [
  {
    id: 1,
    name: 'Energy',
    imageUrl:
      'https://p-media-content.vitl.com/1531240762-energy.png?&colorize=%23FFD326&truecolor=true',
    nutrientsInPack: [
      'Vitamin B12',
      'Iron',
      'Magnesium',
      'Zinc',
      'Creatine',
      'Coenzyme Q10',
      'Ashwagandha',
    ],
    nutrients: [
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
    ],
    color: '#FFD326',
  },
  {
    id: 1,
    name: 'Energy',
    imageUrl:
      'https://p-media-content.vitl.com/1531240762-energy.png?&colorize=%23FFD326&truecolor=true',
    nutrientsInPack: [
      'Vitamin B12',
      'Iron',
      'Magnesium',
      'Zinc',
      'Creatine',
      'Coenzyme Q10',
      'Ashwagandha',
    ],
    nutrients: [
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
    ],
    color: '#FFD326',
  },
  {
    id: 1,
    name: 'Energy',
    imageUrl:
      'https://p-media-content.vitl.com/1531240762-energy.png?&colorize=%23FFD326&truecolor=true',
    nutrientsInPack: [
      'Vitamin B12',
      'Iron',
      'Magnesium',
      'Zinc',
      'Creatine',
      'Coenzyme Q10',
      'Ashwagandha',
    ],
    nutrients: [
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
      {
        name: 'Vitamin B12',
        amount: 10,
        amountUnit: 'ug',
        NRV: 400,
        claim: 'Vitamin B12 contributes to the reduction of tiredness and fatigue',
      },
    ],
    color: '#FFD326',
  },
];

export const FAQs = [
  {
    question: 'When should I take my vitamins?',
    answer:
      'We recommend that you take your Vitl vitamins on a full stomach, preferably with breakfast. This will help your body absorb the nutrients more effectively. Always having it with the same meal will make it easier to remember & incorporate into your daily habits.',
  },
  {
    question: 'Can I take my Vitl vitamins with other supplements?',
    answer:
      "Our supplements have been designed to be taken on their own. However, if you're currently taking or planning to take other supplements, drop us a message at info@vitl.com & our in-house nutritionists will help you!",
  },
  {
    question: 'What makes Vitl vitamins different?',
    answer:
      "Vitl only gives you what your body needs, literally. Through the Health Quiz, we're able to create a bespoke combination of nutrients that together work to support you in achieving your goals. We're committed to only using the highest quality & most sustainably-sourced ingredients for all of our products. Our supplements are completely free from artificial preservatives, artificial colours, & unnecessary fillers.",
  },
  {
    question: 'How should I store my vitamins?',
    answer:
      'Store your vitamins in a cool, dry place, away from direct sunlight & moisture. The packaging is designed to protect the nutrients. No need to refrigerate, just keep them in a convenient spot to help you establish a daily routine.',
  },
  {
    question: 'What happens if I forget to take my vitamins?',
    answer:
      "No worries! If you miss a day, simply resume the next. But don't take double the dose to make up for a missed day. Consistency is key, but occasional lapses won't impact the overall effectiveness. If you find yourself forgetting often, consider setting a daily reminder to help make it a habit.",
  },
  {
    question: 'Can I personalise my vitamins further?',
    answer:
      'Yes, at Vitl we also offer Nutrition DNA Tests & Vitamin Blood Tests. Both of these give you a full view of your health by revealing nutrient deficiencies, metabolic traits & genetic factors that influence your nutritional needs. This information will then serve as a foundation for tailoring your vitamin pack. ',
  },
  {
    question: 'Can I switch certain vitamins or mineral on my pack?',
    answer:
      "Our bespoke vitamin reccomendations are done by our experts, so you can't currently change them. But you can always adjust your health goals or retake your quiz to make sure you're getting the right nutrients!",
  },
  {
    question: 'How often should I retake my health quiz?',
    answer:
      'We recommend that you retake the Health Quiz every 3 months! This helps to ensure that your vitamin pack is always right for you, your evolving health goals & any potentially lifestyle change.',
  },
  {
    question: 'How can I pause or cancel my subscription?',
    answer:
      'You can pause or cancel your subscription at any time by logging into the "My Account" section using the email you signed up with. Once you’ve logged in, hover over your name and click "Orders". From there, you can either cancel or select how many weeks you\'d like to pause your subscription for. After you\'ve paused or cancelled your subscription, you\'ll receive a confirmation email.',
  },
];
export const faqsDescitption = `<p>If you don’t see your question then please get in touch <a href="/contact">here</a>.</p>`;
