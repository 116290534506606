import React from 'react';
import Image from '@components/image/image';
// utilities
import { getContentStyles } from '../utilities';
// styles
import styles from './content-image.module.scss';

interface IProps {
  content: any;
}

const setWrapperClassName = (content: any) => {
  const displayMode = content?.image?.display_mode;
  const itemSize = content?.style?.item_size;

  if (itemSize === 'x-small') return styles.xsmall;
  if (displayMode) return styles[displayMode];
  if (itemSize) return styles[itemSize.replace('-', '')];
};

const setImageSize = (content: any) => {
  const displayMode = content?.image?.display_mode;
  const item_size = content?.style?.item_size;

  if (displayMode === 'scaleAspectFill') return 800;
  switch (item_size) {
    case 'normal':
      return 400;
    case 'xsmall':
      return 200;
    case 'small':
      return 132;
    default:
      return 800;
  }
};

const ContentImage: React.FC<IProps> = ({ content }) => {
  return content.image.url ? (
    <div className={setWrapperClassName(content)} style={getContentStyles(content.style)}>
      <Image src={content.image.url} width={setImageSize(content)} alt={content.image.alt} />
    </div>
  ) : null;
};

export default ContentImage;
