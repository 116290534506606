import React, { useContext } from 'react';

import styles from './EoNutrients.module.scss';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
import { ResultPageContext } from '../../store/store';
import { IProductPill } from '@src/types/product';
import useAccountResult from '../../hooks/useAccountResult';

export const EoNutrients = () => {
  const { nutrientTotal, dataGoals, personalisedProduct, setModalNutrients, userName } = useContext(
    ResultPageContext
  );
  const { directusConsultationResult } = useAccountText();
  const { loading, selectedGoals } = useAccountResult();

  const handleNutrientsPopup = (pill: IProductPill | null) => {
    if (!pill) {
      return;
    }
    setModalNutrients(pill);
  };

  if (!selectedGoals) return null;

  const imageUrl = `${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_bottle_image.filename_disk}?width=590`;

  return (
    <div className={styles.nutrients}>
      <div className={styles.row}>
        <div className={styles.contentLeft}>
          <div className={styles.imageIngredients}>
            <img
              className="lazyload"
              data-srcset={imageUrl + ` 2x`}
              data-src={imageUrl}
              alt="Essential One"
            />
          </div>
        </div>
        <div className={styles.contentRightCentered}>
          <h2 className={styles.h2}>Selected for {userName}</h2>
          <p className={styles.textSmall}>
            One unique pill containing{' '}
            <strong>
              {nutrientTotal}
              &nbsp;nutrients
            </strong>
            , sourced from the highest quality ingredients.
          </p>

          <div className={styles.eoPillWrapper}>
            <div className={styles.eoPill}>
              {selectedGoals.map((goal: any) => (
                <div
                  className={styles.eoPillFragment}
                  key={goal.id}
                  style={{ backgroundColor: goal.color }}
                ></div>
              ))}
              {selectedGoals.length > 0 ? (
                <img
                  className={styles.eoPillImage}
                  width="78"
                  src={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_pill_cover.filename_disk}?w156`}
                  alt=""
                />
              ) : (
                <img
                  className={styles.eoPillImage}
                  width="70"
                  src={`${process.env.GATSBY_MEDIA_URL}${directusConsultationResult.eo_pill_cover_full.filename_disk}?w140`}
                  alt=""
                />
              )}
            </div>
            <ul className={styles.eoPillGoals}>
              {selectedGoals.map((goal: any) => (
                <li key={goal.id}>
                  <img
                    src={`${goal.imageUrl}?&colorize=%23${goal.color.split('#')[1]}&truecolor=true`}
                    alt={goal.name}
                  />
                  <p className={styles.textXsmall} style={{ color: goal.color }}>
                    <strong>{goal.id}</strong>
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <p
            className={styles.nutrientsLink}
            onClick={() =>
              handleNutrientsPopup(
                personalisedProduct && personalisedProduct.pills
                  ? personalisedProduct.pills[0]
                  : null
              )
            }
          >
            {personalisedProduct &&
              personalisedProduct.pills &&
              personalisedProduct.pills[0] &&
              personalisedProduct.pills[0].ingredients?.length}{' '}
            nutrients
            <span className={styles.arrow}> &gt;</span>
          </p>
        </div>
      </div>
    </div>
  );
};
