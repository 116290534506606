import React from 'react';
import styles from './SeeMore.module.scss';
import { Icon } from 'vitl-component-library';

interface numberOfItems {
  numberOfItems: number;
  onClick: () => void;
}

const SeeMore: React.FC<numberOfItems> = ({ numberOfItems, onClick }) => {
  return (
    <div className={styles.seeMore} onClick={onClick}>
      See {numberOfItems} more
      <Icon className={styles.icon} icon="arrow-down" size={10} />
    </div>
  );
};

export default SeeMore;
