import { USER_WARNING_SCREEN } from '@src/queries/user';
import { GlobalContext } from '@src/store/global-state';
import { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

const useGetWarning = () => {
  const { setShowWarningScreen, setWarningScreenContent } = useContext(GlobalContext);

  const [getWarningScreen, { loading }] = useLazyQuery(USER_WARNING_SCREEN, {
    onCompleted: data => {
      const jsonSections = data.user_warningScreen?.jsonSections;
      if (jsonSections) {
        setShowWarningScreen(true);
        setWarningScreenContent(JSON.parse(jsonSections));
      }
    },
  });

  return { getWarningScreen, loading };
};

export default useGetWarning;
