//detox is missing

export const reviewData = {
  generic: [
    {
      reviewerName: 'Marion',
      review:
        'WOW!!! I sleep amazing now. My IBS is at bay. I have new hair growth and my nails and skin are super healthy. The one thing that has stood out is my energy. When heading out for a walk or to my exercise class I feel like I can keep going. This is an investment in me. AMAZING GUYS THANK YOU!',
    },
    {
      reviewerName: 'Amanda',
      review:
        'These vitamins I take every day have made a huge impact on my life and I feel better every day. Thank you Vitl, I have recommended it highly to my mum who will also try this.',
    },
    {
      reviewerName: 'Michelle',
      review:
        'The vitamins have really improved my life and the customer service is really helpful. Happy customer!',
    },
    {
      reviewerName: 'Jesus',
      review:
        "I couldn't be happier with my experience with Vitl. I can feel a real difference since I started taking them, and the customer service was exceptional.",
    },
    {
      reviewerName: 'L. Dunleavy',
      review:
        'Absolutely in love with these vitamins. I am just going into my 3rd month of using them and I’ve noticed a huge difference in everything: my energy, sleep quality, concentration levels, everything I wanted from the vitamins in fact!',
    },
  ],
  energy: [
    {
      reviewerName: 'Victoria',
      review:
        'Fantastic & highly recommend. I was a little sceptical about all the online hype but so glad I made my first order. I’ve been struggling with feeling constantly exhausted and already am noticing a huge difference in my energy levels.',
    },
    {
      reviewerName: 'I. Nazarov',
      review:
        'Wonderful subscription. Improved my energy levels, especially during that week of the menstrual cycle. I’ve been a subscriber for 6 months now and I can’t imagine how I was dealing before!',
    },
    {
      reviewerName: 'Iris',
      review:
        'Really has helped with new found energy within. Definitely starting to see the benefits of taking these vitamins!',
    },
    {
      reviewerName: 'Wendy',
      review:
        'I’ve really felt the difference since I’ve been taking these. I’ve always taken tons of vitamins but never felt any effect. I have so much more energy. Well done!',
    },
  ],
  immunity: [
    {
      reviewerName: 'Beth',
      review:
        'Highly recommend. I have never felt better since taking the vitamins. I have felt much better and feel my immune system is stronger.',
    },
    {
      reviewerName: 'Jennifer',
      review:
        "Beforehand, after catching covid last summer, I'd be ill every other week. After starting Vitl, I noticed my health improved hugely. In fact, I haven't been ill since, despite being around my partner who was! So basically, I'm sold!",
    },
    {
      reviewerName: 'Anonymous',
      review:
        'I have a compromised immune system so expect to be ill pretty much all of winter. Since starting my customised vitamin pack from Vitl, I have not been sick once! I’ve also saved ££ vs buying loads of vitamins randomly each month.',
    },
  ],
  skin: [
    {
      reviewerName: 'Natalie',
      review:
        'Love these vitamins! Tailored for you specifically, clean ingredients, straight to your door and affordable. Would recommend. I’ve got so much energy now and my skin is looking fabulous!',
    },
    {
      reviewerName: 'Laura',
      review:
        'Amazing!!! I suffer from psoriasis and I’ve seen a huge improvement in my skin already - it’s actually amazing! I’ve had every cream under the sun and nothing has worked.',
    },
    {
      reviewerName: 'Alice',
      review:
        'Super skin improver - is this magic?! I was at my wit’s end struggling with recurrent, painful, giant spots. Since using Vitl, I haven’t had a breakout of megatron spots and far fewer little zits. People have even commented on how good my skin is looking - just magic!',
    },
  ],
  digestion: [
    {
      reviewerName: 'Jodie',
      review:
        'Bought personalised vitamins as I was struggling with IBS! 3 weeks in and had no symptoms. Huge improvement! Thank you, thank you, thank you.',
    },
    {
      reviewerName: 'Lena',
      review:
        'I’ve been taking my vitamins for over a month now and I definitely see the benefits already. My digestion is better and period pains are much less noticeable.',
    },
    {
      reviewerName: 'Glyn',
      review:
        'I was having trouble with my digestion, but I had been trying a few things without much success. I first tried Vitl for a month and my digestion improved hugely.',
    },
  ],
  stress: [
    {
      reviewerName: 'Julie',
      review:
        'I cannot believe how much better I feel. My sleep has improved, I don’t wake up feeling tired and I don’t ache all over. My stress and anxiety levels have also reduced to a minimum.',
    },
    {
      reviewerName: 'Jay',
      review:
        'All around I feel a bit more like myself and these vitamins have had a massive impact. I find myself hitting that snooze button less and less, having more energy throughout the day, and actually feeling a lot less stressed and anxious, which is a huuuuge factor.',
    },
    {
      reviewerName: 'Tracey',
      review:
        'Wow, what a difference. I feel more calm, less stressed, and sleep well, which in turn has boosted my energy. I’ll definitely keep taking these and would recommend to everyone.',
    },
  ],
  sleep: [
    {
      reviewerName: 'Rachael',
      review:
        'Even after day one, I started to notice improvements with my sleep, memory, and being able to focus at work.',
    },
    {
      reviewerName: 'Andrew',
      review:
        'Before I was taking Vitl vitamins, I was having trouble with acid reflux and my sleep wasn’t the best. I’ve been very happy since - no acid reflux and my sleep is the best it’s ever been!',
    },
    {
      reviewerName: 'Jan',
      review:
        'I feel better and happier in myself, and I’m definitely sleeping better. So far, so good.',
    },
  ],
  bones: [
    {
      reviewerName: 'Kat',
      review:
        'Great service. My heartburn has reduced. My joints aren’t as sore - I hike a lot and these are to help that. Highly recommend these to anyone. Well worth it.',
    },
  ],
  joints: [
    {
      reviewerName: 'Kat',
      review:
        'Great service. My heartburn has reduced. My joints aren’t as sore - I hike a lot and these are to help that. Highly recommend these to anyone. Well worth it.',
    },
  ],
  hair: [
    {
      reviewerName: 'Rebecca',
      review:
        'I love that it’s personal to me. I am sleeping better, my hair and nails are shiny and healthy, I feel more energised!',
    },
    {
      reviewerName: 'Rachel',
      review:
        'Amazing customer service. Delivered on time every month. Very happy with my vitamins - can definitely see a difference in my energy levels, nails and hair.',
    },
  ],
};
