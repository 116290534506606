import React from 'react';
import { Icon, Image, TrustPilot } from 'vitl-component-library';

import styles from './hero.module.scss';

const HeroContent = () => (
  <div className={styles.bottom}>
    <TrustPilot type="MicroStar" />
    <div className={styles.bottomIcons}>
      <p>
        <Image url="/images/result-page/science_backed.png" width={56} />
        <span>SCIENCE BACKED</span>
      </p>
      <p>
        <Image url="/images/result-page/quality_ingredients.png" width={56} />
        <span>QUALITY INGREDIENTS</span>
      </p>
      <p>
        <Image url="/images/result-page/maximum_absorption.png" width={56} />
        <span>MAXIMUM ABSORPTION</span>
      </p>
    </div>
  </div>
);

export default HeroContent;
