import React, { useMemo } from 'react';

import Reviews from '@src/components/reviews/reviews';
import { TrustPilot } from 'vitl-component-library';
import useAccountResult from '../../hooks/useAccountResult';
import { reviewData } from './mock';

interface IReview {
  reviewerName: string;
  review: string;
}

export const Review = () => {
  const { loading, selectedGoals } = useAccountResult();

  const getReviewsByGoal = () => {
    const reviewToShow: IReview[] = [];

    const getRandomReview = (time: number = 1, reviews: any[]) => {
      for (let i = 0; i < time; i++) {
        const randomReview = reviews[Math.floor(Math.random() * reviews.length)];
        reviewToShow.push(randomReview);
      }
    };

    if (selectedGoals) {
      selectedGoals.map((goal: any, index: number) => {
        const review = reviewData[goal.id];

        // If there is no review for the goal, show a generic one
        if (!review) {
          reviewToShow.push(reviewData.generic[index]);
          return;
        }
        // If there is only one goal and that goal has more than 3 reviews, show 3 reviews
        if (selectedGoals.length === 1 && review.length >= 3) {
          reviewToShow.push(review[0], review[1], review[2]);
          return;
        }

        // get one review for each goal, if there is one review get it but if there is more get random one
        if (review.length === 1) {
          reviewToShow.push(review[0]);
        } else {
          getRandomReview(1, review);
        }
      });

      // If there is only one review get 2 random generic reviews
      if (reviewToShow.length === 1) {
        getRandomReview(2, reviewData.generic);
      }
      // If there is only two review get 1 random generic reviews
      if (reviewToShow.length === 2) {
        getRandomReview(1, reviewData.generic);
      }
    }

    return reviewToShow;
  };

  const reviews = useMemo(() => getReviewsByGoal(), [selectedGoals]);

  if (loading) return null;

  return (
    <Reviews
      title="Our customers say it best"
      items={reviews}
      showReview
      hideTrustPilot={false}
      backgroundColor="#FEF6DC"
      hideImage
      trustpilotIframe={<TrustPilot type={'MicroCombo'} />}
    />
  );
};
