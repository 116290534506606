import { useContext } from 'react';
import { ResultPageContext } from '../store/store';
import { ACCOUNT_RESULT_OFFER_CODES } from '../constants/constants';

const useIs4ppOrEo = () => {
  const { displayProductCode } = useContext(ResultPageContext);

  const is4pp = displayProductCode === ACCOUNT_RESULT_OFFER_CODES.PersonalisedPlus;
  const isE0 = displayProductCode === ACCOUNT_RESULT_OFFER_CODES.EssentialOne;

  return { is4pp, isE0 };
};

export default useIs4ppOrEo;
