import React from 'react';
// styles
import styles from './account-referral.module.scss';
// queries

const AccountReferral: React.FC = () => {

  return (
    <div className={styles.wrapper}>
      <h1>Sorry, the referral program is currently unavailable.</h1>
    </div>
  );
};

export default AccountReferral;
