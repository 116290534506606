import React from 'react';
import ModalBody from '@components/modal/modal-body';
import styles from '@features/account/pages/result/components/QuizResults/QuizResults.module.scss';
import Modal from '@components/modal';
import { IStudy } from '@features/account/pages/result/components/HealthHighlights/HealthHighlights';
import { isStringValueValidNumber } from '@services/format';

interface IProps {
  infoModalItem: IStudy | null;
  setShowInfoModal: (showInfoModal: boolean) => void;
}

const ModalInfoHealthHighLight: React.FC<IProps> = ({ infoModalItem, setShowInfoModal }) => {
  const hasStudies = isStringValueValidNumber(infoModalItem?.studies);
  const hasParticipants = isStringValueValidNumber(infoModalItem?.participants);
  return (
    <Modal type="Results" handleClose={() => setShowInfoModal(false)}>
      <ModalBody type="Results">
        <p>{infoModalItem?.description}</p>
        <hr className={styles.hr} />
        {infoModalItem?.title && <h3 className={styles.articleTitle}>{infoModalItem.title}</h3>}
          <div className={styles.studiesInfo}>
            {hasStudies && (
            <p>Studies: {infoModalItem?.studies}</p>
            )}
            {hasParticipants && (
            <p>Participants: {infoModalItem?.participants}</p>
            )}
          </div>
        <p className={styles.referenceInfo}>{infoModalItem?.reference}</p>
      </ModalBody>
    </Modal>
  );
};

export default ModalInfoHealthHighLight;
