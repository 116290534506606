import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
// services
import { formatPrice, formatDate } from '@services/format';
// queries
import { ORDERS } from '@queries/orders';
// components
import AccountOrderItems from '../account-order-items';
import Loader from '../loader';
// styles
import styles from './account-orders.module.scss';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
// types
import { IUserOrdersQueryResponse } from '../../types/query-response';

const AccountOrders: React.FC = () => {
  const { directusAccountOrder } = useAccountText();
  // state
  const [activeOrderIndex, setActiveOrderIndex] = useState(-1);
  // queries
  const { loading, error, data } = useQuery<IUserOrdersQueryResponse>(ORDERS, {
    fetchPolicy: 'network-only',
  });

  if (loading || !directusAccountOrder) return <Loader />;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.h2}>{directusAccountOrder.title}</h1>
        <hr />
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: directusAccountOrder.text }}
        />
        <div className={styles.row}>
          <div className={styles.col}>
            {error && (
              <p>We are unable to display your orders at the moment. Please try again later.</p>
            )}
            {data?.user_orders.length === 0 && <p>{directusAccountOrder.no_orders_subtitle}</p>}
            <ul>
              {data?.user_orders.map((order, i: number) => (
                <li
                  key={order.id}
                  className={i === activeOrderIndex ? styles.orderActive : styles.order}
                >
                  <div
                    className={styles.orderHeader}
                    onClick={() => setActiveOrderIndex(activeOrderIndex !== i ? i : -1)}
                  >
                    <div className={styles.chevron}>
                      <img src={'/images/icon_chevron.svg'} alt="" />
                    </div>
                    <div className={styles.number}>
                      <p>
                        {directusAccountOrder.number_label}
                        <br />
                        <strong>#{order.orderNumber}</strong>
                      </p>
                    </div>
                    <div className={styles.date}>
                      <p>
                        {directusAccountOrder.date_label}
                        <br />
                        <strong>
                          <strong>{formatDate(order.date)}</strong>
                        </strong>
                      </p>
                    </div>
                    <div className={styles.total}>
                      <p>
                        {directusAccountOrder.total_label}
                        <br />
                        <strong>{formatPrice(order.total, order.currency)}</strong>
                      </p>
                    </div>
                  </div>
                  <div className={styles.orderBody}>
                    <AccountOrderItems items={order.items} orderCurrency={order.currency} />
                    {order.discountAmount < 0 && (
                      <div className={styles.row}>
                        <p className={styles.detailLabel}>{directusAccountOrder.discount_label}</p>
                        <p className={styles.detailAmount}>
                          {formatPrice(order.discountAmount, order.currency)}
                        </p>
                      </div>
                    )}
                    {order.shipping ? (
                      <div className={styles.rowDetail}>
                        <p className={styles.detailLabel}>{directusAccountOrder.shipping_label}</p>
                        <p className={styles.detailAmount}>
                          {formatPrice(order.shipping, order.currency)}
                        </p>
                      </div>
                    ) : null}
                    <div className={styles.rowDetailTotal}>
                      <p className={styles.detailLabel}>
                        <strong>{directusAccountOrder.total_label}</strong>
                      </p>
                      <p className={styles.detailAmount}>
                        <strong>{formatPrice(order.total, order.currency)}</strong>
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountOrders;
