import React from 'react';
import styles from './Steps.module.scss';

interface IProps {
  numberOfSteps: number;
  step: number;
  setStep: (step: number) => void;
}

const Steps: React.FC<IProps> = ({ numberOfSteps, step, setStep }) => {
  return (
    <div className={styles.stepsNavigation}>
      <ul className={styles.smallStepsList}>
        {[...Array(numberOfSteps)].map((n, i) => (
          <li
            key={i}
            className={i + 1 === step ? styles.activeSmallCircle : ''}
            onClick={() => setStep(i + 1)}
          ></li>
        ))}
      </ul>

      <div className={styles[`activeStepCircle${step}`]}>{step}</div>
    </div>
  );
};

export default Steps;
