interface IPillColorKeyValue {
  [key: string]: { color: string };
}

export const PILL_COLORS: IPillColorKeyValue = {
  'active-probiotic': { color: '#F7E3C5' },
  'energy-complex': { color: '#E1E7E8' },
  'co-enzyme-q10': { color: '#FFF8E3' },
  'cleanse-complex': { color: '#FBE8E7' },
  'immune-defense': { color: '#FBF0E7' },
  'krill-omega-3': { color: '#FEF1C4' },
  'tranquility-blend': { color: '#FCFAE4' },
  'magnesium-calcium-complex': { color: '#DEF0F7' },
  'complete-multivitamin': { color: '#FBE8E7' },
  supergreens: { color: '#EFECC7' },
  'fertility-multivitamin': { color: '#FBE8E7' },
  'pregnancy-multivitamin': { color: '#E7F6F4' },
  'vegan-omega-3': { color: '#FCFAE4' },
  'breastfeeding-multivitamin': { color: '#E7F6F4' },
  rejuvenate: { color: '#F2FAFF' },
  'tranquility-blend-v2': { color: '#E1E7E8' },
  'co-enzyme-q10-v2': { color: '#FFF8E3' },
  'daily-cleanse': { color: '#F2E8D4' },
  'oh-mega': { color: '#FCFAE4' },
  'wellness-warrior': { color: '#FDECBC' },
  'collagen-strength': { color: '#FBE8E7' },
  'mineral-trio-plus': { color: '#DEF0F7' },
  'supergreens-v2': { color: '#EFECC7' },
  'pregnancy-multivitamin-v2': { color: '#FBF0E7' },
  'breastfeeding-multivitamin-v2': { color: '#FBF0E7' },
  'fertility-multivitamin-v2': { color: '#FBF0E7' },
  'hot-stuff': { color: '#E7F6F4' },
  'power-b-plus': { color: '#FCF6ED' },
};
