import React, { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { GlobalContext } from '@store/global-state';
// components
import Loader from '../../loader';
import CalendarModal from '../calendar-modal/calendar-modal';
import AddressModal from '../address-modal/address-modal';
import CancelModal from '../cancel-modal/cancel-modal';
import ModalConfirm from '@components/modal-confirm/modal-confirm';
import ChangePaymentModal from '@components/account-subscriptions/change-payment-modal/change-payment-modal';
// service
import { formatDate, formatPrice } from '@services/format';
import { trackGAEvent } from '@services/tracking/ga';
import useGetProductPlansData from '@features/shop/hooks/useGetProductPlansData';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
import { useUnpauseSubscription } from '@hooks/useSubscription';
//utils
import { addDaysToTimestamp } from '@src/utils/date';
//queries
import { SUBSCRIPTION_UPDATE_PAYMENT_METHOD } from '@queries/payment';
// styles
import styles from './account-subscription.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
// constants
import { ERROR_MESSAGES } from '../../../constants';
import useClickedOutside from '@hooks/useClickedOutside';
import { Badge } from 'vitl-component-library';
import { calculateDiscount } from '@src/utils/discount';

interface IProps {
  subscription: any;
  refetchSubscriptions: () => void;
}

const AccountSubscription: React.FC<IProps> = ({ subscription, refetchSubscriptions }) => {
  // context
  const { directusAccountSubscription } = useAccountText();
  const { setMessageModal, setErrorModalMessage } = useContext(GlobalContext);

  // state
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [restartDate, setRestartDate] = useState(
    subscription.restartsAt
      ? addDaysToTimestamp(new Date(+subscription.restartsAt * 1000), 1)
      : null
  );
  const [successMessage, setSuccessMessage] = useState('');
  const isSubscriptionPaused = subscription.status === 'paused';
  const [showChangePaymentModal, setShowChangePaymentModal] = useState(false);
  const [showConfirmDefaultPayment, setShowConfirmDefaultPayment] = useState(false);
  const [lastFourDigits, setLastFourDigits] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [allowSetDefaultPayment, setAllowSetDefaultPayment] = useState(false);

  const handleCancelSubscriptionClick = () => {
    setDropdownIsOpen(false);
    setShowCancelModal(true);
    trackGAEvent('cancellation_start', {});
  };

  const unPauseSubscriptionSuccess = () => {
    setSuccessMessage(directusAccountSubscription.restart_success_label);
    setMessageModal({
      visible: true,
      title: 'Success',
      message: directusAccountSubscription.restart_success_label,
      buttonLabel: 'OK',
    });
    setShowCalendarModal(false);
    setDropdownIsOpen(false);
    refetchSubscriptions();
  };
  const unPauseSubscriptionError = () => {
    setErrorModalMessage(ERROR_MESSAGES.unpauseSubscription);
  };

  const { unpauseSubscription, loading: loadingUnpauseSubscription } = useUnpauseSubscription(
    unPauseSubscriptionSuccess,
    unPauseSubscriptionError
  );

  const [setAsDefaultPaymentMethod, { loading: loadingSettingDefaultPaymentMethod }] = useMutation(
    SUBSCRIPTION_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: () => {
        setShowConfirmDefaultPayment(true);
        if (allowSetDefaultPayment) {
          setShowConfirmDefaultPayment(false);
          setAllowSetDefaultPayment(false);
          setMessageModal({
            visible: true,
            iconSrc: '/images/icon-compare-check-circle.svg',
            title: `The card ending in ${lastFourDigits} will now be used as the default payment method for all of your subscriptions.`,
            message: '',
            buttonLabel: 'Ok',
          });
        }
      },
    }
  );

  const handleSetDefaultPayMethForAllSubs = () => {
    setAllowSetDefaultPayment(true);
    setAsDefaultPaymentMethod({
      variables: {
        paymentMethodId,
        subscriptionId: '',
      },
    });
  };

  const handleSelectPaymentMethodCallback = (
    paymentMethodId: string,
    subscriptionId: string,
    lastFourDigits: string
  ) => {
    setShowChangePaymentModal(false);

    setPaymentMethodId(paymentMethodId);
    setLastFourDigits(lastFourDigits);

    setAsDefaultPaymentMethod({
      variables: {
        paymentMethodId,
        subscriptionId,
      },
    });
  };

  const handleAddNewPaymentMethodCallBack = (
    paymentMethodId: string,
    subscriptionId: string,
    lastFourDigits: string
  ) => {
    setPaymentMethodId(paymentMethodId);
    setLastFourDigits(lastFourDigits);

    setShowChangePaymentModal(false);
    setShowConfirmDefaultPayment(true);
  };

  const loading = loadingSettingDefaultPaymentMethod || loadingUnpauseSubscription;

  const elementRefDropDown = useRef(null);
  const outsideElementClicked = () => dropdownIsOpen && setDropdownIsOpen(false);
  useClickedOutside(elementRefDropDown, outsideElementClicked);

  const { allPlans } = useGetProductPlansData();
  const subscriptionPlan = allPlans?.product_plans.find(plan => plan.sku === subscription.product.sku);

  const showDiscountBadge = () => {
    if (
      subscription.product.price &&
      subscriptionPlan?.rrpPrice &&
      subscriptionPlan?.rrpPrice > subscription.product.price
    ) {
      const discount = calculateDiscount(subscription.product.price, subscriptionPlan?.rrpPrice);
      return <Badge label={`${discount}% OFF`} />;
    }
  };

  if (!directusAccountSubscription) return null;
  return (
    <>
      {loading && <Loader />}

      <li key={subscription.id} className={styles.subscription}>
        <div className={styles.header}>
          <div className={styles.image}>
            <img src={subscription.product.imageUrl} alt={subscription.product.name} />
          </div>
          <div className={styles.details}>
            <h4>{subscription.product.name}</h4>
            <p className={styles.subscriptionDetail}>
              <span className={styles.frequency}>{subscription.frequency}</span>
              <span className={styles.statusDivider}>|</span>
              <strong
                className={
                  subscription.status === 'cancelled' ? styles.statusCancelled : styles.status
                }
              >
                {subscription.status}
              </strong>
              
              <span className={styles.discount}>{showDiscountBadge()}</span>
            </p>

            {subscription.quantity && subscription.quantity > 1 && (
              <p className={styles.subscriptionDetail}>
                <span className={styles.quantityLabel}>Quantity: </span>
                <strong className={styles.quantityNumber}>{subscription.quantity}</strong>
              </p>
            )}

            {subscription.status !== 'cancelled' && (
              <>
                <p>
                  {subscription.nextDeliveryDate &&
                    `${directusAccountSubscription?.latest_pack_label}: ${formatDate(
                      subscription.nextDeliveryDate
                    )}`}
                  <br></br>
                  {`${directusAccountSubscription?.invoice_label}: ${formatPrice(
                    subscription.product.price,
                    subscription.product.currency
                  )} on ${formatDate(
                    restartDate && restartDate > subscription.currentPeriodEnd
                      ? restartDate
                      : subscription.currentPeriodEnd
                  )}`}
                </p>

                <div className={styles.buttonsWrapper} ref={elementRefDropDown}>
                  <div className={dropdownIsOpen ? styles.dropdownOpen : styles.dropdown}>
                    <div
                      className={styles.dropdownToggle}
                      onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
                    >
                      {directusAccountSubscription.manage_button_label}
                      <img className={styles.chevron} src={'/images/icon_chevron.svg'} alt="" />
                    </div>

                    <ul className={styles.dropdownItems}>
                      <li>
                        <button
                          className={styles.dropdownButton}
                          type="button"
                          onClick={() => setShowChangePaymentModal(true)}
                        >
                          {directusAccountSubscription.change_payment_method_label}
                        </button>
                      </li>
                      {!!subscription.deliveryAddress && (
                        <li>
                          <button
                            className={styles.dropdownButton}
                            type="button"
                            onClick={() => {
                              setDropdownIsOpen(false);
                              setShowAddressModal(true);
                            }}
                          >
                            {directusAccountSubscription.update_button_label}
                          </button>
                        </li>
                      )}
                      {isSubscriptionPaused && (
                        <li>
                          <button
                            className={styles.dropdownButton}
                            type="button"
                            onClick={() =>
                              unpauseSubscription({
                                variables: {
                                  id: subscription.id,
                                },
                              })
                            }
                            disabled={loading}
                          >
                            {loading
                              ? 'Loading...'
                              : directusAccountSubscription.restart_now_button_label}
                          </button>
                        </li>
                      )}
                      <li>
                        <button
                          className={styles.dropdownButton}
                          type="button"
                          onClick={handleCancelSubscriptionClick}
                        >
                          {directusAccountSubscription.cancel_button_label}
                        </button>
                      </li>
                    </ul>
                  </div>

                  {!isSubscriptionPaused && (
                    <button
                      className={styles.buttonPrimary}
                      type="button"
                      onClick={() => setShowCalendarModal(true)}
                    >
                      {directusAccountSubscription.pause_button_label}
                    </button>
                  )}

                  {isSubscriptionPaused && (
                    <button
                      className={styles.buttonPrimary}
                      type="button"
                      onClick={() => setShowCalendarModal(true)}
                    >
                      {directusAccountSubscription.unpause_button_label}
                    </button>
                  )}
                </div>
              </>
            )}

            {successMessage && <p className={styles.isSuccess}>{successMessage}</p>}
          </div>
        </div>
        <CancelModal
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          subscription={subscription}
          refetchSubscriptions={refetchSubscriptions}
          setSuccessMessage={setSuccessMessage}
          setShowCalendarModal={setShowCalendarModal}
        />
        {showCalendarModal && (
          <CalendarModal
            refetchSubscriptions={refetchSubscriptions}
            setModal={setShowCalendarModal}
            subscription={subscription}
            unpauseSubscription={unpauseSubscription}
            setDropdownIsOpen={setDropdownIsOpen}
            setSuccessMessage={setSuccessMessage}
            restartDate={restartDate}
            setRestartDate={setRestartDate}
          />
        )}
        {showAddressModal && (
          <AddressModal
            setModal={setShowAddressModal}
            subscription={subscription}
            setSuccessMessage={setSuccessMessage}
          />
        )}
        {showChangePaymentModal && (
          <ChangePaymentModal
            setModal={setShowChangePaymentModal}
            subscription={subscription}
            selectPaymentMethodCallback={handleSelectPaymentMethodCallback}
            addNewPaymentMethodCallBack={handleAddNewPaymentMethodCallBack}
          />
        )}
        <ModalConfirm
          iconSrc="/images/icon-compare-check-circle.svg"
          show={showConfirmDefaultPayment}
          title={`The card ending in ${lastFourDigits} will now be used as the payment method for this subscription.`}
          description="Would you also like to set this card as the default payment method for all other subscriptions?"
          onConfirm={() => handleSetDefaultPayMethForAllSubs()}
          onDecline={() => setShowConfirmDefaultPayment(false)}
          primaryButtonLabel="Yes"
          secondaryButtonLabel="No"
          loading={loadingSettingDefaultPaymentMethod}
        />
      </li>
    </>
  );
};

export default AccountSubscription;
