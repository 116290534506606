import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useLocation } from '@reach/router';
import styles from './QuizResults.module.scss';
import HealthHighlights from '@features/account/pages/result/components/HealthHighlights/HealthHighlights';
import GoalSelection from '@features/account/pages/result/components/GoalSelection/GoalSelection';
import GoalNutrients from '@features/account/pages/result/components/GoalNutrients/GoalNutrients';
import Steps from '@features/account/pages/result/components/Steps/steps';
import { RESULT_STEP_URLS } from '@features/account/pages/result/constants/constants';
import { trackEvent } from '@src/services/tracking/tracking';
import useGetWarning from '../../hooks/useGetWarning';

const QuizResults = () => {
  const [step, setStep] = useState<number>(1);
  const { pathname } = useLocation();
  const { getWarningScreen } = useGetWarning();

  useEffect(() => {
    trackEvent(`view_result${step}`);
    getWarningScreen();
  }, []);

  const handleSetStep = (step: number) => {
    step === 1 && navigate(RESULT_STEP_URLS.step_1);
    step === 2 && navigate(RESULT_STEP_URLS.step_2);
    step === 3 && navigate(RESULT_STEP_URLS.step_3);
    setStep(step);
  };

  useEffect(() => {
    pathname === RESULT_STEP_URLS.step_1 && setStep(1);
    pathname === RESULT_STEP_URLS.step_2 && setStep(2);
    pathname === RESULT_STEP_URLS.step_3 && setStep(3);
  }, [pathname]);

  return (
    <div className={styles.quizResults}>
      <div className={styles.quizResultsHeader}>
        <h1>Results</h1>
        <Steps numberOfSteps={3} step={step} setStep={handleSetStep} />
      </div>
      {step === 1 && <HealthHighlights setStep={handleSetStep} />}
      {step === 2 && <GoalSelection setStep={handleSetStep} />}
      {step === 3 && <GoalNutrients />}
    </div>
  );
};

export default QuizResults;
