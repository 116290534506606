import React from 'react';
import Image from '@components/image/image';

// utilities
import { handleContentAction, getContentStyles } from '../utilities';
// styles
import styles from './content-item-list.module.scss';

interface IProps {
  content: any;
}

const ContentItemList: React.FC<IProps> = ({ content }) => {
  return (
    <ul
      className={content.sub_type ? styles[content.sub_type] : styles.default}
      style={getContentStyles(content.style)}
    >
      {content.list.map((item: any, index: number) => (
        <li
          key={index}
          className={`${item.action ? styles.hasAction : ''} ${item.image ? styles.hasImage : ''}`}
          onClick={() => item.action && handleContentAction(item.action)}
        >
          {item.image && <Image src={item.image.url} width={60} alt={item.image.alt} />}
          <div className={styles.description} style={{ color: item.style?.text_color || '' }}>
            {item.title && <p>{item.title}</p>}
            {item.subtitle && <p>{item.subtitle}</p>}
          </div>
          <p className={styles.info} style={{ color: item.style?.tint_color || '' }}>
            {item.info}
            <strong>{item.sub_info}</strong>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default ContentItemList;
