import { log } from 'console';
import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GOALS } from '@src/queries/user';
import { IHealthGoalsQueryResponse } from '@src/types/query-response';
import { ResultPageContext } from '../store/store';

export const useGetDataGoals = () => {
  const { setDataGoals, setLoadingGoals, setError, setUsername } = useContext(ResultPageContext);

  // _____ API CALLS _____
  const { data: dataGoals, loading: loadingGoals, error: errorGoals, refetch } = useQuery<
    IHealthGoalsQueryResponse
  >(GOALS, { fetchPolicy: 'network-only' });

  // _____ SIDE EFFECTS _____
  useEffect(() => {
    if (loadingGoals) {
      setLoadingGoals(true);
      return;
    }
    setLoadingGoals(false);
  }, [loadingGoals]);

  if (dataGoals) {
    setDataGoals(dataGoals.health_goals);
    setUsername(dataGoals?.user_details?.firstName);
  }
  if (errorGoals) {
    setError(errorGoals);
  }

  return refetch;
};
