import React, { useContext } from 'react';

import { ResultPageContext } from '../../store/store';

import styles from './PPRecomndation.module.scss';

export const Pills = () => {
  const { personalisedProductPills } = useContext(ResultPageContext);
  if (!personalisedProductPills) return null;

  return (
    <div className={styles.pills}>
      {personalisedProductPills.map((pill, index) => (
        <div key={index} className={`${styles['onePill' + index]} ${styles.hover}`}>
          <img src={`/images/pills/Pills-Upright/${pill.id}.png`} alt={pill.label} />
          <p className={styles.hover}>
            <span className={styles.line} /> <span>{pill.label}</span>
          </p>
        </div>
      ))}
    </div>
  );
};
