import React from 'react';
// services
import { formatPrice } from '../../services/format';
// styles
import styles from './account-order-items.module.scss';
// components
import AddToBasket from '@components/add-to-basket';
// types
import { IBasketItem } from '../../types/basket';

interface IProps {
  items: IBasketItem[];
  orderCurrency: string;
}

const AccountOrderItems: React.FC<IProps> = ({ items, orderCurrency }) => {
  return (
    <ul>
      {items.map(item => (
        <li key={item.id} className={styles.item}>
          <div className={styles.image}>
            <img src={item.image} alt={item.name} />
          </div>
          <p className={styles.name}>{item.name}</p>
          <p className={styles.quantity}>x{item.qty}</p>
          <p className={styles.price}>{formatPrice(item.cost, orderCurrency)}</p>
          {item.sku && (
            <div className={styles.reorder}>
              <AddToBasket isReorderButton={true} sku={item.sku.replace('-free-trial','-default')} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default AccountOrderItems;
