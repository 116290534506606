import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
// context
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '../../constants';
// components
import GoalPillsList from '../goal-pills-list';
import ComparePersonalisedProducts from '../compare-personalised-products';
import Loader from '../loader';
import { CSSTransition } from 'react-transition-group';
// queries
import { GOAL_DETAILS, GOALS } from '../../queries/user';
import { GET_PRODUCT } from '../../queries/product';
// interfaces
import {
  IHealthProduct,
  IPillHealthProduct,
  IPillGetProduct,
  IIngredient,
} from '../../interfaces/product';
import { IIssue, IDietAdvice, IFood, ILifestyleAdvice } from '../../interfaces/goal';
// styles
import styles from './account-goal.module.scss';
import Modal from '../modal';
import ModalBody from '../modal/modal-body';
import fadeTransition from '../../styles/fade.module.scss';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  goal: string;
}

const AccountGoal: React.FC<IProps> = ({ goal }) => {
  const { directusAccount } = useAccountText();
  const { setErrorModalMessage } = useContext(GlobalContext);

  const [foodInPopup, setFoodInPopup] = useState({} as IFood);

  const { loading: loadingGoalDetails, error: errorGoalDetails, data: dataGoalDetails } = useQuery(
    GOAL_DETAILS,
    {
      variables: { id: goal },
    }
  );

  const { loading: loadingGoals, error: errorGoals, data: dataGoals } = useQuery(GOALS);

  // get sku for personalised pack and essential one
  const essentialOneProductSku = dataGoalDetails?.health_recommendedProducts.find(
    (product: IHealthProduct) => product.sku.includes('essential-one')
  )?.sku;
  const personalisedPackProductSku = dataGoalDetails?.health_recommendedProducts.find(
    (product: IHealthProduct) => product.sku.includes('sn')
  )?.sku;

  // get products
  const [
    getEssentialOneProduct,
    { data: dataEssentialOne, loading: loadingEssentialOne },
  ] = useLazyQuery(GET_PRODUCT, {
    variables: {
      slug: essentialOneProductSku,
      id: '',
      freeTrial: false,
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getEssentialOneProduct);
    },
  });

  const [
    getPersonalisedPackProduct,
    { data: dataPersonalisedPack, loading: loadingPersonalisedPack },
  ] = useLazyQuery(GET_PRODUCT, {
    variables: {
      slug: personalisedPackProductSku,
      id: '',
      freeTrial: false,
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getPersonalisedPackProduct);
    },
  });

  // get pills
  const personalisedPackPills = dataPersonalisedPack?.product_getProduct.pills;
  const healtGoalPills = dataGoalDetails?.health_goalDetails.pills;
  const foods: IFood[] = dataGoalDetails?.health_goalDetails.foods;

  const commonPills =
    healtGoalPills &&
    personalisedPackPills?.filter((ppPill: IPillGetProduct) => {
      return healtGoalPills.find((pill: IPillHealthProduct) => ppPill.label === pill.name);
    });

  useEffect(() => {
    essentialOneProductSku && getEssentialOneProduct();
    personalisedPackProductSku && getPersonalisedPackProduct();
  }, [dataGoalDetails]);

  if (
    loadingGoalDetails ||
    loadingGoals ||
    loadingEssentialOne ||
    loadingPersonalisedPack ||
    !directusAccount
  )
    return <Loader />;

  if (errorGoalDetails || errorGoals) {
    navigate('/account/dashboard');
    return null;
  }

  // get goal details and issues
  const goalDetails = dataGoalDetails?.health_goalDetails;
  const healthGoalId = dataGoalDetails?.health_goalDetails.id;
  const healthGoalDetails = dataGoals?.health_goals.find((goal: any) => goal.id === healthGoalId);
  const goalExpert = healthGoalDetails?.expert;

  const getIssues = (issues: Array<IIssue>) => {
    const issuesWithName = issues?.filter((issue: IIssue) => issue.name);
    return issuesWithName?.length > 3 ? issuesWithName?.slice(0, 3) : issuesWithName;
  };

  const issues = getIssues(goalDetails.issues);

  // get data needed for products
  const essentialOneData = dataEssentialOne?.product_getProduct;
  const essentialOneInfo = {
    label: essentialOneData?.label,
    productId: essentialOneProductSku,
    imageUrl: essentialOneData?.pills[0].iconUrl,
    ingredients: essentialOneData?.pills[0].ingredients,
  };

  const personalisedPackData = dataPersonalisedPack?.product_getProduct;
  const personalisedPackInfo = {
    label: commonPills && commonPills[0].label,
    productId: personalisedPackProductSku,
    imageUrl: commonPills && commonPills[0].iconUrl,
    ingredients: commonPills && commonPills[0].ingredients,
    description: commonPills && commonPills[0].longDescription,
  };

  // get common ingredients
  const maxCommonIngredients = 6;
  let commonIngredients =
    personalisedPackInfo &&
    essentialOneInfo?.ingredients?.filter((ingredient: IIngredient) => {
      return personalisedPackInfo?.ingredients?.find(
        (personalisedIngredient: IIngredient) => personalisedIngredient.name === ingredient.name
      );
    });

  // get ingredients that are only in Personalised Pack
  let personalisedPackOnlyIngredients = personalisedPackInfo?.ingredients?.filter(
    (ingredient: IIngredient) => {
      return !essentialOneInfo?.ingredients?.find(
        (personalisedIngredient: IIngredient) => personalisedIngredient.name === ingredient.name
      );
    }
  );

  // choose which ingredients to display
  if (commonIngredients && personalisedPackOnlyIngredients) {
    if (commonIngredients.length > 6) {
      commonIngredients = commonIngredients.slice(0, 5);
    }

    if (commonIngredients.length < maxCommonIngredients) {
      personalisedPackOnlyIngredients = personalisedPackOnlyIngredients.slice(
        0,
        maxCommonIngredients - commonIngredients.length
      );
    }
  }

  const showFoodInPopup = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    const foundFood = foods.find(food => food.id === currentTarget.getAttribute('data-food-id'));
    if (foundFood) setFoodInPopup(foundFood);
  };

  const onModalClose = () => {
    setFoodInPopup({} as IFood);
  };

  const hasIssues = issues?.length > 0;

  return (
    <>
      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.colHero}>
              <div className={styles.goalHeader}>
                <img
                  className={styles.imgGoal}
                  src={`${goalDetails.imageUrl}?w=70&h=70`}
                  alt={goalDetails.name}
                />
                <div>
                  <h1>{goalDetails.name}</h1>
                  <p>{healthGoalDetails.tagline}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.colYouToldUs}>
              {hasIssues && <p className={styles.youToldUs}>You told us:</p>}
              <div className={styles.row}>
                {hasIssues && (
                  <div className={styles.colYouToldUsHalf}>
                    <ul className={styles.issues}>
                      {issues.map((issue: IIssue) => (
                        <li>
                          {issue.imageUrl ? (
                            <img src={issue.imageUrl} alt="" />
                          ) : (
                            <img src="/images/icon_triangle.png" alt="" />
                          )}

                          <p>{issue.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div
                  className={hasIssues ? styles.colYouToldUsHalf : styles.colYouToldUsHalfCentered}
                >
                  <p>
                    <span className={styles.quoteOpen}>“</span>
                    <strong> {healthGoalDetails.description}</strong>
                  </p>
                  {goalExpert && (
                    <div className={styles.specialist}>
                      <img src={goalExpert.imageUrl} alt={goalExpert.name} />
                      <div>
                        <p className={styles.specialistName}>{goalExpert.name}</p>
                        <p className={styles.specialistRole}>{goalExpert.title}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {(goalDetails.foods || goalDetails.dietAdvice) && (
        <section className={styles.sectionPrimaryLighter}>
          <div className={styles.container}>
            <h2>{directusAccount.goal_diet_title}</h2>
            <hr />
            <div className={styles.row}>
              {goalDetails.dietAdvice && (
                <div className={styles.colDietaryAdvice}>
                  <h3 className={styles.h4}>{directusAccount.goal_advice_title}</h3>
                  {goalDetails.dietAdvice.map((advice: IDietAdvice) => (
                    <p key={advice.id}>{advice.description}</p>
                  ))}
                </div>
              )}
              {goalDetails.foods && (
                <div className={styles.colFoods}>
                  <h3 className={styles.h4}>{directusAccount.goal_food_title}</h3>
                  <ul className={styles.foods}>
                    {goalDetails.foods.map((food: IFood) => (
                      <li
                        className={styles.foodsItem}
                        key={food.id}
                        data-food-id={food.id}
                        onClick={showFoodInPopup}
                      >
                        <img
                          className="lazyload"
                          data-src={`${food.imageUrl}?w=86`}
                          data-srcset={`
                            ${food.imageUrl}?w=86 1x,
                            ${food.imageUrl}?w=172 2x
                          `}
                          data-sizes="86px"
                          alt={food.name}
                        />
                        <p>{food.name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      {essentialOneInfo &&
        personalisedPackInfo &&
        commonIngredients &&
        personalisedPackOnlyIngredients && (
          <GoalPillsList
            essentialOneProduct={essentialOneInfo}
            personalisedPackProduct={personalisedPackInfo}
            commonIngredients={commonIngredients}
            personalisedPackIngredients={personalisedPackOnlyIngredients}
          />
        )}

      {goalDetails.lifestyleAdvice && (
        <section className={styles.sectionGrayLightest}>
          <h2>{directusAccount.goal_lifestyle_title}</h2>
          <hr />
          <ul className={styles.lifestyleAdvice}>
            {goalDetails.lifestyleAdvice.map((advice: ILifestyleAdvice) => (
              <li key={advice.id}>
                <img
                  width="60"
                  src={`${goalDetails.imageUrl}?&colorize=%23${
                    healthGoalDetails.color.split('#')[1]
                  }&truecolor=true`}
                  alt=""
                />
                <p>{advice.description}</p>
              </li>
            ))}
          </ul>
        </section>
      )}

      {personalisedPackData && essentialOneData && (
        <ComparePersonalisedProducts
          personalisedPackProduct={{
            productId: personalisedPackProductSku,
            product: {
              ...personalisedPackData,
            },
          }}
          essentialOneProduct={{
            productId: essentialOneProductSku,
            product: {
              ...essentialOneData,
            },
          }}
        />
      )}

      <CSSTransition
        in={foodInPopup.hasOwnProperty('name')}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <Modal handleClose={onModalClose}>
          <ModalBody>
            <h2>{foodInPopup.name}</h2>
            <hr className={styles.hrHeight} />
            <img
              className="lazyload"
              width={100}
              src={`${foodInPopup.imageUrl}`}
              alt={foodInPopup.name}
            />
            <p className={styles.popupDescription}>{foodInPopup.description}</p>
          </ModalBody>
        </Modal>
      </CSSTransition>
    </>
  );
};

export default AccountGoal;
