import React from 'react';
import styles from './section-benefits.module.scss';

interface IProps {
  content: any;
}

const SectionBenefits: React.FC<IProps> = ({ content }) => {
  return (
    <section className={styles.sectionPrimaryLighter}>
      <div className={styles.container}>
        <h2 className={styles.h2}>Why Vitl?</h2>
        <hr></hr>
        <div className={styles.row}>
          <div className={styles.benefitsWrapper}>
            <ul className={styles.benefits}>
              <li>
                <img alt="" height="100" width="100" src="/images/icon-boxed-large-no-nasties.svg" />
                <p>No nasties</p>
              </li>
              <li>
                <img alt="" height="100" width="100" src="/images/icon-boxed-large-highly-absorbable.svg" />
                <p>Maximum absorption</p>
              </li>
              <li>
                <img alt="" height="100" width="100" src="/images/icon-boxed-large-filler-free.svg" />
                <p>No fillers</p>
              </li>
              <li>
                <img alt="" height="100" width="100" src="/images/icon-boxed-large-personalised.svg" />
                <p>Personalised to you</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBenefits;
