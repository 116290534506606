import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
// context
import { GlobalContext } from '@store/global-state';
// components
import Modal from '../modal';
import ModalBody from '../modal/modal-body';
import { renderBlock } from '../account-content-screen';
import { getContentStyles } from '../content/utilities';
// styles
import styles from './modal-warning-screen.module.scss';
import fadeTransition from '../../styles/fade.module.scss';

const ModalWarningScreen: React.FC = () => {
  const { showWarningScreen, setShowWarningScreen, warningScreenContent } = useContext(GlobalContext);
  const sections = warningScreenContent;

  return (
    <CSSTransition in={showWarningScreen} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal
        handleClose={() => {
          setShowWarningScreen(false);
        }}
      >
        <ModalBody>
          <div className={styles.modalContainer}>
            {sections?.map((section: any, sectionIndex: number) => (
              <section key={sectionIndex} style={getContentStyles(section.style)}>
                <div className={styles.container}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      {section.blocks.map(
                        (block: any, blockIndex: number) =>
                          block.type !== 'buttons' && (
                            <div key={blockIndex} className={styles.block}>
                              {renderBlock(block)}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ))}
            <button className={styles.buttonPrimary} onClick={() => setShowWarningScreen(false)}>
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalWarningScreen;
