import React from 'react';
import styles from './custom-select.module.scss';

interface IProps {
  id: string;
  label: string;
  register: any;
  error?: any;
  errorMessage?: string;
  options: any;
}

const CustomSelect: React.FC<IProps> = ({ id, label, register, error, errorMessage, options }) => (
  <div className={styles.container}>
    <select name={id} id={id} ref={register} placeholder="Select country">
      {options.map((option: any, id: number) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
    <label htmlFor={id} className={styles.placeholderActive}>
      {label}
    </label>
    {error && <p className={styles.error}>{errorMessage}</p>}
  </div>
);

export default CustomSelect;
