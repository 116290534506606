import gql from 'graphql-tag';

export const QUERY_SUBSCRIPTION_CANCEL_FEEDBACK_OPTIONS = gql`
  query subscription_cancellationFeedbackOptions {
    subscription_cancellationFeedbackOptions {
      key
      value
    }
  }
`;

export const SUBSCRIPTION_SWITCH_LINE = gql`
  mutation subscription_switch_line($subscriptionId: ID!, $variant: String!) {
    subscription_switchLine(subscriptionId: $subscriptionId, variant: $variant) {
      id
      type
      product {
        id
        sku
        variant
        name
        description
        price
        currency
        imageUrl
      }
      quantity
      deliveryAddress {
        id
        firstName
        lastName
        street
        city
        region
        postcode
        countryId
      }
      status
      restartsAt
      frequency
      currentPeriodStart
      currentPeriodEnd
      nextDeliveryDate
    }
  }
`;
