import React, { useContext, useEffect, useState } from 'react';
import styles from '@features/account/pages/result/components/QuizResults/QuizResults.module.scss';
import { Button, Icon } from 'vitl-component-library';
import SeeMore from '@features/account/pages/result/components/SeeMore/SeeMore';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@src/styles/fade.module.scss';
import ModalInfoHealthHighLight from '@features/account/pages/result/components/modals/modalInfoHealthHighLight';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import Loader from '@components/loader';
import SkeletonResultsFlowStep from '@components/skeletons/SkeletonResultsFlowStep';

export interface IStudy {
  description: string;
  title: string;
  studies: number;
  participants: number;
  reference: string;
  link: string;
}

interface IHealthHighlight {
  id: string;
  label: string;
  study: IStudy | null;
}

interface IProps {
  setStep: (step: number) => void;
}

const HealthHighlights: React.FC<IProps> = ({ setStep }) => {
  const { dataHealthResults, loadingHealthResults } = useContext(ResultPageContext);

  const [healthHighlights, setHealthHighlights] = useState<IHealthHighlight[]>([]);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showAllItems, setShowAllItems] = useState<boolean>(false);
  const [infoModalItem, setInfoModalItem] = useState<IStudy | null>(null);
  const [isUnmounting, setIsUnmounting] = useState<boolean>(false);
  const handleInfoClick = (itemIndex: number) => {
    setShowInfoModal(true);
    setInfoModalItem(healthHighlights[itemIndex].study);
  };

  const handleGoToNextStep = () => {
    setIsUnmounting(true);
    setTimeout(() => {
      setStep(2);
    }, 250);
  };

  useEffect(() => {
    if (dataHealthResults?.positiveFeedback) {
      setHealthHighlights([...dataHealthResults.positiveFeedback.slice(0, 3)]);
    }
  }, [dataHealthResults]);

  useEffect(() => {
    if (showAllItems && dataHealthResults?.positiveFeedback) {
      setHealthHighlights([...dataHealthResults.positiveFeedback]);
    }
  }, [dataHealthResults, showAllItems]);

  return (
    <div className={isUnmounting ? styles.quizResultCardSlideOut : styles.quizResultCard}>
      {loadingHealthResults ? (
        <SkeletonResultsFlowStep />
      ) : (
        <>
          <div className={styles.quizResultCardText}>
            <h3>Your results</h3>
            <h5>
              We've analysed your answers & this is what our nutritionists say about the areas
              you're doing great in. Keep it up!
            </h5>
            <div className={showAllItems ? styles.listWrapperExpanded : styles.listWrapper}>
              <ul className={showAllItems ? styles.highLightsListExpanded : styles.highLightsList}>
                {healthHighlights?.length > 0 &&
                  healthHighlights.map((highlight, index) => (
                    <li key={highlight.id}>
                      <Icon className={styles.iconCheck} color="#22A5A5" icon="check" size={12} />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: highlight.label,
                        }}
                      ></p>
                      {highlight.study && (
                        <Icon
                          className={styles.iconInfo}
                          color="#1A8181"
                          icon="info"
                          size={20}
                          onClick={() => handleInfoClick(index)}
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            {!showAllItems ? (
              dataHealthResults?.positiveFeedback.length > 3 && (
                <SeeMore
                  onClick={() => setShowAllItems(true)}
                  numberOfItems={dataHealthResults?.positiveFeedback.length - 3}
                />
              )
            ) : (
              <div className={styles.scrollToSeeMore}>Scroll down to see more</div>
            )}
          </div>

          <Button
            title="View my health goals"
            primary={true}
            onClick={() => handleGoToNextStep()}
          />
        </>
      )}

      <CSSTransition
        in={showInfoModal}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <ModalInfoHealthHighLight
          infoModalItem={infoModalItem}
          setShowInfoModal={setShowInfoModal}
        />
      </CSSTransition>
    </div>
  );
};

export default HealthHighlights;
