import React from 'react';
import useAccountResult from '../../hooks/useAccountResult';
import { formatPrice } from '@src/services/format';
import { applyDiscount } from '@src/utils/discount';
import couponCodeService from '@src/utils/couponCodeService';

import styles from './hero.module.scss';
import useIs4ppOrEo from '../../hooks/useIs4ppOrEo';

const ShowPrice = () => {
  const { currentPricePlan, has4PPDiscount, shouldShowFreeTrialLabel } = useAccountResult();
  const { isE0 } = useIs4ppOrEo();
  const { getOffer, getDiscountNumber } = couponCodeService();

  if (!currentPricePlan) return null;

  const fullPrice = formatPrice(currentPricePlan.rrpPrice, currentPricePlan.currency);

  if (shouldShowFreeTrialLabel()) {
    return (
      <p>
        Then {fullPrice} every {isE0 ? 30 : 28} days
      </p>
    );
  }
  if (has4PPDiscount()) {
    const offer = getOffer('4pp');
    const currentDiscount = getDiscountNumber(offer);
    const discountedPrice = +applyDiscount(currentPricePlan.rrpPrice, currentDiscount).toFixed(2);
    const formatedDiscountedPrice = formatPrice(discountedPrice, currentPricePlan.currency);
    return (
      <p className={styles.price}>
        <s>{fullPrice}</s> <b>{formatedDiscountedPrice}</b>{' '}
        <span className={styles.textXsmall}>/mo</span>
      </p>
    );
  }
  return (
    <p>
      <b>{fullPrice}</b> <span className={styles.textXsmall}>/mo</span>
    </p>
  );
};

export default ShowPrice;
