import React, { useEffect, useState } from 'react';
import { IStripeError, StripeErrorCodes } from '../../interfaces/stripe-error';
import { useMutation } from '@apollo/react-hooks';
import { useForm, Controller } from 'react-hook-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
// queries
import { USER_ADD_PAYMENT_METHOD } from '@queries/user';
import { CREATE_PAYMENT_SETUP_INTENT, PAYMENT_METHODS } from '@queries/payment';
// services
import { formatGraphQLError } from '@services/format';
//components
import CustomInput from '@components/form/custom-input';
import SubmitButton from '@components/submit-button';
// styles
import styles from './add-payment-method.module.scss';
import CustomCheckbox from '@components/form/custom-checkbox';

interface IProps {
  handleAddPaymentOnComplete: (paymentMethodId: string, lastFourDigits: string) => void;
  hasMultiplePaymentMethods: boolean;
  isSubscriptionsView?: boolean;
}

const AddPaymentMethod: React.FC<IProps> = ({
  hasMultiplePaymentMethods,
  handleAddPaymentOnComplete,
  isSubscriptionsView = false,
}) => {
  const stripe = useStripe() as any;
  const elements = useElements() as any;

  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm();

  // context and state
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [createSetupIntent] = useMutation(CREATE_PAYMENT_SETUP_INTENT);

  const [addUserPaymentMethod] = useMutation(USER_ADD_PAYMENT_METHOD, {
    update: (cache, addPaymentMethodData) => {
      const dataPaymentMethods: any = cache.readQuery({
        query: PAYMENT_METHODS,
      });
      dataPaymentMethods.user_paymentMethods.push(addPaymentMethodData.data.user_addPaymentMethod);
      cache.writeQuery({ query: PAYMENT_METHODS, data: dataPaymentMethods });
    },
    onCompleted: data => {
      const { id: paymentMethodId, lastFourDigits } = data.user_addPaymentMethod;
      isSubscriptionsView && handleAddPaymentOnComplete(paymentMethodId, lastFourDigits);
    },
  });

  const forceSca = async (paymentMethodId: string, setDefaultPayment: boolean) => {
    const createSetupIntentResponse = await createSetupIntent({
      variables: {
        token: paymentMethodId,
        setAsDefault: setDefaultPayment,
      },
    });

    const { clientSecret } = createSetupIntentResponse.data.user_createPaymentSetupIntent;

    const { error: errorConfirmCardSetup, setupIntent } = await stripe.confirmCardSetup(clientSecret);
    if (errorConfirmCardSetup) throw errorConfirmCardSetup;
    if (setupIntent.status === 'succeeded') return;
  };

  // handle submit functions
  const handleCreatePaymentMethodSubmit = async (formData: any) => {
    const setAsDefaultPayment = !!formData.defaultPayment;
    setPaymentLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: formData.name,
      },
    });

    const { id } = paymentMethod || {};
    const stripeError: IStripeError = error;

    setPaymentLoading(false);
    if (stripeError) {
      setPaymentError(true);
      if (stripeError.code in StripeErrorCodes) {
        setErrorMessage(stripeError.message);
      }
      if (stripeError.decline_code === StripeErrorCodes.CardNotSupported) {
        setErrorMessage("Sorry we don't support this card, please try a different one");
      }
    } else {
      setPaymentError(false);
      setErrorMessage('');
      try {
        setPaymentLoading(true);
        await forceSca(id, setAsDefaultPayment);
        await addUserPaymentMethod({
          variables: {
            token: id,
            setAsDefault: setAsDefaultPayment || !hasMultiplePaymentMethods,
          },
        });
        setPaymentLoading(false);
        !isSubscriptionsView && handleAddPaymentOnComplete(id, '');
      } catch (e) {
        setErrorMessage(formatGraphQLError(e.message));
        setPaymentLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreatePaymentMethodSubmit)}>
      <fieldset disabled={paymentLoading} className={styles.fieldset}>
        <CustomInput
          id="name"
          register={register({ required: true })}
          label="Name on card"
          isDirty={dirtyFields.name}
          error={errors.name}
          errorMessage="Please enter the name this card is associated with"
        />
        <div className={styles.input}>
          <div className={styles.inputStripe}>
            <CardElement
              onChange={() => setErrorMessage('')}
              options={{
                style: {
                  base: {
                    lineHeight: '56px',
                    borderColor: '#919191',
                  },
                },
              }}
            />
          </div>
        </div>
        <CustomCheckbox label="Set as default" id="defaultPayment" name="defaultPayment" register={register} />
      </fieldset>
      {errorMessage !== '' && <p className={styles.error}>{errorMessage}</p>}
      <SubmitButton
        loading={paymentLoading}
        error={paymentError}
        buttonLabel={'Continue'}
        disabled={!stripe}
        fullWidth
      />
    </form>
  );
};

export default AddPaymentMethod;
