import { useQuery } from '@apollo/react-hooks';
import { HEALTH_RESULTS } from '@queries/user';
import { useContext, useEffect } from 'react';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import useIs4ppOrEo from './useIs4ppOrEo';

const useHealthResults = () => {
  const { setDataHealthResults, setLoadingHealthResults, setError } = useContext(ResultPageContext);
  const { isE0 } = useIs4ppOrEo();

  const {
    data: dataHealthResults,
    loading: loadingHealthResults,
    error: errorHealthResults,
  } = useQuery(HEALTH_RESULTS, {
    variables: {
      productType: isE0 ? 'theone' : 'personalised',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loadingHealthResults) {
      setLoadingHealthResults(true);
      return;
    }
    setLoadingHealthResults(false);
  }, [loadingHealthResults]);

  if (dataHealthResults) {
    setDataHealthResults(dataHealthResults.health_results);
  }
  if (errorHealthResults) {
    setError(errorHealthResults);
  }
};

export default useHealthResults;
