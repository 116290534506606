import React from 'react';
import { getContentStyles } from '../utilities';

// styles
import styles from './content-html.module.scss';

interface IProps {
  content: any;
}

const ContentHtml: React.FC<IProps> = ({ content }) => {
  return (
    <div
      className={styles.content}
      style={getContentStyles(content.style)}
      dangerouslySetInnerHTML={{
        __html: content.html ? content.html : content.text,
      }}
    ></div>
  );
};

export default ContentHtml;
