import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, Image } from 'vitl-component-library';
import { IHealthProfileGoal } from '@src/types/goal';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@src/styles/fade.module.scss';
import styles from './GoalSelection.module.scss';
import ModalManageGoals from '@components/modal-manage-goals';
import { useMutation } from '@apollo/react-hooks';
import { SELECT_GOALS } from '@queries/user';
import { ERROR_MESSAGES } from '@src/constants';
import { GlobalContext } from '@store/global-state';
import ModalInfoHealthGoal from '@features/account/pages/result/components/modals/modalInfoHealthGoal';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import { healthGoalsMock } from '@features/account/pages/result/utils/mockData';
import SkeletonResultsFlowStep from '@components/skeletons/SkeletonResultsFlowStep';
import { GOALS_EXTRA_INFO } from '@constants/goalsExtraInfo';

interface IProps {
  setStep: (step: number) => void;
}

const GoalSelection: React.FC<IProps> = ({ setStep }) => {
  const { setErrorModalMessage } = useContext(GlobalContext);

  const { dataGoals, loadingGoals, goalIsUpdated, setGoalIsUpdated } = useContext(
    ResultPageContext
  );

  const [selectGoals, { loading: loadingSelectGoals }] = useMutation(SELECT_GOALS, {
    onCompleted: res => {
      setShowManageGoalsModal(false);
      setGoalIsUpdated(true);
    },
    onError: error => {
      setErrorModalMessage(ERROR_MESSAGES.selectGoals);
    },
  });

  const [goals, setGoals] = useState<IHealthProfileGoal[]>([]);
  const [showManageGoalsModal, setShowManageGoalsModal] = useState<boolean>(false);
  const [showGoalInfoModal, setShowGoalInfoModal] = useState<boolean>(false);
  const [goalInfo, setGoalInfo] = useState<IHealthProfileGoal | null>(null);
  const [isUnmounting, setIsUnmounting] = useState<boolean>(false);
  const selectedGoals = goals?.filter(g => g.selected) || [];
  const recommendedGoals = goals?.filter(g => !g.selected) || [];
  const handleSelectGoals = (goals: IHealthProfileGoal[]) => {
    const ids = goals.map(goal => goal.id);
    selectGoals({ variables: { ids } });
  };

  const handleInfoClick = (itemIndex: number) => {
    setShowGoalInfoModal(true);
    if (dataGoals) {
      const goal = goals[itemIndex];
      const goalGenericDesc = GOALS_EXTRA_INFO[goal.id];
      setGoalInfo({ ...goal, genericDesc: goalGenericDesc?.issueLongDesc });
    }
  };

  const handleGoToNextStep = () => {
    setIsUnmounting(true);
    setTimeout(() => {
      setStep(3);
    }, 250);
  };

  useEffect(() => {
    if (dataGoals) {
      setGoals([...dataGoals]);
    }
  }, [dataGoals]);

  useEffect(() => {
    if (goalIsUpdated) {
      window.location.reload();
    }
  }, [goalIsUpdated]);

  const loading = loadingSelectGoals || loadingGoals;

  return (
    <div className={isUnmounting ? styles.quizResultCardSlideOut : styles.quizResultCard}>
      {loading ? (
        <SkeletonResultsFlowStep />
      ) : (
        <>
          <div className={styles.quizResultCardText}>
            <h3>Let's start with your goals</h3>
            <h5>
              Your journey to feeling extraordinary starts with <b>manageable </b>
              changes. Rome wasn't built in a day!
            </h5>

            <ul className={styles.goalsList}>
              {goals?.length > 0 &&
                goals.map(
                  (goal, index) =>
                    goal.selected && (
                      <li key={goal.name}>
                        <Image
                          url={`${goal.imageUrl}?&colorize=%23${
                            goal.color.split('#')[1]
                          }&truecolor=true`}
                          width={32}
                        />
                        <p>{goal.name}</p>
                        <Icon
                          className={styles.infoIcon}
                          onClick={() => handleInfoClick(index)}
                          color="#1A8181"
                          icon="info"
                          size={20}
                        />
                      </li>
                    )
                )}
            </ul>
            <div className={styles.changeGoals}>
              <p>Not quite right?</p>
              <div
                className={styles.changeGoalsButtonWrapper}
                onClick={() => setShowManageGoalsModal(true)}
              >
                <span>Change goals</span>
                <Icon icon="arrow-right" size={11} color="#707070" />
              </div>
            </div>
          </div>
          <Button title="Confirm goals" primary={true} onClick={() => handleGoToNextStep()} />
        </>
      )}
      <CSSTransition
        in={showGoalInfoModal}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <ModalInfoHealthGoal goalInfo={goalInfo} setShowInfoModal={setShowGoalInfoModal} />
      </CSSTransition>

      <CSSTransition
        in={showManageGoalsModal}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <ModalManageGoals
          initSelectedGoals={selectedGoals}
          initRecommendedGoals={recommendedGoals}
          handleSubmit={handleSelectGoals}
          loading={loadingSelectGoals}
          handleClose={() => setShowManageGoalsModal(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default GoalSelection;
