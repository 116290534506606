import * as React from 'react';
import { getContentStyles } from '../utilities';
import styles from './content-spacer.module.scss';

interface IContentSpacerProps {
  content: any;
}

const ContentSpacer: React.FunctionComponent<IContentSpacerProps> = ({ content }) => {
  return <div className={styles.spacer} style={getContentStyles(content.style)} />;
};

export default ContentSpacer;
