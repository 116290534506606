import React from 'react';
// utilities
import { getContentStyles, handleContentAction } from '../utilities';
// styles
import styles from './content-buttons.module.scss';

interface IProps {
  content: any;
}

const ContentButtons: React.FC<IProps> = ({ content }) => (
  <ul className={styles.wrapper}>
    {content.buttons.map(
      (
        button: {
          icon: string;
          title: string;
          style: string;
          action: { type: string; value: string };
        },
        index: number
      ) => {
        return (
          <li key={index}>
            <button
              type="button"
              className={styles.button}
              onClick={() => handleContentAction(button.action)}
              style={getContentStyles(button.style)}
            >
              {button.title}
            </button>
          </li>
        );
      }
    )}
  </ul>
);

export default ContentButtons;
