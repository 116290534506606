import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import ModalHeader from '@components/modal/modal-header';
import Skeleton from 'react-loading-skeleton';
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { ISubscription } from '@interfaces/subscriptions';
import { PAYMENT_METHODS } from '@queries/payment';
import styles from '@components/account-payment/account-payment.module.scss';
import { Button } from 'vitl-component-library';
import AddPaymentMethod from '@components/add-payment-method';
import PaymentCard from '@components/account-payment/payment-card';

interface IProps {
  setModal: (showAddressModal: boolean) => void;
  subscription: ISubscription;
  selectPaymentMethodCallback: (subscriptionId: string, paymentMethodId: string, cardLastFourDigits: string) => void;
  addNewPaymentMethodCallBack: (subscriptionId: string, paymentMethodId: string, cardLastFourDigits: string) => void;
}

const ChangePaymentModal = ({
  setModal,
  subscription,
  selectPaymentMethodCallback,
  addNewPaymentMethodCallBack,
}: IProps) => {
  const { setErrorModalMessage } = useContext(GlobalContext);

  const { loading: loadingPaymentMethods, data: dataPaymentMethods } = useQuery(PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getPaymentMethods);
    },
  });

  const [showAddCardView, setShowAddCardView] = useState(false);
  const handleAddPaymentCardClick = () => {
    setShowAddCardView(true);
  };

  const handleAddPaymentOnCompleteCallback = (
    paymentMethodId: string,
    subscriptionId: string,
    lastFourDigits: string
  ) => {
    setShowAddCardView(false);
    addNewPaymentMethodCallBack(paymentMethodId, subscriptionId, lastFourDigits);
  };

  const paymentMethodsView = () => {
    if (loadingPaymentMethods)
      return <Skeleton count={3} className={styles.skeletonCard} containerClassName={styles.skeletonPaymentMethods} />;
    return (
      <ul className={styles.paymentCards}>
        {dataPaymentMethods?.user_paymentMethods.map((card: any) => (
          <PaymentCard
            card={card}
            subscription={subscription}
            selectPaymentMethodCallback={selectPaymentMethodCallback}
          />
        ))}
      </ul>
    );
  };

  return (
    <Modal handleClose={() => setModal(false)} type="PaymentMethods">
      <ModalHeader type="PaymentMethods">
        <h2>
          {!showAddCardView ? 'Select existing payment card' : `Add payment card to\n${subscription.product.name}`}
        </h2>
      </ModalHeader>
      <ModalBody type="PaymentMethods">
        {showAddCardView ? (
          <>
            <AddPaymentMethod
              isSubscriptionsView={true}
              handleAddPaymentOnComplete={(paymentMethodId, lastFourDigits) =>
                handleAddPaymentOnCompleteCallback(paymentMethodId, subscription.id, lastFourDigits)
              }
              hasMultiplePaymentMethods={dataPaymentMethods?.user_paymentMethods.length > 1}
            />
            <Button title="Back" back={true} className={styles.buttonBack} onClick={() => setShowAddCardView(false)} />
          </>
        ) : (
          <>
            {paymentMethodsView()}
            <Button
              primary={true}
              title="Add a payment card"
              disabled={loadingPaymentMethods}
              onClick={handleAddPaymentCardClick}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChangePaymentModal;
