import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
// context
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '../../constants';
// components
import ModalManageGoals from '../modal-manage-goals';
import GoalPanel from '../goal-panel';
import Loader from '../loader';
// queries
import { GOALS, SELECT_GOALS } from '../../queries/user';
// styles
import styles from './goals.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
// interfaces
import { IHealthGoalsQueryResponse } from '../../types/query-response';
import { IHealthProfileGoal } from '../../types/goal';
import useAccountContext from '@src/features/account/hooks/useAccountContext';
import { ResultPageContext } from '@src/features/account/pages/result/store/store';

interface IProps {
  type: string;
  goals?: IHealthProfileGoal[];
}

const Goals: React.FC<IProps> = ({ type, goals }) => {
  const { directusAccountDashboard } = useStaticQuery(graphql`
    query AccountDasboardGoal {
      directusAccountDashboard {
        goal_button_label
        goal_manage_button_label
      }
    }
  `);

  const { setRecommendedProducts } = useAccountContext();
  const { setErrorModalMessage } = useContext(GlobalContext);
  const { setGoalIsUpdated } = useContext(ResultPageContext);

  const { data: dataGoals, loading: loadingGoals, error: errorGoals } = useQuery<
    IHealthGoalsQueryResponse
  >(GOALS, {
    fetchPolicy: 'network-only',
    skip: type === 'dashboard',
  });

  const [showManageModal, setShowManageModal] = useState(false);

  const renderDashboardGoal = (goal: IHealthProfileGoal) => {
    return (
      <div className={styles.goalDashboard}>
        <img
          src={`${goal.imageUrl}?&colorize=%23${goal.color.split('#')[1]}&truecolor=true&width=126`}
          alt={goal.name}
        />
        <p className={styles.h4}>
          <strong>{goal.name}</strong>
        </p>
        <p className={styles.textSmall}>{goal.tagline}</p>
        <Link className={styles.button} to={'/account/goal/' + goal.id}>
          {directusAccountDashboard.goal_button_label}
        </Link>
      </div>
    );
  };

  const [selectGoals, { loading: loadingSelectGoals }] = useMutation(SELECT_GOALS, {
    onCompleted: () => {
      setRecommendedProducts();
      setGoalIsUpdated(true);
      setShowManageModal(false);
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.selectGoals);
    },
  });

  const handleSelectGoals = (goals: IHealthProfileGoal[]) => {
    const ids = goals.map(goal => goal.id);
    selectGoals({ variables: { ids } });
  };

  if (loadingGoals) return <Loader />;
  if (!directusAccountDashboard || errorGoals) return null;

  const healthGoals = type === 'dashboard' ? goals : dataGoals?.health_goals || [];
  const selectedGoals = (healthGoals && healthGoals.filter(goal => goal.selected === true)) || [];
  const recommendedGoals =
    (healthGoals && healthGoals.filter(goal => goal.selected === false)) || [];

  return (
    <>
      {selectedGoals.length > 0 && (
        <ul className={styles.row}>
          {selectedGoals.map(goal => (
            <li key={goal.id} className={styles.panel}>
              {type === 'result' && <GoalPanel goal={goal} />}
              {type === 'dashboard' && renderDashboardGoal(goal)}
            </li>
          ))}
        </ul>
      )}

      {type === 'dashboard' && (
        <button
          className={styles.buttonLink}
          type="button"
          onClick={() => setShowManageModal(true)}
        >
          <img src="/images/icon_switch.png" width="20px" alt="" />
          {directusAccountDashboard.goal_manage_button_label}
        </button>
      )}

      <CSSTransition
        in={showManageModal}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <ModalManageGoals
          initSelectedGoals={selectedGoals}
          initRecommendedGoals={recommendedGoals}
          handleSubmit={handleSelectGoals}
          loading={loadingSelectGoals}
          handleClose={() => setShowManageModal(false)}
        />
      </CSSTransition>
    </>
  );
};

export default Goals;
