import React from 'react';
import { Image, SwiperComponent } from 'vitl-component-library';

import { IImage } from '@src/types/global';

export const ppImages = [
  {
    url: '/images/result-page/4PP-Hero-11.jpg',
    alt: 'Vitl personalised pack, blister and supplement capsules',
  },
  {
    url: '/images/result-page/4PP-Hero-12.jpg',
    alt: '7 day blister pack with hand tearing off Monday',
  },
  {
    url: '/images/result-page/4PP-Hero-13.jpg',
    alt: 'Close up of 4 supplement capsule',
  },
  {
    url: '/images/result-page/4PP-Hero-14.jpg',
    alt: 'A supplement capsule being held between two fingers',
  },
  {
    url: '/images/result-page/4PP-Hero-15.jpg',
    alt: 'The personalised pack being held open showing a 7 day blister pack',
  },
];

export const eoImages = [
  {
    url: '/images/result-page/TEO-Hero-11-LR.jpg',
    alt: 'The essential one bottle with 3 capsules beside it',
  },
  {
    url: '/images/result-page/TEO-Hero-12-LR.jpg',
    alt: 'The packaging of the essential one with bottle inside',
  },
  {
    url: '/images/result-page/TEO-Hero-13-LR.jpg',
    alt: 'A single essential one capsule being held between two fingers',
  },
];

import styles from './hero.module.scss';
import useIs4ppOrEo from '../../hooks/useIs4ppOrEo';

const HeroSlider = () => {
  
  const { isE0 } = useIs4ppOrEo();
  var images = isE0 ? eoImages : ppImages;

  return (
    <div className={styles.slides}>
      <div className={styles.slideWrap}>
        <SwiperComponent dotInside paginationType="bullets" spaceBetween={0} slidesPerView={1}>
          {images.map((image: IImage, index: number) => (
            <Image key={index} url={image.url} alt={image.alt} width={1400} />
          ))}
        </SwiperComponent>
      </div>
    </div>
  );
};

export default HeroSlider;
