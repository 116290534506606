import React, { useContext, useEffect, useState } from 'react';
import styles from './GoalSlideItem.module.scss';
import {
  IGoalNutrientClaim,
  IGoalNutrientInPack,
} from '@features/account/pages/result/components/GoalNutrients/GoalNutrients';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@src/styles/fade.module.scss';
import ModalHealthGoalNutrients from '../modals/modalGoalNutrients';
import { Icon, Image } from 'vitl-component-library';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import { IHealthGoal } from '@interfaces/goal';
import Loader from '@components/loader';

interface IProps {
  goalItem: IGoalNutrientInPack;
}

const GoalSlideItem: React.FC<IProps> = ({ goalItem }) => {
  const { dataGoals, loadingGoals } = useContext(ResultPageContext);

  const [showGoalNutrientsModal, setShowGoalNutrientsModal] = useState<boolean>(false);

  const nutrientsInPack = goalItem.nutrientClaims.map((c: IGoalNutrientClaim) => c.nutrient.name);

  const [goal, setGoal] = useState<IHealthGoal | null>(null);

  useEffect(() => {
    if (dataGoals) {
      setGoal(dataGoals?.find((g: IHealthGoal) => g.id === goalItem.goalId));
    }
  }, [dataGoals]);

  return (
    <div className={styles.goalSlideItem}>
      {loadingGoals ? (
        <Loader hasContainer={true} />
      ) : (
        <>
          <div
            className={styles.goalItemHeader}
            style={{ backgroundColor: goal?.color.concat('20') }}
          >
            <h4>
              For your <b>{goal?.id}</b>
            </h4>
            <Image
              url={`${goal?.imageUrl}?&colorize=%23${goal?.color.split('#')[1]}&truecolor=true`}
              width={32}
            />
          </div>
          <div className={styles.goalItemBody}>
            <div className={styles.goalItemText}>
              <p>We've included: </p>
              <div className={styles.nutrientsInPack}>{nutrientsInPack.slice(0, 5).join(', ')}</div>
            </div>
            <p className={styles.whyTheseNutrients} onClick={() => setShowGoalNutrientsModal(true)}>
              Why these nutrients?
              <sup>
                <Icon icon="external-link" size={12} />
              </sup>
            </p>
          </div>
        </>
      )}

      <CSSTransition
        in={showGoalNutrientsModal}
        timeout={300}
        classNames={{ ...fadeTransition }}
        unmountOnExit
      >
        <ModalHealthGoalNutrients
          goal={goal}
          goalNutrients={goalItem.nutrientClaims}
          handleClose={() => setShowGoalNutrientsModal(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default GoalSlideItem;
