import React from 'react';
import { IBloodResult, IBloodResultRange } from '../../../types/blood';
// utilities
import { handleContentAction, getContentStyles } from '../utilities';
// styles
import styles from './content-biomarker.module.scss';

interface IProps {
  content: any;
}

const ContentBiomarker: React.FC<IProps> = ({ content }) => {
  const getHighlightedRange = (result: IBloodResult, range: IBloodResultRange) => {
    const resultDescription = result.description.toLowerCase();
    const rangeLabel = range.label.toLowerCase();
    if (rangeLabel.includes(resultDescription) || rangeLabel === '') {
      return range;
    }
    return null;
  };

  const getRangeOffset = (result: IBloodResult, range: IBloodResultRange) => {
    return {
      left: (range.min / result.max) * 100 + '%',
      right: 100 - (range.max / result.max) * 100 + '%',
    };
  };

  const getMarkerOffset = (result: IBloodResult) => {
    return (result.value / result.max) * 100 + '%';
  };

  return (
    <ul className={styles.results} style={getContentStyles(content.style)}>
      {content?.results?.map((result: IBloodResult, index: number) => (
        <li
          key={result.title}
          className={result.action && styles.hasAction}
          onClick={() => handleContentAction(result.action)}
        >
          <div className={styles.heading}>
            <p className={styles.name}>{result.title}</p>
            <p className={styles.score} style={{ color: result.description_color }}>
              {result.description}
            </p>
          </div>
          <div className={styles.ranges}>
            {result?.ranges?.map((range: IBloodResultRange, indexRange: number) => (
              <div
                key={indexRange}
                className={styles.range}
                style={{
                  backgroundColor: getHighlightedRange(result, range)?.color || '#e2e2e2c',
                  left: getRangeOffset(result, range).left,
                  right: getRangeOffset(result, range).right,
                }}
              ></div>
            ))}
            <div className={styles.marker} style={{ left: getMarkerOffset(result) }}>
              <p className={styles.markerValue}>{result.value_text}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ContentBiomarker;
