import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';

import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import SubmitButton from '@components/submit-button';
import { useGetUserDetails } from '@hooks/useGetUserDetails';

import styles from '../../account-subscription/account-subscription.module.scss';
import fadeTransition from '../../../../styles/fade.module.scss';
import { ISubscription } from '../../../../types/subscription';
import { trackGAEvent } from '@services/tracking/ga';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  subscription: ISubscription;
  loading: boolean;
  onSubmit: (formData: any) => void;
}

const AdditionalFeedbackModal = ({
  showModal,
  closeModal,
  subscription,
  loading,
  onSubmit,
}: IProps) => {
  const { directusAccountSubscription } = useAccountText();
  const { register, handleSubmit } = useForm();
  const { user } = useGetUserDetails();

  useEffect(() => {
    trackGAEvent('cancellation_feedback', {});
  }, []);

  const firstName = user?.firstName;

  return (
    <CSSTransition in={showModal} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal handleClose={closeModal}>
        <ModalBody>
          <div>
            <h2>We’ll miss you</h2>
            <hr />
          </div>
          <div className={styles.subscriptionPreview}>
            <div className={styles.previewImage}>
              <img src={`${subscription.product.imageUrl}?width=100`} alt="" />
            </div>
            <p className={styles.previewDetails}>
              {subscription.product.name}
              <br />
              <strong>{subscription.frequency}</strong>
            </p>
          </div>
          <div className={styles.goodbyeText}>
            <h6>We're sorry to see you go, {firstName}</h6>
            <p>Is there anything we could have done to prevent you from cancelling?</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className={styles.cancelForm}>
            <textarea
              placeholder="What could we have done?"
              ref={register()}
              name="additionalFeedback"
              id="additionalFeedback"
            />

            <SubmitButton
              buttonLabel="Cancel my subscription"
              loading={loading}
              disabled={loading}
              fullWidth
            />
          </form>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default AdditionalFeedbackModal;
