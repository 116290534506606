import React from 'react';
// components
import AddToBasket from '../add-to-basket';
import Loader from '../loader';
// interfaces
import { IIngredient } from '../../interfaces/product';
// styles
import styles from './goal-pills-list.module.scss';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import { essentialOneProductFormat } from '@src/utils/essentialOneFormat';

interface IProps {
  essentialOneProduct: {
    label: string;
    productId: string;
    imageUrl: string;
    ingredients: string;
  };
  personalisedPackProduct: {
    label: string;
    productId: string;
    imageUrl: string;
    ingredients: string;
  };
  commonIngredients: Array<IIngredient>;
  personalisedPackIngredients: Array<IIngredient>;
}

const GoalPillsList: React.FC<IProps> = ({
  essentialOneProduct,
  personalisedPackProduct,
  commonIngredients,
  personalisedPackIngredients,
}) => {
  const {
    dataPlans: dataEssentialOnePlans,
    loading: loadingEssentialOne,
    isAvailable: isEssentialOneAvailable,
  } = useGetProductPlansData(
    'essential-one',
    essentialOneProductFormat(essentialOneProduct.productId)
  );

  const {
    dataPlans: dataPersonalisedPackPlans,
    loading: loadingPersonalisedPack,
    isAvailable: isPersonalisedAvailable,
  } = useGetProductPlansData('personalised', personalisedPackProduct.productId);

  const singleCommonIngredient = commonIngredients.length === 1;
  const noCommonIngredients = commonIngredients.length === 0;

  if (loadingEssentialOne || loadingPersonalisedPack) return <Loader />;

  return (
    <section className={styles.sectionPrimaryLightest}>
      <div className={styles.container}>
        <h2>Supplementing your diet</h2>
        <hr />
        <div className={styles.row}>
          <div className={styles.col}>
            {singleCommonIngredient && (
              <div>
                <h3>{commonIngredients[0].name}</h3>
                <p className={styles.singleCommonParagraph}>
                  We included {commonIngredients[0].name} in your recommendations:
                </p>
              </div>
            )}
            <div
              className={
                singleCommonIngredient || noCommonIngredients
                  ? styles.goalsPillsListOneCommon
                  : styles.goalsPillsList
              }
            >
              <div className={styles.goalsPillsListItem}>
                <div>
                  <div className={styles.heading}>
                    <h6>{personalisedPackProduct.label}</h6>
                    <p>Included in your personalised pack</p>
                  </div>
                  <img src={personalisedPackProduct.imageUrl} alt="" height="90" />
                </div>
                <div>
                  {noCommonIngredients && (
                    <p className={styles.description}>{personalisedPackProduct.description}</p>
                  )}
                  <div className={styles.buttonsDesktop}>
                    <a href="#personalisedPack" className={styles.buttonInverted}>
                      Learn more
                    </a>
                    {dataPersonalisedPackPlans && (
                      <AddToBasket
                        plans={dataPersonalisedPackPlans.product_plans}
                        showButtonOnly
                        isAvailable={isPersonalisedAvailable}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!singleCommonIngredient && !noCommonIngredients && (
                <div className={styles.goalsPillsListIngredients}>
                  {commonIngredients.map((ingredient: any) => (
                    <div className={styles.ingredientStatus}>
                      <div>
                        <img src="/images/icon-compare-check.svg" width="16" height="16" />
                      </div>
                      <p>{ingredient.name}</p>
                      <div>
                        <img src="/images/icon-compare-check.svg" width="16" height="16" />
                      </div>
                    </div>
                  ))}

                  {personalisedPackIngredients.map((ingredient: IIngredient) => (
                    <div className={styles.ingredientStatus}>
                      <div>
                        <img src="/images/icon-compare-check.svg" width="16" height="16" />
                      </div>
                      <p>{ingredient.name}</p>
                      <div></div>
                    </div>
                  ))}
                  <div className={styles.buttonsMobile}>
                    <div>
                      <a href="#personalisedPack" className={styles.buttonInverted}>
                        Learn more
                      </a>
                      {dataPersonalisedPackPlans && (
                        <AddToBasket
                          plans={dataPersonalisedPackPlans.product_plans}
                          showButtonOnly
                          isAvailable={isPersonalisedAvailable}
                        />
                      )}
                    </div>
                    <div>
                      <a href="#essentialOne" className={styles.buttonInverted}>
                        Learn more
                      </a>
                      {dataEssentialOnePlans && (
                        <AddToBasket
                          plans={dataEssentialOnePlans.product_plans}
                          showButtonOnly
                          isAvailable={isEssentialOneAvailable}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.goalsPillsListItem}>
                <div>
                  <div className={styles.heading}>
                    <h6>{essentialOneProduct.label}</h6>
                    <p>Take one daily</p>
                  </div>
                  <img src={essentialOneProduct.imageUrl} alt="" height="90" />
                </div>
                <div>
                  {noCommonIngredients && (
                    <p className={styles.description}>
                      Designed for you, based on your body’s needs and your personal health goals
                    </p>
                  )}
                  <div className={styles.buttonsDesktop}>
                    <a href="#essentialOne" className={styles.buttonInverted}>
                      Learn more
                    </a>
                    {dataEssentialOnePlans && (
                      <AddToBasket
                        plans={dataEssentialOnePlans.product_plans}
                        showButtonOnly
                        isAvailable={isEssentialOneAvailable}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoalPillsList;
