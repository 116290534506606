import React from 'react';
import Image from '@components/image/image';
import { getContentStyles } from '../utilities';
// styles
import styles from './content-header.module.scss';

interface IProps {
  content: any;
}

const ContentHeader: React.FC<IProps> = ({ content }) => {
  return (
    <h2 className={styles.h2} style={getContentStyles(content.style)}>
      {content.image && <Image src={content.image.url} width={30} alt={content.image.alt} />}
      {content.text}
    </h2>
  );
};

export default ContentHeader;
