import React, { useContext } from 'react';
import { Link } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
// queries
import { SEND_FEEDBACK } from '../../queries/user';
import styles from './account-feedback.module.scss';
// services
import { trackEvent } from '../../services/tracking/tracking';
//components
import SubmitButton from '../submit-button';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

const AccountFeedback: React.FC = () => {
  const { directusAccount } = useAccountText();

  // state
  const { register, handleSubmit, errors } = useForm();

  // queries
  const [
    sendFeedback,
    { data: dataFeedback, loading: loadingFeedback, error: errorFeedback },
  ] = useMutation(SEND_FEEDBACK, {
    onCompleted: () => {
      trackEvent('Feedback Sent');
    },
  });

  const onSubmit = (formData: any) => {
    sendFeedback({
      variables: {
        message: formData.message,
      },
    });
  };

  if (!directusAccount) return null;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            {!dataFeedback ? (
              <div>
                <h1 className={styles.h2}>{directusAccount.feedback_title}</h1>
                <hr />
                <p className={styles.p}>{directusAccount.feedback_description}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.textarea}>
                    <textarea
                      id="message"
                      name="message"
                      ref={register({ required: true })}
                      placeholder={directusAccount.feedback_placeholder}
                    />
                    {errors.message && <p className={styles.error}>Please enter your feedback</p>}
                  </div>
                  <SubmitButton
                    loading={loadingFeedback}
                    success={dataFeedback}
                    error={errorFeedback}
                    errorMessage="There was a problem submitting your feedback"
                    buttonLabel={directusAccount.feedback_button_label}
                    successMessage="Your feedback have been sent successfully!"
                  />
                </form>
              </div>
            ) : (
              <div className={styles.confirmation}>
                <h1 className={styles.h2}>{directusAccount.feedback_sent_title}</h1>
                <hr />
                <p className={styles.p}>{directusAccount.feedback_sent_description}</p>
                <Link className={styles.button} to={'/account/dashboard'}>
                  {directusAccount.feedback_sent_button_label}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountFeedback;
