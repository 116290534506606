import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { RECOMMENDED_PRODUCTS } from '@src/queries/user';
import { IHealthRecommendedProductsQueryResponse } from '@src/types/query-response';
import { ResultPageContext } from '../store/store';

export const useGetRecomendedProducts = () => {
  const { setDataRecommendedProducts, setLoadingRecommended, setError } = useContext(
    ResultPageContext
  );

  const {
    data: dataRecommendedProducts,
    loading: loadingResult,
    error: errorResult,
    refetch,
  } = useQuery<IHealthRecommendedProductsQueryResponse>(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  // _____ SIDE EFFECTS _____
  useEffect(() => {
    if (loadingResult) {
      setLoadingRecommended(true);
      return;
    }
    setLoadingRecommended(false);
  }, [loadingResult]);

  if (dataRecommendedProducts) {
    setDataRecommendedProducts(dataRecommendedProducts);
  }

  if (errorResult) {
    setError(errorResult);
  }

  return refetch;
};
