import React, { FC } from 'react';
import { Section, SwiperComponent } from 'vitl-component-library';
import { useMediaQuery } from 'react-responsive';

import { hexToRgba } from '@src/utils/color';
import BackedByScienceSliderItem from './backed-by-science-slide';

import image from '../../../static/images/new-product-template/science-image-V6.jpg';
import styles from './backed-by-science.module.scss';

interface IItem {
  title: string;
  description: string;
  studies: string;
  participants: string;
  link: string;
  reference?: string;
}

interface IBackedByScienceSectionProps {
  title: string;
  subtitle: string;
  backgroundColor: string;
  items: IItem[];
}

const BackedByScienceSection: FC<IBackedByScienceSectionProps> = ({
  title,
  subtitle,
  backgroundColor,
  items,
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  return (
    <div
      style={{
        background: `linear-gradient(${hexToRgba(backgroundColor, 0.5)}, ${hexToRgba(
          backgroundColor,
          0.5
        )}), url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Section
        className={styles.section}
        title={title}
        subTitle={subtitle}
        backgroundColor="rgba(0,0,0,0)"
      >
        <SwiperComponent
          className={styles.slider}
          paginationType="bullets"
          showArrows={false}
          pagination={false}
          allowTouchMove={!isDesktop}
          slideToClickedSlide={!isDesktop}
          breakpoints={{
            320: {
              slidesPerView: 1.15,
              slidesOffsetBefore: 8,
              slidesOffsetAfter: 8,
            },
            375: {
              slidesPerView: 1.35,
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15,
            },
            414: {
              slidesPerView: 1.45,
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15,
            },
            768: {
              slidesPerView: 2.4,
              slidesOffsetBefore: 30,
              slidesOffsetAfter: 30,
            },
            1024: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {items.map(item => (
            <BackedByScienceSliderItem
              key={item.title}
              title={item.title}
              description={item.description}
              studies={String(item.studies)}
              participants={String(item.participants)}
              link={item.link}
              reference={item.reference}
            />
          ))}
        </SwiperComponent>
      </Section>
    </div>
  );
};

export default BackedByScienceSection;
