import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { USER_STRIPE_BALANCE } from '@queries/user';

interface IProps {
  className?: string;
}

const AccountBalance = ({ className = '' }: IProps) => {
  const { data, loading, error } = useQuery(USER_STRIPE_BALANCE, {
    fetchPolicy: 'network-only',
  });
  const hasUserCredit = data && data.user_stripeBalance > 0;

  return loading || error || !hasUserCredit ? null : (
    <p className={className}>
      Your <b>£{data.user_stripeBalance}</b> credit will be taken from any subscriptions.
    </p>
  );
};

export default AccountBalance;
