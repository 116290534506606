import { useLocation } from '@reach/router';
import useAccountResult from '../pages/result/hooks/useAccountResult';
import { useGetDataGoals } from '../pages/result/hooks/useGetDataGoals';
import { useGetRecomendedProducts } from '../pages/result/hooks/useGetRecomendedProducts';
import useHealthResults from '../pages/result/hooks/useHealthResults';
import useUserConsultationStatus from '../pages/result/hooks/useUserStatus';
import useUserKitStatus from '../pages/result/hooks/useUserKitStatus';

export const useGetAccountData = () => {
  const location = useLocation();

  if (location.pathname.includes('/account/signin')) return;

  // Get Result page data from api
  useUserConsultationStatus();
  useUserKitStatus();
  useGetDataGoals();
  useGetRecomendedProducts();
  useAccountResult();
  useHealthResults();
};
