import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import fadeTransition from '../../../../../../styles/fade.module.scss';
import Modal from '@src/components/modal';
import ModalHeader from '@src/components/modal/modal-header';
import ModalBody from '@src/components/modal/modal-body';
import ModalFooter from '@src/components/modal/modal-footer';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
import { ResultPageContext } from '../../store/store';

const ResultPageErrorModal = () => {
  const { directusAccount } = useAccountText();
  const { isErrorModalVisible, setIsErrorModalVisible } = useContext(ResultPageContext);

  return (
    <CSSTransition
      in={isErrorModalVisible}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setIsErrorModalVisible(false)}>
        <ModalHeader>
          <h4>{directusAccount.recommendation_unavailable_title}</h4>
        </ModalHeader>
        <ModalBody>
          <p>{directusAccount.recommendation_unavailable_text}</p>
        </ModalBody>
        <ModalFooter
          type=""
          closeLabel={directusAccount.recommendation_unavailable_close_button_label}
          saveLabel={''}
          handleClose={() => setIsErrorModalVisible(false)}
          handleSave={() => {}}
        />
      </Modal>
    </CSSTransition>
  );
};

export default ResultPageErrorModal;
