import React, { useState, FC } from 'react';
import styles from './dropdown-button.module.scss';

interface IDropdownButtonProps {
  buttonText: string;
  onItemClick: (item: string) => void;
  items: string[];
  isAbsoluteDropdown?: boolean;
  dropdownClassName?: string;
}

const DropdownButton: FC<IDropdownButtonProps> = ({
  buttonText,
  onItemClick,
  items,
  isAbsoluteDropdown = true,
  dropdownClassName = '',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleDropdownClick = () => setIsExpanded(value => !value);

  const handleDropdownItemClick = (item: string) => {
    setIsExpanded(false);
    onItemClick(item);
  };

  return (
    <div className={`${styles.dropdown} ${isExpanded ? styles.isExpanded : ''} ${dropdownClassName}`}>
      <div className={styles.dropdownToggle} onClick={handleDropdownClick}>
        {buttonText}
        <img className={styles.chevronIcon} src={'/images/icon_chevron.svg'} alt="" />
      </div>

      <ul className={`${styles.dropdownItems} ${isAbsoluteDropdown ? styles.absolute : ''}`}>
        {items.map(item => (
          <li key={item}>
            <button className={styles.dropdownItemButton} type="button" onClick={() => handleDropdownItemClick(item)}>
              {item}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownButton;
