import { useContext } from 'react';

import { ContentAccountContext } from '@features/account';

const useAccountContext = () => {
  const context = useContext(ContentAccountContext);
  return context;
};

export default useAccountContext;
