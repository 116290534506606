import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import ModalHeader from '@components/modal/modal-header';
import { GlobalContext } from '@store/global-state';
import Loader from '@components/loader';
import AddressManual from '@components/address-manual';
import { STORE_COUNTRIES } from '@queries/delivery';
import { UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS } from '@queries/orders';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { IAddress } from '@interfaces/address';
import { ISubscription } from '@interfaces/subscriptions';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  setModal: (showAddressModal: boolean) => void;
  subscription: ISubscription;
  setSuccessMessage: (message: string) => void;
}

const AddressModal = ({ setModal, subscription, setSuccessMessage }: IProps) => {
  const { directusAccountSubscription, directusCheckout } = useAccountText();
  const { setMessageModal, setErrorModalMessage } = useContext(GlobalContext);
  const { data: countriesData } = useQuery(STORE_COUNTRIES);
  const countries = countriesData?.store_countries;

  const currentDeliveryAddress = subscription.deliveryAddress;

  const { firstName, lastName, street, postcode, city, countryId, phone } = currentDeliveryAddress;

  const addressData = {
    firstName,
    lastName,
    street,
    postcode,
    city,
    countryId,
    phone,
  };

  const [updateSubscriptionAddress, { loading: updateSubscriptionAddressLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS,
    {
      onCompleted() {
        setModal(false);
        setSuccessMessage(directusAccountSubscription.update_success_label);

        setMessageModal({
          visible: true,
          title: 'Success',
          message: directusAccountSubscription.update_success_label,
          buttonLabel: 'OK',
        });
      },
      onError: () => {
        setErrorModalMessage(ERROR_MESSAGES.updateSubscriptionAddress);
      },
    }
  );

  // handle submit functions
  const handleUpdateAddress = (formData: IAddress) => {
    updateSubscriptionAddress({
      variables: {
        ...formData,
        subscriptionId: subscription.id,
      },
    });
  };

  return (
    <Modal handleClose={() => setModal(false)}>
      <ModalHeader>
        <h3>{directusAccountSubscription.update_delivery_title}</h3>
        <hr />
      </ModalHeader>
      <ModalBody>
        {updateSubscriptionAddressLoading && <Loader />}
        <AddressManual
          content={directusCheckout}
          countries={countries}
          loading={updateSubscriptionAddressLoading}
          submitLabel={directusAccountSubscription.update_delivery_button_label}
          submit={handleUpdateAddress}
          addressData={addressData}
        />
      </ModalBody>
    </Modal>
  );
};

export default AddressModal;
