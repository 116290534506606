import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
// context
import { GlobalContext } from '@store/global-state';
// queries
import {
  USER_DETAILS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_EMAIL,
  USER_MARKETING_CONSENT,
} from '../../queries/user';
// components
import CustomInput from '../form/custom-input';
import SubmitButton from '../submit-button';
import Loader from '../loader';
import PhoneNumberInput from '../form/phone-number-input';
// services
import { isEmpty } from '../../services/global';
import { ERROR_MESSAGES } from '../../constants';
import { isValidPhoneNumber } from 'react-phone-number-input';
import AccountPreferences from './account-preferences';
// hooks
import { useGetUserDetails } from '@hooks/useGetUserDetails';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
// styles
import styles from './account-details.module.scss';

const AccountDetails: React.FC = () => {
  const { directusAccount } = useAccountText();
  const { setErrorModalMessage } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);

  // queries
  const { user, loading: loadingUser } = useGetUserDetails();

  const { loading: marketingConsentLoading } = useQuery(USER_MARKETING_CONSENT);

  const [
    updateUserDetails,
    { data: updateResponse, loading: loadingUpdateUser, error: errorUpdateUser },
  ] = useMutation(UPDATE_USER_DETAILS, {
    update: (cache, updateResponse) => {
      const data = { ...updateResponse.data.user_updateDetails };
      cache.writeQuery({ query: USER_DETAILS, data: { user_details: data } });
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.updateUserDetails);
    },
  });

  const [updateUserEmail, { data: updateEmailResponse, loading: updateEmailLoading }] = useMutation(
    UPDATE_USER_EMAIL,
    {
      update: (cache, updateEmailResponse) => {
        const data = { ...updateEmailResponse.data.user_updateEmail };
        cache.writeQuery({ query: USER_DETAILS, data: { user_details: data } });
      },
      onError: () => {
        setErrorModalMessage(ERROR_MESSAGES.updateUserEmail);
      },
    }
  );

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { dirtyFields },
    watch,
    reset,
  } = useForm();

  // const [dateOfBirth, setDateOfBirth] = useState();

  const onSubmit = (formData: { firstName: string; lastName: string; phone: string }) => {
    isEmpty(errors) &&
      updateUserDetails({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          // dob: Math.round(new Date(dateOfBirth).getTime()/1000).toString()
        },
      });
  };

  const onEmailSubmit = (formData: { email: string }) => {
    isEmpty(errors) &&
      updateUserEmail({
        variables: {
          email: formData.email,
        },
      });
  };

  useEffect(() => {
    if (user) {
      if (user.phone && !isValidPhoneNumber(user.phone)) {
        user.phone = '';
      }
      reset(user);
    }
  }, [user, reset]);

  if (loadingUser || marketingConsentLoading || !directusAccount) return <Loader />;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.h2}>{directusAccount.details_title}</h1>
        <hr />
        <div className={styles.row}>
          <div className={styles.content}>
            <div className={styles.formWrapper}>
              <h4>Update your details</h4>
              <hr className={styles.hr} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={loading}>
                  <div className={styles.halfWidthInputContainer}>
                    <div className={styles.halfWidthInput}>
                      <CustomInput
                        id="firstName"
                        register={register({ required: true })}
                        label={directusAccount.details_first_name_label}
                        isDirty={dirtyFields.firstName || watch('firstName')}
                        error={errors.firstName}
                        errorMessage="Please enter your first name"
                      />
                    </div>
                    <div className={styles.halfWidthInput}>
                      <CustomInput
                        id="lastName"
                        register={register()}
                        label={directusAccount.details_last_name_label}
                        isDirty={dirtyFields.lastName || watch('lastName')}
                        error={errors.lastName}
                        errorMessage="Please enter your last name"
                      />
                    </div>
                  </div>

                  <div className={styles.halfWidthInputContainer}>
                    <div className={styles.halfWidthInput}>
                      <PhoneNumberInput
                        id="phone"
                        autoComplete="off"
                        control={control}
                        countryCallingCodeEditable={false}
                        error={errors.phone}
                        required={false}
                      />
                    </div>
                    {/* <div className={styles.halfWidthInput}>
                      <DatePicker
                        selected={dateOfBirth}
                        onChange={date => setDateOfBirth(date)}
                        openToDate={new Date("1996/01/01")}
                        showYearDropdown
                        showMonthDropdown
                        placeholderText="Date of birth"
                      />
                    </div> */}
                  </div>
                </fieldset>

                <SubmitButton
                  className={styles.btnSubmitDetails}
                  loading={loadingUpdateUser}
                  success={updateResponse}
                  error={errorUpdateUser}
                  buttonLabel={directusAccount.details_submit_button_label}
                  successMessage="Your details have been updated successfully!"
                />
              </form>
            </div>
            <div className={styles.formWrapper}>
              <h4>Update your email address</h4>
              <hr className={styles.hr} />
              <form onSubmit={handleSubmit(onEmailSubmit)}>
                <fieldset disabled={updateEmailLoading}>
                  <div className={styles.halfWidthInputContainer}>
                    <div className={styles.halfWidthInput}>
                      <CustomInput
                        id="email"
                        type="email"
                        register={register()}
                        label={directusAccount.details_email_label}
                        isDirty={dirtyFields.email || watch('email')}
                        error={errors.email}
                        errorMessage="Please enter your email address"
                      />
                    </div>
                  </div>
                </fieldset>

                <SubmitButton
                  loading={updateEmailLoading}
                  success={updateEmailResponse}
                  buttonLabel={directusAccount.details_submit_button_label}
                  successMessage="Your email have been updated successfully!"
                />
              </form>
            </div>
            <AccountPreferences updateEmailLoading={updateEmailLoading} setLoading={setLoading} />
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </section>
  );
};

export default AccountDetails;
