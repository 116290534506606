import React, { useContext, useEffect, useState } from 'react';
import styles from '@components/account-consultation-result/account-consultation-result.module.scss';
import Image from '@components/image/image';
import { useAccountText } from '@hooks/directusHooks/useAccountText';
import { useGetAccountData } from '@features/account/hooks/useGetAccountData';
import { ResultPageContext } from '@features/account/pages/result/store/store';

interface IProps {
  children?: React.ReactNode;
  setAnimationCompleted?: (value: boolean) => void;
}

const PillLoader = ({ children, setAnimationCompleted }: IProps) => {
  const { directusAccountConsultationResult } = useAccountText();

  return (
    <section className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.pillLoader}>
          <div className={styles.progress} />
          <div
            className={styles.pill}
            onAnimationEnd={() => {
              setAnimationCompleted && setAnimationCompleted(true);
            }}
          >
            <Image
              filename={directusAccountConsultationResult.product_image.filename_disk}
              width={33}
            />
          </div>
        </div>
        {children}
      </div>
    </section>
  );
};

export default PillLoader;
