import React, { useContext } from 'react';
import { Image } from 'vitl-component-library';

import useAccountResult from '../../hooks/useAccountResult';
import NoPersonalizedProduct from '../NoPersonalizedProduct/no-personalized-product';
import ShowPrice from './show-price';
import { ResultPageContext } from '../../store/store';
import { CartIcons } from './CartIcons';
import Add4PPToBasket from '../Add4PPToBasket/add4ppToBasket';
import useIs4ppOrEo from '../../hooks/useIs4ppOrEo';
import { DiscountBadge } from './discountBadge';
import useIsProductUpToDate from '../../hooks/useIsProductUpToDate';

import styles from './hero.module.scss';

const HeroMain = () => {
  const { loading, selectedGoals, shouldShowFreeTrialLabel } = useAccountResult();
  const { personalisedProduct, nutrientTotal } = useContext(ResultPageContext);
  const { isE0 } = useIs4ppOrEo();
  const isProductUpToDate = useIsProductUpToDate();

  const getHowManyDays = () => {
    if (shouldShowFreeTrialLabel()) return 'Free';
    return isE0 ? '30-day plan' : '28-day plan';
  };

  return (
    <div className={styles.mainSection}>
      <h5 className={styles.header}>{personalisedProduct?.label}</h5>
      <p>
        {isE0 ? '1' : '4'} daily capsules formulated with {nutrientTotal} nutrients to support your
        goals:
      </p>
      <div className={styles.icons}>
        {selectedGoals.map((goal: any, index: number) => {
          return (
            <li key={index} className={styles.goalIcon}>
              <Image
                url={`${goal.imageUrl}?&colorize=%23${goal.color.split('#')[1]}&truecolor=true`}
                width={32}
              />
              <p className={styles.textXsmall}>{goal.healthPillar}</p>
            </li>
          );
        })}
      </div>
      <div className={styles.addToCartWrapper}>
        {isProductUpToDate ? null : (
          <div className={styles.priceWrap}>
            <DiscountBadge />
            <p>
              <b>{getHowManyDays()}</b>
            </p>
            <ShowPrice />
          </div>
        )}
        <Add4PPToBasket />
        {isProductUpToDate ? null : <CartIcons />}
      </div>
    </div>
  );
};

export default HeroMain;
