import React, { useState, useMemo } from 'react';
// components
import AddToBasket from '../add-to-basket';
import ModalNutrients from '../modal-nutrients';
import Loader from '../loader';
// interfaces
import { IPillGetProduct } from '../../interfaces/product';
// styles
import styles from './compare-personalised-products.module.scss';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import { essentialOneProductFormat } from '@src/utils/essentialOneFormat';

interface IProps {
  essentialOneProduct: {
    productId: string;
    product: IPillGetProduct;
  };
  personalisedPackProduct: {
    productId: string;
    product: IPillGetProduct;
  };
}

const ComparePersonalisedProducts: React.FC<IProps> = ({
  essentialOneProduct,
  personalisedPackProduct,
}) => {
  const {
    dataPlans: dataEssentialOnePlans,
    loading: loadingEssentialOne,
    isAvailable: isEssentialOneAvailable,
  } = useGetProductPlansData(
    'essential-one',
    essentialOneProductFormat(essentialOneProduct.productId)
  );
  const {
    dataPlans: dataPersonalisedPackPlans,
    loading: loadingPersonalisedPack,
    isAvailable: isPersonalisedAvailable,
  } = useGetProductPlansData('personalised', personalisedPackProduct.productId);

  const [showModalNutrients, setShowModalNutrients] = useState();
  const [pillInfo, setPillInfo] = useState();

  const { label: eoLabel, pills: eoPills } = essentialOneProduct?.product;
  const { label: ppLabel, pills: ppPills } = personalisedPackProduct?.product;

  if (loadingEssentialOne || loadingPersonalisedPack) return <Loader />;

  return (
    <section className={styles.sectionPrimaryLighter}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.personalisedProductsItem} id="personalisedPack">
            <div className={styles.mostPopularLabel}>Most popular</div>
            <div>
              <h4>{ppLabel}</h4>
              <hr />
              <p>
                A comprehensive combination of 4 personalised supplements in handy daily strips.
              </p>
            </div>
            <ul className={styles.personalisedPills}>
              {ppPills.map((pill: IPillGetProduct) => (
                <li
                  onClick={() => {
                    setShowModalNutrients(true);
                    setPillInfo(pill);
                  }}
                >
                  <img src={pill.iconUrl} height="60" />
                  <div>
                    <p>
                      <strong>{pill.label}</strong>
                    </p>
                    <p className={styles.pillDescription}>{pill.shortDescription}</p>
                  </div>
                </li>
              ))}
            </ul>
            {dataPersonalisedPackPlans && (
              <AddToBasket
                plans={dataPersonalisedPackPlans.product_plans}
                showDeliveryText={false}
                isAvailable={isPersonalisedAvailable}
              />
            )}
          </div>
          <div className={styles.personalisedProductsDivider}></div>
          <div className={styles.personalisedProductItemLast} id="essentialOne">
            <div>
              <h4>{eoLabel}</h4>
              <hr className={styles.hr} />
              <p>A personalised daily supplement covering your essential needs.</p>
            </div>
            <div>
              <img src={eoPills[0].iconUrl} height="90" />
              <ul className={styles.personalisedPills}>
                {eoPills.map((pill: IPillGetProduct) => (
                  <li
                    onClick={() => {
                      setShowModalNutrients(true);
                      setPillInfo(pill);
                    }}
                  >
                    <div>
                      <p>
                        <strong>{pill.label}</strong>
                      </p>
                      <p className={styles.pillDescription}>{pill.shortDescription}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {dataEssentialOnePlans && (
              <AddToBasket
                plans={dataEssentialOnePlans.product_plans}
                showDeliveryText={false}
                isAvailable={isEssentialOneAvailable}
              />
            )}
          </div>
        </div>
      </div>

      {pillInfo && (
        <ModalNutrients
          showModalNutrients={showModalNutrients}
          setShowModalNutrients={setShowModalNutrients}
          pillInfo={pillInfo}
        />
      )}
    </section>
  );
};

export default ComparePersonalisedProducts;
