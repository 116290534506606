import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
// components
import Modal from '@components/modal';
import ModalHeader from '@components/modal/modal-header';
import ModalBody from '@components/modal/modal-body';
import { SwiperComponent } from 'vitl-component-library';
// styles
import styles from './modalAllNutrients.module.scss';
import fadeTransition from '../../../../../../styles/fade.module.scss';
// interfaces
import { IProductPill } from '@src/types/product';
// constants
import { MODAL_TYPE } from '@components/modal/constants';

interface IProps {
  pills: IProductPill[];
  showModalNutrients: boolean;
  setShowModalNutrients: (value: boolean) => void;
}

const ModalAllNutrients: React.FC<IProps> = ({
  pills,
  showModalNutrients,
  setShowModalNutrients,
}) => {
  const [pillInfo, setPillInfo] = useState<IProductPill>(pills[0]);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const handleSlideChange = (swiper: any) => {
    setPillInfo(pills[swiper.activeIndex]);
  };

  const hideArrow = (swiper: any) => {
    if (swiper.isBeginning) {
      setHideLeftArrow(true);
    } else {
      setHideLeftArrow(false);
    }

    if (swiper.isEnd) {
      setHideRightArrow(true);
    } else {
      setHideRightArrow(false);
    }
  };

  useEffect(() => {
    if (pills.length === 1) {
      setHideLeftArrow(true);
      setHideRightArrow(true);
    }
  }, [pills]);

  useEffect(() => {
    return () => {
      setHideLeftArrow(true);
      setHideRightArrow(pills.length === 1);
    };
  }, []);

  const handleCloseModal = () => {
    setHideLeftArrow(true);
    setHideRightArrow(pills.length === 1);
    setShowModalNutrients(false);
  };

  return (
    <CSSTransition
      in={showModalNutrients}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => handleCloseModal()} type={MODAL_TYPE.allNutrients}>
        <div
          className={`${styles.sliderWrapper} ${hideLeftArrow && styles.hideLeftArrow} ${
            hideRightArrow && styles.hideRightArrow
          }`}
        >
          <SwiperComponent
            onActiveIndexChange={handleSlideChange}
            allowTouchMove={true}
            showArrows={true}
            pagination={false}
            slidesPerView={1}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            onSlideChange={hideArrow}
          >
            {pills.map(pill => (
              <div className={styles.slide} key={pill.id}>
                <ModalHeader type={MODAL_TYPE.allNutrients}>
                  <button
                    data-testid="modal-button-close"
                    type="button"
                    className={styles.closeModalBtn}
                    onClick={() => handleCloseModal()}
                  >
                    <img src={'/images/icon_close.svg'} alt="Close" />
                  </button>
                  <div className={styles.header}>
                    <img src={pillInfo.iconUrl} alt={pillInfo.label} />
                    <div>
                      <h3>{pillInfo.label}</h3>
                      <hr className={styles.hr} />
                      <h4>{pillInfo.description}</h4>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody type={MODAL_TYPE.allNutrients}>
                  <div className={styles.modalContent}>
                    <p>{pillInfo.longDescription}</p>
                    <div className={styles.pillDetailsTable}>
                      <p>
                        <strong>Ingredients</strong>
                      </p>
                      <p className={styles.textSmall}>{pillInfo.ingredientsList}</p>
                      <p>
                        <strong>Nutritional information</strong>
                      </p>
                      <ul>
                        {pillInfo.nutrients.map(nutrient => (
                          <li className={styles.nutrient} key={nutrient.name}>
                            <span>{nutrient.name}</span>
                            {nutrient.type !== 'heading' && (
                              <span>
                                {nutrient.amount && (
                                  <span>
                                    {nutrient.amount}
                                    {nutrient.unit}
                                  </span>
                                )}
                                {nutrient.nrv && <span> ({nutrient.nrv}%)</span>}
                                {!nutrient.nrv && <span> (&dagger;)</span>}
                              </span>
                            )}
                          </li>
                        ))}
                        <li className={styles.pillDetailsRda}>&dagger; NRV not established</li>
                      </ul>
                      <p>
                        <strong>Further information</strong>
                      </p>
                      <p className={styles.textSmall}>
                        Does not contain bulking agents, colours, sweeteners or flavourings. Free
                        from gluten, lactose, dairy products, yeast, salt, wheat and maize.
                      </p>
                    </div>
                  </div>
                </ModalBody>
              </div>
            ))}
          </SwiperComponent>
        </div>
      </Modal>
    </CSSTransition>
  );
};

export default ModalAllNutrients;
