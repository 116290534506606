export enum ACCOUNT_RESULT_OFFER_CODES {
  EssentialOne = 'EO',
  PersonalisedPlus = 'PP',
}

export enum ACCOUNT_RESULT_PACK_NAMES {
  EO = 'Essential One',
  PP = 'Personalised Pack',
}

export enum RESULT_STEP_URLS {
  step_1 = '/account/result/strengths',
  step_2 = '/account/result/goals',
  step_3 = '/account/result/recommendation_preview',
}
