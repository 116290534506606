import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';

import CustomCheckbox from '@components/form/custom-checkbox';
import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import { MODAL_TYPE } from '@components/modal/constants';
import SubmitButton from '@components/submit-button';
import { ISubscriptionCancelFeedbackOption } from '@interfaces/subscriptions';
import fadeTransition from '../../../../styles/fade.module.scss';
import { QUERY_SUBSCRIPTION_CANCEL_FEEDBACK_OPTIONS } from '@queries/subscription';
import { ISubscription } from '../../../../types/subscription';
import { trackGAEvent } from '@services/tracking/ga';

import styles from '../../account-subscription/account-subscription.module.scss';

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  subscription: ISubscription;
  onSubmit: (formData: any) => void;
}

const ShowCancelReasonModal = ({ showModal, closeModal, subscription, onSubmit }: IProps) => {
  const { register, handleSubmit, errors } = useForm();

  const { data: subscriptionCancelFeedbackOptionsData } = useQuery(
    QUERY_SUBSCRIPTION_CANCEL_FEEDBACK_OPTIONS
  );

  useEffect(() => {
    trackGAEvent('cancellation_reason', {});
  }, []);

  const cancellationReasonOptions = subscriptionCancelFeedbackOptionsData?.subscription_cancellationFeedbackOptions as ISubscriptionCancelFeedbackOption[];

  return (
    <CSSTransition in={showModal} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal handleClose={closeModal} type={MODAL_TYPE.fullHeight}>
        <ModalBody>
          <div className={styles.modalContainer}>
            <div>
              <h2>We’ll miss you</h2>
              <hr />
            </div>
            <div className={styles.subscriptionPreview}>
              <div className={styles.previewImage}>
                <img src={`${subscription.product.imageUrl}?width=100`} alt="" />
              </div>
              <p className={styles.previewDetails}>
                {subscription.product.name}
                <br />
                <strong>{subscription.frequency}</strong>
              </p>
            </div>
            <div className={styles.goodbyeText}>
              <h6>Please tell us why you’re leaving</h6>
              <p>
                We're still a young business and would really appreciate it if you could tell us why
                (select all that apply)
              </p>
            </div>

            {cancellationReasonOptions ? (
              <form onSubmit={handleSubmit(onSubmit)} className={styles.cancelForm}>
                <div className={styles.reasons}>
                  {cancellationReasonOptions.map(
                    ({ key, value }: ISubscriptionCancelFeedbackOption) => {
                      return (
                        <CustomCheckbox
                          key={key}
                          label={value}
                          id={key}
                          name="cancellationReasons"
                          register={register({
                            required: (r: any) => r.length > 0,
                          })}
                        />
                      );
                    }
                  )}
                </div>

                {errors.cancellationReasons?.type === 'required' && (
                  <p className={styles.error}>Please select at least one reason</p>
                )}

                <SubmitButton buttonLabel="Continue" fullWidth />
              </form>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ShowCancelReasonModal;
