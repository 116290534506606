import React from 'react';

import { Pills } from './Pills';

import styles from './PPRecomndation.module.scss';

export const PPRecomndation = () => {
  return (
    <div className={styles.recommentation}>
      <div className={styles.container}>
        <div className={styles.capsulesWrap}>
          <div className={styles.capsules}>
            <img className={styles.pillBox} src="/images/pills/3-Blister-Front-Friday.png" alt="" />
            <div className={styles.pillBoxEmpty}>
              <img src="/images/pills/2-Blister-Empty.png" alt="" />
            </div>
            <div className={`${styles.pillBoxEmpty} ${styles.reflection}`}>
              <img src="/images/pills/1-Blister-Reflection.png" alt="" />
            </div>
            <Pills />
          </div>
        </div>
        <div className={styles.textWrap}>
          <h2>4 daily vitamins</h2>
          <p>
            Your vitamins come in handy strips labelled by the day of the week. Great for when
            you're on the move!
          </p>
        </div>
      </div>
    </div>
  );
};
