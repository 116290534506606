import { useQuery } from '@apollo/react-hooks';
//queries
import { CROSS_SELL_MY_VITL_PRODUCTS } from '@queries/product';
//interfaces
import { ICrossSellMyVitlQueryResponse } from '@src/types/query-response';

const useCrossSellMyVitlProducts = () => {
  const { data: crossSellMyVitlProductsData, loading: loadingCrossSellMyVitlProducts } = useQuery<
    ICrossSellMyVitlQueryResponse
  >(CROSS_SELL_MY_VITL_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const crossSellMyVitlProducts = crossSellMyVitlProductsData?.content_crossSellMyVitl;

  return { crossSellMyVitlProducts, loadingCrossSellMyVitlProducts };
};

export default useCrossSellMyVitlProducts;
