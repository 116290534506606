import { navigate } from '@reach/router';
import { logout } from '@services/auth';
import { snakeToCamel } from '../../services/format';

export interface IButtonAction {
  type: string;
  value: string;
}

export const getContentStyles = (style: any) => {
  const parsedStyle: any = {};

  if (style) {
    for (const [key, value] of Object.entries(style)) {
      const camelKey = snakeToCamel(key);

      if (camelKey === 'textColor') {
        parsedStyle.color = value;
      } else {
        if (value) parsedStyle[camelKey] = value;
      }
    }
  }

  return parsedStyle;
};

export const handleContentAction = (action: IButtonAction) => {
  if (!action) {
    return false;
  }
  switch (action.type) {
    case 'openConsultation':
    case 'startConsultation':
      navigate('/consultation');
      break;

    //case 'openUrl' :
    //case 'viewWebContent' :
    //window.open(this.appAction.value); break;

    case 'viewRecommendations':
    case 'viewDashboard':
      // if user on consultation/result, redirect to account/result instead of account
      const currentUrl = window.location.pathname.split('/');
      const currentPage = currentUrl[currentUrl.length - 1][0];

      if (currentPage === 'result') {
        navigate('/account/result');
      } else {
        navigate('/account/dashboard');
      }
      break;

    //case 'viewBasket' :
    //setShowBasket(); - can't set basket here because setBasket() can only be called inside a React function
    //break;

    case 'viewCustomerSupportScreen':
      navigate('/contact');
      break;

    case 'logOut':
      logout();
      break;

    case 'dismissCartWarning':
    case 'dismissScreen':
      navigate('/');
      break;

    case 'viewTestKitRegistrationScreen':
      navigate('/kit');
      break;

    case 'presentContent':
    case 'viewContent':
      if (!action.value.startsWith('/')) {
        action.value = '/' + action.value;
      }
      navigate(`/account${action.value}`);
      break;

    case 'payDigitalSubscription':
    case 'productBuyNow':
      if (!action.value.startsWith('/')) {
        action.value = '/' + action.value;
      }
      navigate(`/account/select-plan${action.value}`);
      break;
  }
};
