import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
import { Link } from 'gatsby';
import React from 'react';

import styles from './NoConsultation.module.scss';

const NoConsultation = () => {
  const { directusAccountConsultationResult } = useAccountText();
  return (
    <section className={styles.sectionNoResults}>
      <div className={styles.container}>
        <h2>{directusAccountConsultationResult.no_consultation_title}</h2>
        <p>{directusAccountConsultationResult.no_consultation_description}</p>
        <br />
        <Link to="/consultation" className={styles.button}>
          {directusAccountConsultationResult.no_consultation_button_label}
        </Link>
      </div>
    </section>
  );
};

export default NoConsultation;
