import React, { Suspense } from 'react';
import { Router, Redirect } from '@reach/router';

import Loader from '@src/components/loader';
import PrivateRoute from '@src/components/private-route';
// Pages (old)
import AccountSignIn from '@components/account-sign-in';
import AccountGoal from '@components/account-goal';
import AccountDashboard from '@components/account-dashboard';
import AccountOrders from '@components/account-orders';
import AccountDetails from '@components/account-details';
import AccountSelectPlan from '@components/account-select-plan';
import AccountSubscriptions from '@components/account-subscriptions';
import AccountPayment from '@components/account-payment';
import AccountResult from '@components/account-result';
import AccountConsultationResult from '@components/account-consultation-result';
import AccountFeedback from '@components/account-feedback';
import AccountReferral from '@components/account-referral';
import AccountContentScreen from '@components/account-content-screen';
// NEW PAGES
import ResultPage from './pages/result';
import QuizResults from '@features/account/pages/result/components/QuizResults/QuizResults';
// Hooks
import { useGetAccountData } from './hooks/useGetAccountData';
import PillLoading from '@features/account/pages/result/components/PillLoading/PillLoading';

const Routes: React.FC = () => {
  useGetAccountData();

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <AccountSignIn path="/account/signin" />
        <PrivateRoute path="/account/dashboard" component={AccountDashboard} />
        <PrivateRoute path="/account/result" component={AccountResult} />
        <PrivateRoute path="/account/recommendation" component={ResultPage} />
        <PrivateRoute path="/account/result/loading" component={PillLoading} />
        <PrivateRoute path="/account/result/strengths" component={QuizResults} />
        <PrivateRoute path="/account/result/goals" component={QuizResults} />
        <PrivateRoute path="/account/result/recommendation_preview" component={QuizResults} />
        <PrivateRoute path="/account/consultation/result" component={AccountConsultationResult} />
        <PrivateRoute path="/account/goal/:goal" component={AccountGoal} />
        <PrivateRoute path="/account/details" component={AccountDetails} />
        <PrivateRoute path="/account/orders" component={AccountOrders} />
        <PrivateRoute path="/account/subscriptions" component={AccountSubscriptions} />
        <PrivateRoute path="/account/payment" component={AccountPayment} />
        <PrivateRoute path="/account/feedback" component={AccountFeedback} />
        <PrivateRoute path="/account/referral" component={AccountReferral} />
        <PrivateRoute path="/account/select-plan/:productId" component={AccountSelectPlan} />
        <PrivateRoute default component={AccountContentScreen} />
        <Redirect from="/account" to="/account/dashboard" noThrow />
      </Router>
    </Suspense>
  );
};

export default Routes;
