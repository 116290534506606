import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
// styles
import styles from './address-manual.module.scss';
// components
import CustomInput from '../form/custom-input';
import CustomSelect from '../form/custom-select';
import SubmitButton from '../submit-button';
import PhoneNumberInput from '@components/form/phone-number-input';
// services
import { isEmpty } from '@services/global';
// interfaces
import { IAddress, ICountry } from '@interfaces/address';

interface IProps {
  content: any;
  countries: Array<ICountry>;
  addressData: any;
  loading: boolean;
  error?: any;
  submitLabel: string;
  submit: (formData: IAddress) => void;
}

const AddressManual: React.FC<IProps> = ({ content, countries, addressData, loading, error, submitLabel, submit }) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { dirtyFields },
    watch,
    reset,
  } = useForm({
    mode: 'all',
  });

  const { city, countryId, firstName, lastName, postcode, street, phone } = addressData;

  const onSubmit = (formData: IAddress) => {
    isEmpty(errors) && submit(formData);
  };

  useEffect(() => {
    reset({
      city,
      countryCode: countryId,
      firstName,
      lastName: lastName || '',
      postcode,
      street,
      phone: phone && isValidPhoneNumber(phone) ? phone : '',
    });
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.updateAddressForm}>
      <fieldset disabled={loading}>
        <div className={styles.halfWidthInputContainer}>
          <div className={styles.halfWidthInput}>
            <CustomInput
              id="firstName"
              register={register({ required: true })}
              label={content.delivery_first_name_label}
              isDirty={dirtyFields.firstName || watch('firstName')}
              error={errors.firstName}
              errorMessage="Please enter your first name"
              autocomplete="off"
            />
          </div>
          <div className={styles.halfWidthInput}>
            <CustomInput
              id="lastName"
              register={register({ required: true })}
              label={content.delivery_last_name_label}
              isDirty={dirtyFields.lastName || watch('lastName')}
              error={errors.lastName}
              errorMessage="Please enter your last name"
              autocomplete="off"
            />
          </div>
        </div>

        <CustomInput
          id="street"
          register={register({ required: true })}
          label={content.delivery_address_label}
          isDirty={dirtyFields.street || watch('street')}
          error={errors.street}
          errorMessage="Please enter the first line of your address"
          autocomplete="off"
        />

        <CustomInput
          id="city"
          register={register({ required: true })}
          label={content.delivery_city_label}
          isDirty={dirtyFields.city || watch('city')}
          error={errors.city}
          errorMessage="Please enter your city"
          autocomplete="off"
        />

        <CustomInput
          id="postcode"
          register={register({ required: true, minLength: 4 })}
          label={content.delivery_postcode_label}
          isDirty={dirtyFields.postcode || watch('postcode')}
          error={errors.postcode}
          errorMessage="Please enter a valid postcode"
          autocomplete="off"
        />

        {countries && (
          <CustomSelect
            id="countryCode"
            register={register({ required: true })}
            label="Country"
            options={countries}
            error={errors.countryCode}
            errorMessage="Please choose your country"
          />
        )}

        <PhoneNumberInput
          id="phone"
          autoComplete="off"
          control={control}
          countryCallingCodeEditable={false}
          error={errors.phone}
          required={false}
          hasValue={!!watch('phone')}
        />
      </fieldset>

      <SubmitButton loading={loading} error={error} buttonLabel={submitLabel} fullWidth />
    </form>
  );
};

export default AddressManual;
