import React from 'react';
import { Link } from 'gatsby';
import { Card, Image } from 'vitl-component-library';

import styles from './backed-by-science.module.scss';
import { isStringValueValidNumber } from '@services/format';

interface IProps {
  title: string;
  studies: string;
  participants: string;
  description: string;
  link: string;
  reference?: string;
}

const BackedByScienceSliderItem: React.FC<IProps> = ({
  title,
  studies,
  participants,
  description,
  link,
  reference,
}) => {
  return (
    <Card className={styles.slideCard} borderRadius={true} shadow={true}>
      <div className={styles.slideContent}>
        <div className={styles.slideTextContent}>
          <h3>{title}</h3>
          <hr className={styles.hr} />
          <div className={styles.studiesAndParticipants}>
            {!!studies ? (
              <p>
                {isStringValueValidNumber(studies) && 'Studies: '}
                <span>{studies}</span>
              </p>
            ) : null}
            {!!participants ? (
              <p>
                {isStringValueValidNumber(participants) && 'Participants: '}
                <span>{participants}</span>
              </p>
            ) : null}
          </div>
          <p>{description}</p>
        </div>

        {reference ? (
          <p className={styles.footer}>{reference}</p>
        ) : (
          <Link className={styles.slideLink} to={link} target={'_blank'}>
            Read journal
            <sup className={styles.externalLinkIcon}>
              <Image url={'/images/icons/icon-external-link.svg'} width={10} />
            </sup>
          </Link>
        )}
      </div>
    </Card>
  );
};

export default BackedByScienceSliderItem;
