import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

// components
import SignInForm from '../sign-in-form';
import Loader from '../loader';
import Magic from '../../pages/login/magic';
// styles
import styles from './sign-in.module.scss';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

interface IProps {
  destination: string;
}

const SignIn: React.FC<IProps> = ({ destination }) => {
  const { directusAccount } = useAccountText();

  const location = useLocation();

  const parameters = new URLSearchParams(location.search);
  const token = parameters.get('token');

  const [isMagicLinkView, setIsMagicLinkView] = useState<boolean>(false);

  if (!directusAccount) return <Loader />;
  if (token) return <Magic />;

  return (
    <div>
      <h3>{directusAccount.signin_title}</h3>
      <hr />
      <SignInForm destination={destination} setIsMagicLinkView={setIsMagicLinkView} />
      {!isMagicLinkView && (
        <div className={styles.buttonContainer}>
          <h4>{directusAccount.signin_no_account}</h4>
          <Link className={styles.buttonGhost} to="/register">
            {directusAccount.signin_register_button_label}
          </Link>
        </div>
      )}
    </div>
  );
};

export default SignIn;
