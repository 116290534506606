import React from 'react';
import styles from '@components/account-payment/account-payment.module.scss';
import { IPaymentMethod } from '../../types/payment';
import { ISubscription } from '@interfaces/subscriptions';

interface ICard extends IPaymentMethod {
  lastFourDigits: string;
  cardIssuer: string;
  expiryYear: string;
}

interface IProps {
  card: ICard;
  subscription: ISubscription;
  selectPaymentMethodCallback: (cardId: string, subscriptionId: string, lastFourDigits: string) => void;
}

const PaymentCard: React.FC<IProps> = ({ card, subscription, selectPaymentMethodCallback }) => {
  const hasCardExpired = (expiryMonth: string, expiryYear: string) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const expMonth = parseInt(expiryMonth, 10);
    const expYear = parseInt(expiryYear, 10);

    if (expYear < currentYear) {
      return true;
    } else return expYear === currentYear && expMonth < currentMonth;
  };

  return (
    <li
      onClick={() => selectPaymentMethodCallback(card.id, subscription.id, card.lastFourDigits)}
      key={card.id}
      className={styles.cardPaymentMethod}
    >
      <img
        className={styles.cardPaymentIssuer}
        src={`/images/cards/${card.cardIssuer.toLowerCase()}.png`}
        srcSet={`/images/cards/${card.cardIssuer.toLowerCase()}.png 1x,
                        /images/cards/${card.cardIssuer.toLowerCase()}@2x.png 2x`}
        sizes="auto"
        alt={card.cardIssuer}
      />
      <div className={styles.cardPaymentDetails}>
        <div className={styles.cardPaymentNumber}>
          **** **** **** {card.lastFourDigits}
          {hasCardExpired(card.expiryMonth, card.expiryYear) && (
            <b className={styles.cardPaymentMethodExpired}>Card expired</b>
          )}
        </div>
        <div className={styles.cardPaymentNameExpiryDate}>
          <span>
            <b>{card.name}</b>
          </span>{' '}
          | <span>{card.postcode}</span> |{' '}
          <span>
            Expires: {card.expiryMonth}/{card.expiryYear.slice(-2)}
          </span>
        </div>
      </div>
    </li>
  );
};

export default PaymentCard;
