import React from 'react';
// components
import { GOALS_EXTRA_INFO } from '../../constants/goalsExtraInfo';
import { IHealthProfileGoal } from '../../types/goal';
// styles
import styles from './goal-panel.module.scss';

interface IProps {
  goal: IHealthProfileGoal;
}

const GoalPanel: React.FC<IProps> = ({ goal }) => {
  goal.issues = goal?.issues?.filter(issue => issue.name !== '');
  goal.issues = goal?.issues?.slice(0, 3); //just want the first 3

  if (!GOALS_EXTRA_INFO[goal.id]) return null;

  return (
    <div className={styles.panelContainer}>
      <div
        className={styles.panelHeader}
        style={{
          backgroundColor: GOALS_EXTRA_INFO[goal.id].background,
          color: GOALS_EXTRA_INFO[goal.id].text,
        }}
      >
        <div>
          <h5 className={styles.goalHeading}>{goal.name}</h5>
          <p className={styles.textXsmall}>{goal.tagline}</p>
        </div>
        <img
          src={`${goal.imageUrl}?&colorize=%23${GOALS_EXTRA_INFO[goal.id].text.split('#')[1]}&truecolor=true`}
          alt={goal.name}
        />
      </div>
      <div className={styles.panelBody}>
        {!goal?.issues?.length && (
          <div
            dangerouslySetInnerHTML={{
              __html: GOALS_EXTRA_INFO[goal.id].issueLongDesc,
            }}
          />
        )}

        {goal?.issues?.length === 1 && <p className={styles.shortDesc}>{GOALS_EXTRA_INFO[goal.id].issueShortDesc}</p>}

        {goal?.issues && goal.issues.length > 0 && (
          <p>
            <strong>You told us:</strong>
          </p>
        )}

        <ul className={styles.issuesList}>
          {goal?.issues?.map(issue => (
            <li key={issue.id} className={styles.textXsmall}>
              <span>{issue.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GoalPanel;
