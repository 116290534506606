import { useContext } from 'react';
import { ResultPageContext } from '../store/store';
import useAccountResult from './useAccountResult';

const useIsProductUpToDate = () => {
  const { personalisedProduct } = useContext(ResultPageContext);
  const { isSubscribedToRecommendedProduct } = useAccountResult();

  const isProductUpToDate = () =>
    personalisedProduct &&
    personalisedProduct.plans &&
    personalisedProduct.plans.length > 0 &&
    isSubscribedToRecommendedProduct;

  return isProductUpToDate();
};

export default useIsProductUpToDate;
