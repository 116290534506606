import React, { useContext } from 'react';
import BackedByScienceSection from '@src/components/backed-by-sience/backed-by-science-section';
import { MockBackedByScienceData } from '@src/components/backed-by-sience/mock';
import { ResultPageContext } from '../../store/store';

export const BackedByScience = () => {
  const { dataHealthResults } = useContext(ResultPageContext);

  const data = MockBackedByScienceData;

  if (!dataHealthResults?.topNutrientStudies) return null;

  return (
    <BackedByScienceSection
      title={data.sectionTitle}
      subtitle={data.sectionSubtitle}
      items={dataHealthResults?.topNutrientStudies}
      backgroundColor="#F5F5F5"
    />
  );
};
