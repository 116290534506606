import { useQuery } from '@apollo/react-hooks';
import { USER_KIT_STATUS } from '@queries/user';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '@src/store/global-state';

const useUserKitStatus = () => {
  const { setUserKitStatus } = useContext(GlobalContext);

  const { data, loading, error } = useQuery(USER_KIT_STATUS, {
    fetchPolicy: 'network-only',
  });

  if (data) {
    setUserKitStatus(data?.user_kitsStatus);
  }
};

export default useUserKitStatus;
