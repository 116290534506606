import React from 'react';
import { CSSTransition } from 'react-transition-group';
// components
import Modal from '../modal';
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import { MODAL_TYPE } from '@components/modal/constants';
// interfaces
import { IProductPill } from '../../types/product';
// styles
import styles from './modal-nutrients.module.scss';
import fadeTransition from '../../styles/fade.module.scss';

interface IProps {
  pillInfo: IProductPill;
  showModalNutrients: boolean;
  setShowModalNutrients: (value: boolean) => void;
}

const ModalNutrients: React.FC<IProps> = ({ pillInfo, showModalNutrients, setShowModalNutrients }) => {
  return (
    <CSSTransition in={showModalNutrients} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal handleClose={() => setShowModalNutrients(false)} type={MODAL_TYPE.nutrients}>
        <ModalHeader type={MODAL_TYPE.nutrients}>
          <div className={styles.header}>
            <img src={pillInfo.iconUrl} alt={pillInfo.label} />
            <div>
              <h3>{pillInfo.label}</h3>
              <hr className={styles.hr} />
              <h4>{pillInfo.description}</h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody type={MODAL_TYPE.nutrients}>
          <div className={styles.modalContent}>
            <p>{pillInfo.longDescription}</p>
            <div className={styles.pillDetailsTable}>
              <p>
                <strong>Ingredients</strong>
              </p>
              <p className={styles.textSmall}>{pillInfo.ingredientsList}</p>
              <p>
                <strong>Nutritional information</strong>
              </p>
              <ul>
                {pillInfo.nutrients.map(nutrient => (
                  <li className={styles.nutrient} key={nutrient.name}>
                    <span>{nutrient.name}</span>
                    {nutrient.type !== 'heading' && (
                      <span>
                        {nutrient.amount && (
                          <span>
                            {nutrient.amount}
                            {nutrient.unit}
                          </span>
                        )}
                        {nutrient.nrv && <span> ({nutrient.nrv}%)</span>}
                        {!nutrient.nrv && <span> (&dagger;)</span>}
                      </span>
                    )}
                  </li>
                ))}
                <li className={styles.pillDetailsRda}>&dagger; NRV not established</li>
              </ul>
              <p>
                <strong>Further information</strong>
              </p>
              <p className={styles.textSmall}>
                Does not contain bulking agents, colours, sweeteners or flavourings. Free from gluten, lactose, dairy
                products, yeast, salt, wheat and maize.
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalNutrients;
