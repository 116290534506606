import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from '@apollo/client';
import AccountBalance from '@components/account-balance/AccountBalance';
// components
import AccountSubscription from './account-subscription';
import Loader from '../loader';
// queries
import { SUBSCRIPTIONS } from '../../queries/orders';
// styles
import styles from './account-subscriptions.module.scss';
import { IUserSubscriptionsQueryResponse } from '../../types/query-response';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

const AccountSubscriptions: React.FC = () => {
  const { directusAccountSubscription } = useAccountText();

  // queries
  const { data, loading, error, networkStatus, refetch } = useQuery<
    IUserSubscriptionsQueryResponse
  >(SUBSCRIPTIONS, {
    fetchPolicy: 'no-cache',
  });

  const activeSubscriptions = data?.user_subscriptions?.filter(s => s.status !== 'cancelled') || [];

  const refetching = networkStatus === NetworkStatus.refetch;

  if (loading || refetching || !directusAccountSubscription) return <Loader />;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.h2}>{directusAccountSubscription.title}</h1>
        <hr />
        <div className={styles.row}>
          <div className={styles.col}>
            {error && <p>We had a problem fetching your subscriptions. Please try again later.</p>}

            <AccountBalance />
            {activeSubscriptions.length > 0 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: directusAccountSubscription.has_subscriptions_subtitle,
                }}
              />
            )}
            {activeSubscriptions.length === 0 && (
              <p>{directusAccountSubscription.no_subscriptions_subtitle}</p>
            )}
            {directusAccountSubscription.apply_the_coupon_text ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: directusAccountSubscription.apply_the_coupon_text,
                }}
              />
            ) : null}
            <ul className={styles.subscriptions}>
              {activeSubscriptions.map(subscription => (
                <AccountSubscription
                  key={subscription.id}
                  subscription={subscription}
                  refetchSubscriptions={refetch}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountSubscriptions;
