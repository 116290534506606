import React from 'react';

import HeroContent from './hero-content';
import HeroSlider from './hero-swiper';
import HeroMain from './hero-main';

import styles from './hero.module.scss';

const Hero = () => {
  return (
    <div className={styles.heroWrap}>
      <div className={styles.hero}>
        <HeroSlider />
        <div className={styles.main}>
          <div className={styles.section}>
            <HeroMain />
            <HeroContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
