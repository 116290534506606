import React from 'react';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
// hooks
import { useAccountText } from '@hooks/directusHooks/useAccountText';

import NewAccount from '@src/features/account';

const Account: React.FC = () => {
  const content = useAccountText();

  return (
    <Layout showFooter={false}>
      <SEO
        title={content.directusAccount.page_title}
        description={content.directusAccount.page_description}
      />
      <NewAccount />
    </Layout>
  );
};

export default Account;
