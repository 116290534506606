import React from 'react';
import { Icon } from 'vitl-component-library';

import styles from './hero.module.scss';

export const CartIcons = () => {
  return (
    <div className={styles.iconWrap}>
      <p>
        <Icon icon="check" color="#46bc8f" size="10" /> Free UK Delivery
      </p>
      <p>
        <Icon icon="check" color="#46bc8f" size="10" /> Pause / cancel any time
      </p>
    </div>
  );
};
