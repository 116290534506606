import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { CSSTransition } from 'react-transition-group';
import { ApolloError } from 'apollo-client';
// context
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '../../constants';
// components
import AccountPaymentUpdate from '@components/account-payment-update';
import Modal from '@components/modal';
import ModalHeader from '@components/modal/modal-header';
import ModalBody from '@components/modal/modal-body';
import Error from '@components/error/error';
import Loader from '@components/loader';
import AddPaymentMethod from '@components/add-payment-method';
import ModalConfirm from '@components/modal-confirm/modal-confirm';
// services
import { formatGraphQLError } from '@services/format';
// queries
import {
  PAYMENT_METHODS,
  UPDATE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
  SUBSCRIPTION_UPDATE_PAYMENT_METHOD,
} from '@queries/payment';
// constants
import { GRAPH_QL_ERROR_TYPES } from '@src/constants';
import { GraphQLError } from '@interfaces/graphql';
// styles
import styles from './account-payment.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
// hooks
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';

const AccountPayment: React.FC = () => {
  const { directusAccount } = useAccountText();
  const { setErrorModalMessage } = useContext(GlobalContext);

  const preScaThreshold = parseInt(process.env.GATSBY_STRIPE_LAST_NON_SCA_CARD_ID!);

  // state
  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showAddPayment, setShowAddPayment] = useState<string | boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<null | any>(null);
  const [removeError, setRemoveError] = useState<ApolloError | string | undefined>('');
  const [loading, setLoading] = useState(true);
  const [confirmDefaultPaymentModal, setConfirmDefaultPaymentModal] = useState({
    show: false,
    title: '',
    description: '',
    paymentId: '',
    iconSrc: '',
  });

  // queries
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethods, data: dataPaymentMethods },
  ] = useLazyQuery(PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setErrorModalMessage(ERROR_MESSAGES.getPaymentMethods);
    },
    onCompleted: () => setLoading(false),
  });

  const [
    updatePaymentMethod,
    { data: dataUpdatePayment, loading: loadingUpdatePayment },
  ] = useMutation(UPDATE_PAYMENT_METHOD, {
    onCompleted: res => {
      setShowUpdatePayment(false);
      const { default: isDefault, id: paymentId } = res?.user_updatePaymentMethod;
      if (isDefault) {
        setConfirmDefaultPaymentModal({
          show: true,
          title: 'The payment method has been successfully updated',
          description: 'Would you like to use this card to pay for all your subscriptions?',
          paymentId: paymentId,
          iconSrc: '/images/icon-compare-check-circle.svg',
        });
      }
      getPaymentMethods();
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.updatePaymentMethod);
    },
  });

  const [
    removePaymentMethod,
    { loading: loadingRemovePayment, error: errorRemovePayment },
  ] = useMutation(REMOVE_PAYMENT_METHOD, {
    update: (cache, removeResponse) => {
      const data: any = cache.readQuery({ query: PAYMENT_METHODS });
      data.user_paymentMethods = removeResponse.data.user_removePaymentMethod;
      cache.writeQuery({ query: PAYMENT_METHODS, data });
    },
    onCompleted: () => setShowUpdatePayment(false),
    onError: err => {
      const findError = err.graphQLErrors.find(
        (x: GraphQLError) => GRAPH_QL_ERROR_TYPES[x.errorType]
      );
      if (
        findError?.errorType === GRAPH_QL_ERROR_TYPES.DefaultPaymentMethodNotRemovable ||
        findError?.errorType === GRAPH_QL_ERROR_TYPES.invalidPaymentMethod
      ) {
        setRemoveError(err);
      } else {
        setErrorModalMessage(formatGraphQLError(err.graphQLErrors[0].message));
      }
    },
  });

  const [setAsDefaultPaymentMethod, { loading: loadingSettingDefaultPaymentMethod }] = useMutation(
    SUBSCRIPTION_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: () => {
        setConfirmDefaultPaymentModal({
          show: false,
          title: '',
          description: '',
          paymentId: '',
          iconSrc: '',
        });
        getPaymentMethods();
      },
    }
  );

  const handleAddPaymentOnCompleteCallback = (paymentMethodId: string) => {
    setShowAddPayment(false);
    if (dataPaymentMethods?.user_paymentMethods.length > 1) {
      setConfirmDefaultPaymentModal({
        show: true,
        title: 'The payment method has been successfully added',
        description: 'Would you like to use this card to pay for all your subscriptions?',
        paymentId: paymentMethodId,
        iconSrc: '/images/icon-compare-check-circle.svg',
      });
    }
  };

  const closeModal = () => {
    setRemoveError('');
    setShowUpdatePayment(false);
  };

  const handleUpdatePayment = (formData: any) => {
    const { postcode, setAsDefault } = formData;

    updatePaymentMethod({
      variables: {
        id: selectedPayment.id,
        postcode,
        setAsDefault: !!setAsDefault.length,
      },
    });
  };

  const handleRemovePayment = () => {
    removePaymentMethod({
      variables: {
        id: selectedPayment.id,
      },
    });
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  if (loading || loadingPaymentMethods || !directusAccount) return <Loader />;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.h2}>{showAddPayment || directusAccount.payment_title}</h1>
        <hr />
        <div className={styles.row}>
          <div className={styles.col}>
            {dataPaymentMethods?.user_paymentMethods.length === 0 && (
              <p>{directusAccount.payment_no_methods_subtitle}</p>
            )}

            {showAddPayment ? (
              <AddPaymentMethod
                hasMultiplePaymentMethods={dataPaymentMethods?.user_paymentMethods.length > 1}
                handleAddPaymentOnComplete={handleAddPaymentOnCompleteCallback}
              />
            ) : (
              <>
                <ul className={styles.paymentMethods}>
                  {dataPaymentMethods?.user_paymentMethods.map((card: any) => (
                    <li key={card.id} className={styles.paymentMethod}>
                      <div className={styles.cardIssuer}>
                        <img
                          src={`/images/cards/${card.cardIssuer.toLowerCase()}.png`}
                          srcSet={`/images/cards/${card.cardIssuer.toLowerCase()}.png 1x,
                      /images/cards/${card.cardIssuer.toLowerCase()}@2x.png 2x`}
                          sizes="auto"
                          alt={card.cardIssuer}
                        />
                      </div>
                      <div className={styles.cardDetails}>
                        <p className={styles.bold}>
                          <img
                            src={`/images/cards/${card.cardIssuer.toLowerCase()}.png`}
                            srcSet={`/images/cards/${card.cardIssuer.toLowerCase()}.png 1x,
                        /images/cards/${card.cardIssuer.toLowerCase()}@2x.png 2x`}
                            sizes="auto"
                            alt={card.cardIssuer}
                          />
                          **** **** **** {card.lastFourDigits}
                          {card.default && <span className={styles.label}>{'Default'}</span>}
                          {card.id < preScaThreshold && (
                            <span className={styles.label}>{'NEEDS UPDATING'}</span>
                          )}
                          <button
                            type="button"
                            className={styles.buttonLink}
                            onClick={() => {
                              if (card.id < preScaThreshold) {
                                setShowAddPayment('Re-enter Card Details');
                              } else {
                                setShowUpdatePayment(true);
                                setSelectedPayment(card);
                              }
                            }}
                          >
                            {directusAccount.payment_update_button_label}
                          </button>
                        </p>
                        <p>
                          <span className={styles.bold}>{card.name}</span> {card.postcode} | Expires{' '}
                          {card.expiryMonth}/{card.expiryYear}
                        </p>
                        {/* <button type="button" onClick={() => handleRemovePayment(card.id)}>{directusAccount.payment_remove_button_label}</button>
                    {removeResponse?.removePaymentMethod && <p>Your payment method has been removed.</p>} */}
                      </div>
                    </li>
                  ))}
                </ul>
                <button
                  className={styles.buttonPrimary}
                  type="button"
                  onClick={() => setShowAddPayment('Add Payment Method')}
                >
                  Add a payment card
                </button>
              </>
            )}

            {/*Modal update payment method*/}
            <CSSTransition
              in={showUpdatePayment}
              timeout={300}
              classNames={{ ...fadeTransition }}
              unmountOnExit
            >
              <Modal handleClose={closeModal} type="CrossSell">
                {(loadingRemovePayment || loadingUpdatePayment) && <Loader />}
                <ModalHeader>
                  <h4 className={styles.h4}>{directusAccount.payment_update_title}</h4>
                </ModalHeader>
                <ModalBody>
                  <AccountPaymentUpdate
                    paymentData={{
                      postcode: selectedPayment?.postcode,
                      default: selectedPayment?.default,
                    }}
                    loading={loadingUpdatePayment || loadingPaymentMethods}
                    submit={handleUpdatePayment}
                  ></AccountPaymentUpdate>
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => handleRemovePayment()}
                  >
                    {directusAccount.payment_remove_button_label}
                  </button>
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    {directusAccount.payment_update_cancel_button_label}
                  </button>
                  <Error data={removeError} noMargin={true} />
                  {dataUpdatePayment?.updatePaymentMethod && (
                    <p>Your payment method has been updated.</p>
                  )}
                </ModalBody>
              </Modal>
            </CSSTransition>

            {/*Modal confirm set as default payment method*/}
            <ModalConfirm
              iconSrc={confirmDefaultPaymentModal.iconSrc}
              show={confirmDefaultPaymentModal.show}
              title={confirmDefaultPaymentModal.title}
              description={confirmDefaultPaymentModal.description}
              onConfirm={() =>
                setAsDefaultPaymentMethod({
                  variables: {
                    paymentMethodId: confirmDefaultPaymentModal.paymentId,
                    subscriptionId: '',
                  },
                })
              }
              onDecline={() =>
                setConfirmDefaultPaymentModal({
                  show: false,
                  title: '',
                  description: '',
                  paymentId: '',
                  iconSrc: '',
                })
              }
              primaryButtonLabel={'Yes'}
              secondaryButtonLabel={'No'}
              loading={loadingSettingDefaultPaymentMethod}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountPayment;
