import React, { useContext, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
// components
import Loader from '../loader';
import SignIn from '../sign-in';
//services
import { isSSR } from '../../services/global';
// styles
import styles from './account-sign-in.module.scss';
import { GlobalContext } from '@src/store/global-state';

interface IProps extends RouteComponentProps {
  location?: any;
}

const AccountSignIn: React.FC<IProps> = ({ location }) => {
  const { isLoggedIn } = useContext(GlobalContext);
  const successRedirect = !isSSR && new URLSearchParams(window.location.search).get('successRedirect');

  const signInRedirect = () => {
    let redirectPath = location?.state?.path || '/account/dashboard';

    if (successRedirect === 'referral') redirectPath = '/account/referral';
    if (redirectPath === '/account/goal/:goal') redirectPath = '/account/dashboard';
    return redirectPath;
  };

  useEffect(() => {
    if (isLoggedIn) navigate('/account/dashboard');
  }, [isLoggedIn]);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <SignIn destination={signInRedirect()}></SignIn>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountSignIn;
