/**
 * used to format essential one product ids from
 * @example
 * `essential-one-15` to `essential-one-monthly-30-15-default`
 * Which is format we use to get data from backend
 */

export const essentialOneProductFormat = (productId: string) => {
  const originalString = productId;
  const searchString = 'essential-one-';
  const resultString = originalString.replace(new RegExp(searchString, 'g'), '');
  return `essential-one-monthly-30-${resultString}-default`;
};
