import React from 'react';
// constants
import { MODAL_TYPE } from '@components/modal/constants';
// component
import ModalHeader from '@components/modal/modal-header';
import ModalBody from '@components/modal/modal-body';
import Modal from '@components/modal';
import { Button } from 'vitl-component-library';
// styles
import styles from './modal-confirm.module.scss';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@src/styles/fade.module.scss';

interface IProps {
  show: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
  onDecline: () => void;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  loading: boolean;
  iconSrc?: string;
}

const ModalConfirm: React.FC<IProps> = ({
  show,
  title,
  description,
  onConfirm,
  onDecline,
  primaryButtonLabel,
  secondaryButtonLabel,
  loading,
  iconSrc = '',
}) => {
  return (
    <CSSTransition in={show} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal type={MODAL_TYPE.confirm}>
        <ModalHeader type={MODAL_TYPE.confirm}>
          {iconSrc && <img width="20px" src={iconSrc} alt="icon-modal" />}
          <h2>{title}</h2>
          <hr />
        </ModalHeader>
        <ModalBody type={MODAL_TYPE.confirm}>
          <p>{description}</p>
          <div className={styles.modalActions}>
            <Button primary={true} onClick={onConfirm} title={primaryButtonLabel} loading={loading} />
            <Button ghost={true} onClick={onDecline} title={secondaryButtonLabel} />
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalConfirm;
