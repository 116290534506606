import { ICrossSellMyVitlProduct } from '@src/types/product';

export const mapCrossSellMyVitlProductToShopProduct = (product: ICrossSellMyVitlProduct) => {
  return {
    best_seller: product.tag === 'best_seller',
    description: product.caption,
    digital_product: product.tag === 'digital_product',
    filename_disk: product.imageUrl || '',
    identifier: product.configurableSku,
    low_stock: product.tag === 'low_stock',
    name: product.name,
    out_of_stock: product.tag === 'out_of_stock',
    product: { name: product.name, identifier: product.configurableSku },
    route: '',
    selling_fast: product.tag === 'selling_fast',
    id: product.configurableSku,
    isImageFromMarketingDirectus: true,
  };
};
