import React, { useContext, useEffect, useState } from 'react';
import styles from './GoalNutrients.module.scss';
import { Button, SwiperComponent } from 'vitl-component-library';
import GoalItem from './GoalSlideItem';
import { ResultPageContext } from '@features/account/pages/result/store/store';
import Loader from '@components/loader';
import { navigate } from 'gatsby';
import SkeletonResultsFlowStep from '@components/skeletons/SkeletonResultsFlowStep';
import { useMediaQuery } from 'react-responsive';

export interface IGoalNutrientClaim {
  claim: string;
  nutrient: {
    amount: number;
    ingredient: string;
    name: string;
    nrv: number;
    unit: string;
  };
  score: number;
}

export interface IGoalNutrientInPack {
  goalId: string;
  nutrientClaims: IGoalNutrientClaim[];
}

const GoalNutrients = () => {
  const { dataHealthResults, loadingHealthResults, nutrientTotal } = useContext(ResultPageContext);
  const [goalNutrientsInPack, setGoalNutrientsInPack] = useState<IGoalNutrientInPack[] | []>([]);
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  useEffect(() => {
    if (dataHealthResults?.goalNutrientsInPack) {
      setGoalNutrientsInPack([...dataHealthResults.goalNutrientsInPack]);
    }
  }, [dataHealthResults]);

  return (
    <div className={styles.quizResultCard}>
      {loadingHealthResults ? (
        <SkeletonResultsFlowStep />
      ) : (
        <>
          <div className={styles.quizResultCardText}>
            <h3>Your top nutrients</h3>
            <h5>
              Based on your consultation, our nutritionists have selected{' '}
              <b>{nutrientTotal} nutrients </b>
              for you:
            </h5>
            <div className={styles.healthGoalsSlider}>
              <SwiperComponent
                paginationType="bullets"
                showArrows={isDesktop}
                pagination={isDesktop}
                breakpoints={{
                  320: {
                    slidesPerView: 1.2,
                    slidesOffsetBefore: 8,
                    slidesOffsetAfter: 8,
                  },
                  375: {
                    slidesPerView: 1.25,
                    slidesOffsetBefore: 15,
                    slidesOffsetAfter: 15,
                  },
                }}
              >
                {goalNutrientsInPack.map(goalItem => (
                  <GoalItem key={goalItem.goalId} goalItem={goalItem} />
                ))}
              </SwiperComponent>
            </div>
          </div>
          <Button
            className={styles.cardAction}
            title="Show me my vitamins!"
            primary={true}
            onClick={() => navigate('/account/recommendation')}
          />
        </>
      )}
    </div>
  );
};

export default GoalNutrients;
