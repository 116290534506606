import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import Modal from '@src/components/modal';
import ModalBody from '@src/components/modal/modal-body';
import ModalFooter from '@src/components/modal/modal-footer';
import ModalHeader from '@src/components/modal/modal-header';
import useAccountResult from '../../hooks/useAccountResult';
import styles from './SwithcSubscriptionModal.module.scss';
import { ResultPageContext } from '../../store/store';

import fadeTransition from '../../../../../../styles/fade.module.scss';

const SwitchSubscriptionModal = () => {
  const { getPackName, confirmUpdateSubscription, loading } = useAccountResult();

  const { setShowModalSwitchSubscription, showModalSwitchSubscription } = useContext(
    ResultPageContext
  );

  return (
    <CSSTransition
      in={showModalSwitchSubscription}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setShowModalSwitchSubscription(false)}>
        <ModalHeader>
          <h3>Update your vitamin pack</h3>
          <hr />
        </ModalHeader>
        <ModalBody>
          <p className={styles.updateModalText}>
            It’s important to update your pack every now and again to make sure your personalised
            vitamin formulas reflect any recent changes.
          </p>
          <p className={styles.updateModalTextNote}>
            {<>Please note: This update will apply to all of your {getPackName} subscriptions.</>}
          </p>
        </ModalBody>
        <ModalFooter
          handleClose={() => setShowModalSwitchSubscription(false)}
          handleSave={() => confirmUpdateSubscription()}
          saveLabel="Update"
          closeLabel="Cancel"
          loading={loading}
        />
      </Modal>
    </CSSTransition>
  );
};

export default SwitchSubscriptionModal;
