import React, { FC, useEffect } from 'react';
import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '../../../../styles/fade.module.scss';
import { ISubscription } from '../../../../types/subscription';
import styles from './are-you-sure-modal.module.scss';
import DropdownButton from '@components/dropdown-button/DropdownButton';
import { trackGAEvent } from '@services/tracking/ga';

interface IAreYouSureModalProps {
  showModal: boolean;
  subscription: ISubscription;
  closeModal: () => void;
  onPauseClicked: () => void;
  onCancelClicked: () => void;
  onKeepClicked: () => void;
}

const AreYouSureModal: FC<IAreYouSureModalProps> = ({
  showModal,
  closeModal,
  subscription,
  onPauseClicked,
  onCancelClicked,
  onKeepClicked,
}) => {
  useEffect(() => {
    trackGAEvent('cancellation_usp', {});
  }, []);

  const handleDropdownClick = (item: string) => {
    if (item === 'I want to pause') {
      onPauseClicked();
      return;
    }
    if (item === 'I want to cancel') {
      onCancelClicked();
      return;
    }
  };

  return (
    <CSSTransition in={showModal} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal handleClose={closeModal}>
        <ModalBody>
          <div className={styles.modalBody}>
            <h2 className={styles.modalTitle}>Are you sure?</h2>
            <hr />
            {subscription.hasLegacyPrice ? (
              <p className={styles.modalText}>
                Our prices have risen since you started your {subscription.product.name}{' '}
                subscription. If you cancel today and choose to come back in the future, your
                subscription cost will increase to £39.95 per month. What's more, by cancelling your
                subscription you'll also be saying goodbye to:
              </p>
            ) : (
              <p>If you cancel your subscription today you'll be saying goodbye to:</p>
            )}
            <div className={styles.subscriptionBenefits}>
              <div className={styles.subscriptionsBenefitsLeft}>
                <div className={styles.subscriptionBenefit}>
                  <img src="/images/icon-compare-check.svg" alt="check" />
                  <div>
                    A daily dose of vitamins designed to help you feel your best self all year round
                  </div>
                </div>
                <div className={styles.subscriptionBenefit}>
                  <img src="/images/icon-compare-check.svg" alt="check" />
                  <div>
                    The convenience of having your vitamins delivered right to your door each month
                  </div>
                </div>
              </div>
              <div className={`${styles.subscriptionsBenefitsRight} ${styles.subscriptionBenefit}`}>
                <img src="/images/icon-compare-check.svg" alt="check" />
                <div>
                  Clean, high quality ingredients that, unlike some other high street brands, are
                  non-GMO and 100% free from synthetic fillers and bulking agents
                </div>
              </div>
            </div>
            <div className={styles.subscription}>
              <img src={subscription.product.imageUrl} />
              <div className={styles.subscriptionDetailsAndActions}>
                <div className={styles.subscriptionProductName}>{subscription.product.name}</div>
                <div>
                  <span className={styles.subscriptionFrequency}>{subscription.frequency}</span> |{' '}
                  <span className={styles.subscriptionStatus}>{subscription.status}</span>
                </div>
                <div className={styles.subscriptionActions}>
                  <button
                    className={`${styles.buttonPrimary} ${styles.actionButton}`}
                    onClick={onKeepClicked}
                  >
                    Keep subscription
                  </button>
                  <DropdownButton
                    buttonText="No thank you"
                    items={['I want to pause', 'I want to cancel']}
                    onItemClick={handleDropdownClick}
                    dropdownClassName={styles.actionButton}
                    isAbsoluteDropdown={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default AreYouSureModal;
