import React from 'react';
import { Link } from '@reach/router';

import Image from '@components/image/image';

import styles from './account-dashboard.module.scss';

export interface IDataUserKitsStatus {
  kits: {
    dna: string;
    blood: string;
  };
  purchaseStatus: {
    hasPurchasedDNAKit: boolean;
    hasPurchasedBloodKit: boolean;
  };
}

interface IProps {
  dataUserKitsStatus: IDataUserKitsStatus;
  dataResultItem: {
    text: string;
    icon: string;
  };
  isConsultationCompleted: boolean;
}

const ResultCard = ({ dataUserKitsStatus, dataResultItem, isConsultationCompleted }: IProps) => {
  const { hasPurchasedDNAKit, hasPurchasedBloodKit } = dataUserKitsStatus?.purchaseStatus;

  const { dna: dnaStatus, blood: bloodStatus } = dataUserKitsStatus?.kits;

  const isBloodTest = dataResultItem?.text === 'Blood Test';
  const isDNATest = dataResultItem?.text === 'DNA Test';
  const isHealthQuiz = dataResultItem?.text === 'Health Quiz';

  const resultData = {
    resultType: dataResultItem?.text || '',
    resultIcon: dataResultItem?.icon || '',
    statusText: '',
    statusIcon: '',
    linkText: '',
    linkUrl: '',
    classNamePrefix: '',
  };

  const getRestProps = () => {
    switch (isBloodTest ? bloodStatus : dnaStatus) {
      case '':
        return {
          linkText: 'Get your test kit',
          statusText: '',
          statusIcon: '/images/icon-plus.svg',
          classNamePrefix: 'discover',
        };
      case 'registered':
        return {
          linkText: 'Registered',
          statusText: 'Lab awaiting delivery',
          statusIcon: '',
          classNamePrefix: 'registered',
        };
      case 'processing':
        return {
          linkText: 'Processing',
          statusText: 'Lab analysing results',
          statusIcon: '/images/icon-processing.svg',
          classNamePrefix: 'processing',
        };
      case 'expired':
        return {
          linkText: 'Update results',
          statusText: 'Results outdated',
          statusIcon: '/images/icon-bell.svg',
          classNamePrefix: 'expired',
        };
      case 'complete':
        return {
          linkText: 'View results',
          statusText: '',
          statusIcon: '/images/icon-checked-new.svg',
          classNamePrefix: 'complete',
        };
      default:
        return {
          linkText: '',
          statusText: '',
          statusIcon: '',
          classNamePrefix: '',
        };
    }
  };

  if (isHealthQuiz) {
    resultData.statusText = isConsultationCompleted ? '' : 'Discover more';
    resultData.statusIcon = isConsultationCompleted
      ? '/images/icon-checked-new.svg'
      : '/images/icon-plus.svg';
    resultData.linkText = isConsultationCompleted ? 'View results' : 'Take health quiz';
    resultData.linkUrl = isConsultationCompleted ? '/account/recommendation' : '/consultation';
    resultData.classNamePrefix = isConsultationCompleted ? 'complete' : 'discover';
  } else {
    if (isBloodTest) {
      resultData.linkUrl = hasPurchasedBloodKit ? '/account/result/blood' : '/product/blood';
    } else if (isDNATest) {
      resultData.linkUrl = hasPurchasedDNAKit ? '/account/result/dna' : '/product/dna';
    }
    resultData.linkText = getRestProps().linkText;
    resultData.statusText = getRestProps().statusText;
    resultData.statusIcon = getRestProps().statusIcon;
    resultData.classNamePrefix = getRestProps().classNamePrefix;
  }

  const hasALink =
    resultData.classNamePrefix !== 'registered' && resultData.classNamePrefix !== 'processing';
  const hasIcon = resultData.statusIcon !== '';

  return (
    <div className={`${styles.result}`}>
      <div className={styles.resultContent}>
        <Image filename={resultData.resultIcon} width={40} />
        <p className={styles.h4}>
          <strong>{resultData.resultType}</strong>
        </p>
        {hasALink ? (
          <Link className={`${styles.resultStatus} ${styles[`${resultData.classNamePrefix}Status`]}`} to={resultData.linkUrl}>
            <Image src={resultData.statusIcon} width={16} alt={'icon'} />
            {resultData.linkText}
          </Link>
        ) : (
          <>
            <div className={`${styles.resultStatus} ${styles[`${resultData.classNamePrefix}Status`]}`}>
              { hasIcon ?? (
                <Image src={resultData.statusIcon} width={16} alt={'icon'} />
              )}
              {resultData.linkText}
            </div>
          </>
        )}
        <div className={styles.status}>{resultData.statusText}</div>
      </div>
    </div>
  );
};
export default ResultCard;
