import { useQuery } from '@apollo/react-hooks';
import { CONSULTATION_STATUS } from '@queries/user';
import { useContext, useEffect } from 'react';
import { ResultPageContext } from '@features/account/pages/result/store/store';

const useUserConsultationStatus = () => {
  const { setConsultationStatusComplete } = useContext(ResultPageContext);

  const { data, loading, error } = useQuery(CONSULTATION_STATUS, {
    fetchPolicy: 'network-only',
  });

  if (data) {
    setConsultationStatusComplete(data?.user_consultationStatus?.status === 'complete');
  }
};

export default useUserConsultationStatus;
