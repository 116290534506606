import React, { useContext } from 'react';

import useAccountResult from '../../hooks/useAccountResult';
import AddToBasket from '@src/components/add-to-basket';
import { useBasket } from '@src/hooks/useBasket';
import SubmitButton from '@src/components/submit-button';
import { ResultPageContext } from '../../store/store';

import styles from './add4ppToBasket.module.scss';
import useIsProductUpToDate from '../../hooks/useIsProductUpToDate';

export const ADD_TO_BASKET_OFFER_LABEL = 'Claim offer';

const Add4PPToBasket = () => {
  const {
    loading,
    shouldUpdatePack,
    shouldShowFreeTrialLabel,
    has4PPDiscount,
    getSubscriptions,
    errorSubscriptions,
  } = useAccountResult();

  const { personalisedProduct, displayProductCode } = useContext(ResultPageContext);
  const { data: basketData } = useBasket('network-only');

  const isProductUpToDate = useIsProductUpToDate();

  if (!personalisedProduct) return null;

  if (shouldUpdatePack)
    return (
      <>
        <div className={styles.notUpToDate}>
          <b>Time to update your pack! </b>
          <br />
          Make sure your pack is fully up to date
        </div>
        <SubmitButton
          className={styles.buttonUpdatePack}
          buttonLabel={'Update pack'}
          loading={loading}
          onClick={() => getSubscriptions()}
          error={errorSubscriptions}
          fullWidth
        />
      </>
    );

  if (isProductUpToDate)
    return (
      <div className={styles.upToDate}>
        <b>Your pack is fully up to date!</b>
        <br />
        You're getting the best vitamin recommendations for you.
      </div>
    );

  return (
    <div>
      <AddToBasket
        isFreeTrial={shouldShowFreeTrialLabel()}
        dontShowDeliveryCost
        plans={personalisedProduct.plans}
        showPlanLabel={false}
        productType={displayProductCode}
        ctaText={has4PPDiscount() ? ADD_TO_BASKET_OFFER_LABEL : ''}
        basketCouponCode={basketData?.discountCode}
        showButtonOnly
        fullWidth
      />
    </div>
  );
};

export default Add4PPToBasket;
