import { IGoalExtraInfo } from '../interfaces/goal';

export const GOALS_EXTRA_INFO: { [key: string]: IGoalExtraInfo } = {
  brain: {
    issueShortDesc:
      'As part of your central nervous system, it’s crucial to support your brain health for overall wellbeing.',
    issueLongDesc:
      'As part of your central nervous system and the control centre of your body, your brain coordinates both your mental and physical performance.',
    text: '#2D8437',
    background: '#EEF5D8',
  },
  detox: {
    issueShortDesc: 'Eliminating toxins and cleansing your body helps support a healthy liver.',
    issueLongDesc:
      'Your body can accumulate a number of harmful toxins such as pollutants, processed foods and chemicals.<br/><br/>Improving your body’s natural detoxification mechanism can help cleanse your body and support a healthy liver function.',
    text: '#0082B7',
    background: '#D9EBEE',
  },
  digestion: {
    issueShortDesc:
      'Supporting your digestion helps prevent discomfort and increases the nutrients you absorb from food.',
    issueLongDesc:
      'Your digestion is important to ensure that you absorb all the vital nutrients from food.<br/><br/>When your digestive system isn’t functioning at its best it can affect how well you absorb the food in your gut and offset the balance of your gut bacteria.',
    text: '#368B6B',
    background: '#D5ECD8',
  },
  energy: {
    issueShortDesc: 'Keeping your energy levels high is crucial for getting the most out of every day.',
    issueLongDesc:
      'Your energy levels can be affected by your sleep, diet and exercise habits.<br/><br/>Supporting your energy levels will help you get the most out of every day.',
    text: '#E56B03',
    background: '#FDECBC',
  },
  fertility: {
    issueShortDesc:
      'Providing your body with essential nutrients and minerals is key to supporting the early stages of pregnancy.',
    issueLongDesc:
      'If you’re trying for a baby, you want to know you’re giving your body the best chance of success.<br /><br />Providing your body with essential nutrients and minerals is key to supporting the early stages of pregnancy.',
    text: '#DD446D',
    background: '#FFEDF1',
  },
  hair: {
    issueShortDesc: 'Having strong and healthy hair and nails can be an indicator of your overall wellbeing.',
    issueLongDesc:
      'The health of hair and nails can be affected by both your diet and lifestyle.<br/><br/>Ensuring you are consuming the right nutrients is important to keep your hair follicles strong and resilient.',
    text: '#664863',
    background: '#E2D7DC',
  },
  immunity: {
    issueShortDesc: 'Support your body’s natural defences against illness and infection.',
    issueLongDesc:
      'Your immune system is your body’s internal defence system against illness and infection.<br/><br/>Supporting it with the correct nutrients is vital to give it the support it needs to function at its best.',
    text: '#496FAB',
    background: '#D4DBEE',
  },
  skin: {
    issueShortDesc:
      'Supporting your skin with the right nutrients will nourish your body from the inside, so you can radiate on the outside.',
    issueLongDesc:
      'Your skin is the largest organ protecting you from the external environment.<br/><br/>Supporting your skin with the right nutrients will nourish your body from the inside, so you can radiate on the outside.',
    text: '#BA3653',
    background: '#F9EBE7',
  },
  sleep: {
    issueShortDesc: 'Making sure you get enough quality sleep is essential for you to function at your best.',
    issueLongDesc:
      'Sleep is one of the fundamental pillars of your overall health and wellness.<br/><br/>Ensuring you get the right amount of high quality sleep allows you to function at your best.',
    text: '#6A3584',
    background: '#E0CCDD',
  },
  stress: {
    issueShortDesc: 'Reducing stress & anxiety allows you to focus on what matters most.',
    issueLongDesc:
      'Stress & anxiety are the body’s natural responses against danger and managing them both is essential for overall health and wellbeing.<br/><br/>Reducing your stress & anxiety allows you to focus on what matters most.',
    text: '#CD4A64',
    background: '#F5DDDC',
  },
  'weight-mgmt': {
    issueShortDesc: 'Achieve your desired weight goals and live a healthier lifestyle.',
    issueLongDesc: 'Achieve your desired weight goals and live a healthier lifestyle.',
    text: '#1C587C',
    background: '#D1DDE5',
  },
  fitness: {
    issueShortDesc: 'Physical activity is an important pillar in your overall health and wellbeing.',
    issueLongDesc:
      'Physical activity is an important pillar in your overall health and wellbeing.<br/><br/>Support your fitness efforts with the right nutrients to help you workout efficiently and recover quickly.',
    text: '#E35240',
    background: '#FCEBE8',
  },
  bones: {
    issueShortDesc: 'Maintaining healthy bones is one of the most important things you can do for long-term health.',
    issueLongDesc:
      'Maintaining healthy bones is one of the most important things you can do for long-term health. Let’s see how we can help with some expert tips!',
    text: '#033D60',
    background: '#D3E5F2',
  },
  joints: {
    issueShortDesc: 'Maintaining healthy joints is key for long-term health.',
    issueLongDesc:
      'Maintaining healthy joints is key for long-term health. Let’s see how we can help with some expert tips!',
    text: '#535151',
    background: '#D5D5D5',
  },
};
