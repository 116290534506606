import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import { USER_MARKETING_CONSENT } from '@queries/user';
import CustomCheckbox from '@components/form/custom-checkbox';
import { trackEvent, trackIdentify } from '@services/tracking/tracking';
//hooks
import { useGetUserDetails } from '@hooks/useGetUserDetails';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
// styles
import styles from './account-details.module.scss';

interface IProps {
  updateEmailLoading: boolean;
  setLoading: (loading: boolean) => void;
}

const AccountPreferences = ({ updateEmailLoading, setLoading }: IProps) => {
  const { directusAccount } = useAccountText();

  // queries
  const { user } = useGetUserDetails();
  const { data: marketingConsentData } = useQuery(USER_MARKETING_CONSENT);

  const { register: registerEmail, setValue: setValueEmail, getValues: getValuesEmail } = useForm();

  const { register: registerSms, setValue: setValueSms, getValues: getValuesSms } = useForm();

  const onCommunicationPreferencesChange = (type: string) => {
    if (type === 'email') {
      const emailPreferencesEnabled = getValuesEmail('emailPreferences');
      user &&
        trackIdentify(
          user.customerDataPlatformId,
          {
            unsubscribed: !emailPreferencesEnabled,
          },
          {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
          }
        );
      !!emailPreferencesEnabled
        ? trackEvent('SubscribedToEmailMarketing')
        : trackEvent('UnsubscribedFromEmailMarketing');

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } else if (type === 'sms') {
      const smsPreferencesEnabled = getValuesSms('smsPreferences');
      user &&
        trackIdentify(
          user?.customerDataPlatformId,
          {
            smsSubscribed: !!smsPreferencesEnabled,
            phone: user?.phone,
          },
          {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
          }
        );
      !!smsPreferencesEnabled
        ? trackEvent('SubscribedToSMSMarketing')
        : trackEvent('UnsubscribedFromSMSMarketing');

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    }
  };

  useEffect(() => {
    const isUserSubscribedToEmails = marketingConsentData?.user_marketingConsent.email;
    const isUserSubscribedToSMS = marketingConsentData?.user_marketingConsent.sms;

    setValueEmail('emailPreferences', isUserSubscribedToEmails);
    setValueSms('smsPreferences', isUserSubscribedToSMS);
  }, [marketingConsentData]);

  return (
    <div className={styles.formWrapper}>
      <h4>Update your communication preferences</h4>
      <hr className={styles.hr} />
      <p>{directusAccount.communication_preferences_text}</p>
      <form onChange={() => onCommunicationPreferencesChange('email')}>
        <fieldset disabled={updateEmailLoading}>
          <CustomCheckbox
            label={directusAccount.communication_email_label}
            id="emailPreferences"
            name="emailPreferences"
            register={registerEmail()}
          />
        </fieldset>
      </form>
      <form onChange={() => onCommunicationPreferencesChange('sms')}>
        <fieldset>
          <CustomCheckbox
            label={directusAccount.communication_sms_label}
            id="smsPreferences"
            name="smsPreferences"
            register={registerSms()}
          />
        </fieldset>
      </form>
    </div>
  );
};

export default AccountPreferences;
