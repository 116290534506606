import React, { useState, FC } from 'react';
import Modal from '../modal';
// components
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import ModalFooter from '../modal/modal-footer';
import { useAccountText } from '@src/hooks/directusHooks/useAccountText';
// styles
import styles from './modal-manage-goals.module.scss';
import { IHealthProfileGoal } from '../../types/goal';

interface IModalManageGoalsProps {
  initSelectedGoals: IHealthProfileGoal[];
  initRecommendedGoals: IHealthProfileGoal[];
  handleSubmit: (selectedGoals: IHealthProfileGoal[]) => void;
  handleClose: () => void;
  loading?: boolean;
}

const ModalManageGoals: FC<IModalManageGoalsProps> = ({
  initSelectedGoals,
  initRecommendedGoals,
  handleSubmit,
  handleClose,
  loading = false,
}) => {
  const { directusAccountGoal } = useAccountText();

  const [selectedGoals, setSelectedGoals] = useState(initSelectedGoals);
  const [recommendedGoals, setRecommendedGoals] = useState(initRecommendedGoals);

  const selectGoal = (goal: IHealthProfileGoal) => {
    const newRecommendedGoals = [...recommendedGoals];
    const newSelectedGoals = [...selectedGoals, goal];
    newRecommendedGoals.splice(
      newRecommendedGoals.findIndex(recommendedGoal => recommendedGoal.id === goal.id),
      1
    );

    setSelectedGoals(newSelectedGoals);
    setRecommendedGoals(newRecommendedGoals);
  };

  const unselectGoal = (goal: IHealthProfileGoal) => {
    const newRecommendedGoals = [...recommendedGoals];
    const newSelectedGoals = [...selectedGoals];
    const pos = recommendedGoals.findIndex(
      recommendedGoal => recommendedGoal.score && goal.score && recommendedGoal?.score <= goal.score
    );
    newRecommendedGoals.splice(pos, 0, goal);
    newSelectedGoals.splice(
      selectedGoals.findIndex(selectedGoal => selectedGoal.id === goal.id),
      1
    );

    setRecommendedGoals(newRecommendedGoals);
    setSelectedGoals(newSelectedGoals);
  };

  const renderSelectedGoal = (goal: IHealthProfileGoal, index: number) => {
    return (
      <li className={styles.selectedGoal} key={goal.id}>
        {goal ? (
          <>
            <button
              className={styles.buttonRemove}
              data-testid={`remove-selected-goal-${index}`}
              type="button"
              onClick={() => unselectGoal(goal)}
            ></button>
            <div className={styles.selectedGoalImg}>
              <img
                src={`${goal.imageUrl}?&colorize=%23${
                  goal.color.split('#')[1]
                }&truecolor=true&width=92`}
                alt={goal.name}
              />
            </div>
            <p>
              <strong>{goal.name}</strong>
            </p>
          </>
        ) : (
          <div className={styles.selectedGoalImg}></div>
        )}
      </li>
    );
  };

  if (!directusAccountGoal) return null;

  return (
    <Modal type="ManageGoals" handleClose={handleClose}>
      <ModalHeader type="ManageGoals">
        <h2 className={styles.h4}>{directusAccountGoal.manage_title}</h2>
        <hr />
        <ul className={styles.selectedGoals}>
          {selectedGoals.map((selectedGoal, index) => renderSelectedGoal(selectedGoal, index))}
        </ul>
        {selectedGoals.length === 0 && (
          <p className={styles.noSelectedGoals}>{directusAccountGoal.manage_no_goals_label}</p>
        )}
      </ModalHeader>
      <ModalBody type="ManageGoals">
        <ul className={styles.goals}>
          {recommendedGoals.map((goal, index) => (
            <li key={goal.id} className={styles.goal}>
              <img
                className={styles.goalImg}
                src={`${goal.imageUrl}?&colorize=%23${goal.color.split('#')[1]}&truecolor=true`}
                alt={goal.name}
              />
              <p>
                <strong>{goal.name}</strong>
              </p>
              <button
                className={styles.buttonAdd}
                data-testid={`add-selected-goal-${index}`}
                disabled={selectedGoals.length >= 3}
                type="button"
                onClick={() => selectGoal(goal)}
              ></button>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter
        type="ManageGoals"
        closeLabel={directusAccountGoal.manage_cancel_button_label}
        saveLabel={directusAccountGoal.manage_save_button_label}
        isSaveDisabled={selectedGoals.length === 0}
        handleClose={handleClose}
        handleSave={() => handleSubmit(selectedGoals)}
        loading={loading}
      />
    </Modal>
  );
};

export default ModalManageGoals;
