import React from 'react';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
// queries
import { GET_PRODUCT } from '../../queries/product';
// components
import Loader from '../loader';
import AddToBasket from '../add-to-basket';
// styles
import styles from './account-select-plan.module.scss';
//constants
import { OUT_OF_STOCK } from '@constants/messages';

interface IProps {
  productId: string;
}

const AccountScreen: React.FC<IProps> = ({ productId }) => {
  // get products
  const { data, loading, error } = useQuery(GET_PRODUCT, {
    variables: {
      slug: productId,
      id: productId,
      freeTrial: false,
    },
  });

  if (loading) return <Loader />;
  if (error) navigate('/account/dashboard');

  const product = data?.product_getProduct;
  const label = product?.label;
  const iconUrl = product?.iconUrl;
  const plans = product?.plans || [];

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h2 className={styles.h2}>Choose your plan</h2>
            <img src={iconUrl} alt="" />
            {data && (
              <div>
                <h3>{label}</h3>
                <hr />
                {plans.length > 0 ? (
                  <AddToBasket plans={plans} displaySinglePlanLabel={plans[0].planLabel !== ''} />
                ) : (
                  <h4>{OUT_OF_STOCK}</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountScreen;
