import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
// queries
import { CONTENT_SCREEN } from '@queries/user';
// components
import ContentHeader from '@components/content/content-header/content-header';
import ContentTitle from '@components/content/content-title/content-title';
import ContentText from '@components/content/content-text/content-text';
import ContentImage from '@components/content/content-image/content-image';
import ContentItemList from '@components/content/content-item-list/content-item-list';
import ContentButtons from '@components/content/content-buttons/content-buttons';
import ContentHtml from '@components/content/content-html/content-html';
import ContentIconGrid from '@components/content/content-icon-grid/content-icon-grid';
import ContentBiomarker from '@components/content/content-biomarker/content-biomarker';
import ContentDnaTraitMarker from '@components/content/content-dna-trait-marker/content-dna-trait-marker';
import ContentCarousel from '@components/content/content-carousel';
import ContentSpacer from '@components/content/content-spacer/content-spacer';
import Loader from '../loader';
// utilities
import { getContentStyles } from '../content/utilities';
// styles
import styles from './account-content-screen.module.scss';
import useAccountContext from '@src/features/account/hooks/useAccountContext';

interface IProps {
  uri: string;
}

export const renderBlock = (block: any) => {
  switch (block.type) {
    case 'header':
      return <ContentHeader content={block} />;
    case 'title':
      return <ContentTitle content={block} />;
    case 'text':
      return <ContentText content={block} />;
    case 'image':
      return <ContentImage content={block} />;
    case 'itemList':
      return <ContentItemList content={block} />;
    case 'buttons':
      return <ContentButtons content={block} />;
    case 'html':
      return <ContentHtml content={block} />;
    case 'iconGrid':
      return <ContentIconGrid content={block} />;
    case 'biomarkerResults':
      return <ContentBiomarker content={block} />;
    case 'dnaTraitMarker':
      return <ContentDnaTraitMarker content={block} />;
    case 'carousel':
      return <ContentCarousel content={block} />;
    case 'spacer':
      return <ContentSpacer content={block} />;
  }
};

const AccountContentScreen: React.FC<IProps> = ({ uri }) => {
  const { contentScreen, setContentScreen } = useAccountContext();

  const formattedUri = uri.replace('/account', '');

  const { loading, error, data } = useQuery(CONTENT_SCREEN, {
    fetchPolicy: 'no-cache',
    variables: { path: formattedUri },
  });

  if (loading) return <Loader />;
  if (error) navigate('/account/dashboard');

  const submitFormData = contentScreen?.content_submitForm;
  const submitFormSections = submitFormData && JSON.parse(submitFormData.jsonSections);
  const getScreenSections =
    data?.content_getScreen && JSON.parse(data.content_getScreen.jsonSections);

  const sections = submitFormData ? submitFormSections : getScreenSections;

  const handleGoBack = () => {
    setContentScreen(null);
    navigate(-1);
  };

  if (sections?.message) {
    navigate('/account/dashboard');
    return null;
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <button className={styles.linkBack} type="button" onClick={() => handleGoBack()}>
              Go back
            </button>
          </div>
        </div>
      </div>
      {sections?.map((section: any, sectionIndex: number) => (
        <section key={sectionIndex} style={getContentStyles(section.style)}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.col}>
                {section.blocks?.map((block: any, blockIndex: number) => (
                  <div key={blockIndex} className={styles.block}>
                    {renderBlock(block)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ))}
    </section>
  );
};

export default AccountContentScreen;
