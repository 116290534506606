export interface IStripeError {
  code: string;
  decline_code: string;
  doc_url: string;
  message: string;
  param: string;
  type: string;
}

export enum StripeErrorCodes {
  CardNotSupported = 'card_not_supported',
  incomplete_zip = 'incomplete_zip',
  incomplete_number = 'incomplete_number',
  incomplete_cvc = 'incomplete_cvc',
  incomplete_expiry = 'incomplete_expiry',
}
